import Swal from "sweetalert2";
import axios from "./services/axios";

const AxiosInterceptorsSetup = navigate => {
    // const userAgent = navigator.userAgent;
    // const isInstagramBrowser = userAgent.includes('Instagram');
    // const isFacebookBrowser = userAgent.includes('FBAN') || userAgent.includes('FBAV');

    axios.interceptors.response.use(
        response => response,
        error => {
            if (error.response.status === 401) {
                Swal.fire({
                    title: "User Session has been Expired!",
                    timer: 2000,
                    icon: 'error',
                    showConfirmButton: false,
                })
                setTimeout(() => {
                    localStorage.removeItem('sparrowSignIn');
                    navigate('/signin');
                }, 2000);
            }
            return Promise.reject(error);
        }
    );
};

export default AxiosInterceptorsSetup;