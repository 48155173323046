import { initializeApp } from "firebase/app";
import { addDoc, collection, getDocs, query, where, Timestamp, getFirestore, updateDoc, doc, setDoc } from "firebase/firestore";
import { firebaseConfig } from "../../../firebase/FireBase";
import { v4 as uuidv4 } from "uuid";
import { showStudent } from "../../../services/users/ShowSingleStudent";

const appNew = initializeApp(firebaseConfig);
const dbNew = getFirestore(appNew);

async function createUserIfNotExists(userObj) {
  try {
    // Check if the user already exists
    const userQuery = query(collection(dbNew, "Users"), where("email", "==", userObj.email), where("id", "==", userObj.id));
    const querySnapshot = await getDocs(userQuery);

    if (querySnapshot.empty) {
      // If the user does not exist, add a new user document
      const docRef = await addDoc(collection(dbNew, "Users"), userObj);
      // console.log("User created with ID:", docRef.id);
    } else {
      // console.log("User already exists");
    }
  } catch (error) {
    console.error("Error creating user:", error);
  }
}

export const createChatRoom = async (studentId, clientId, taskId, orderId, reason, message) => {
  // this whole function is for creating chatRooms and is using in inviteStudent + customerCreateTask screen
  // firebase

  let user_name = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.first_name;
  // sending msg to student
  const serverTime = Timestamp.now();

  let client_data = await showStudent(clientId);
  let student_data = await showStudent(studentId);

  const clientObj = {
    email: client_data?.data?.user?.email,
    id: client_data?.data?.user?.id.toString(),
    imageUrl: client_data?.data?.user?.image,
    name: client_data?.data?.user?.first_name,
  };

  const studentObj = {
    email: student_data?.data?.user?.email,
    id: student_data?.data?.user?.id.toString(),
    imageUrl: student_data?.data?.user?.image,
    name: student_data?.data?.user?.first_name,
  };

  await createUserIfNotExists(clientObj);
  await createUserIfNotExists(studentObj);

  // chatRoom/firebase is creating from here for pushing notification of order in Chat || below is for checking if already their exist a ChatRoom b/w users or not

  const q = query(collection(dbNew, "ChatRooms"), where("userIds", "array-contains-any", [clientId]));

  const roomsSnapshot2 = await getDocs(q);
  const roomsList2 = roomsSnapshot2.docs.map((doc) => doc.data());

  const roomLIstGet2 = roomsList2.filter((item) => {
    return item.userIds.includes(studentId);
  });

  if (roomLIstGet2.length === 0) {
    // creating firestore date
    const date = Timestamp.now();
    // sending to firestore db
    await addDoc(collection(dbNew, "ChatRooms"), {
      id: uuidv4(),
      lastmessage: "",
      lastmessages: "",
      timeStampOfLastMessage: JSON.stringify(serverTime.seconds * 1000),
      userIds: [clientId, studentId],
    });
    // console.log('room created')
  }
  // searching for rooms to which we have to send notification in Msg

  const qChatRooms = query(collection(dbNew, "ChatRooms"), where("userIds", "==", [clientId, studentId]));
  const qRoomsSnapshot = await getDocs(qChatRooms);
  const qRoomsList = qRoomsSnapshot.docs.map((doc) => doc.data());
  const qRoomdId = qRoomsList.map((item) => {
    return item?.id;
  });

  if (reason == "student_personal_message") {
    await addDoc(collection(dbNew, "Messages"), {
      message: `${message != "" || message != null ? message : null}`,
      roomId: qRoomdId.toString(),
      senderId: studentId,
      timeStamp: JSON.stringify(serverTime.seconds * 1000),
      url: `/dashboardRoute/task-history/student-task-descp-for-client/${taskId}/${orderId}`,
    });
  }

  if (reason == "default") {
    await addDoc(collection(dbNew, "Messages"), {
      message: `${user_name} sent a job offer. Click to View Offer`,
      roomId: qRoomdId.toString(),
      senderId: clientId,
      timeStamp: JSON.stringify(serverTime.seconds * 1000),
      url: `/dashboardRoute/task-history/student-task-descp/${taskId}/${orderId}`,
    });
  }

  // await addDoc(collection(dbNew, "Messages"), {
  //   // message: `${user_name} sent a job offer. Click to View Offer`,
  //   roomId: qRoomdId.toString(),
  //   senderId: clientId,
  //   timeStamp: JSON.stringify(serverTime.seconds * 1000),
  //   url: `/dashboardRoute/task-history/student-task-descp/${taskId}/${orderId}`,
  // });

  // setting room here so we can send last msg to db || setting serverTime and message here because we have to show what was the last time and message when we chatted

  const q2 = query(collection(dbNew, "ChatRooms"), where("id", "==", qRoomdId.toString()));
  const roomsSnapshot = await getDocs(q2);
  const roomsListId = roomsSnapshot.docs.map((doc) => doc.id);
  const roomListIdString = roomsListId.toString();

  if (reason == "default") {
    await updateDoc(doc(dbNew, "ChatRooms", roomListIdString), {
      lastmessage: `${user_name} sent a job offer. Click to View Offer`,
      timeStampOfLastMessage: JSON.stringify(serverTime.seconds * 1000),
    });
  }

  if (reason == "student_personal_message") {
    await updateDoc(doc(dbNew, "ChatRooms", roomListIdString), {
      lastmessage: `${message}`,
      timeStampOfLastMessage: JSON.stringify(serverTime.seconds * 1000),
      seen: false,
    });
  }

  return qRoomdId[0];
};
