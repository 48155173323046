// import { useEffect, useReducer } from 'react';
// import AuthContext from './AuthContext';
// import { useState } from 'react';
// import { getLocations } from '../services/locations/Locations';

// const defaultlocationState = {
//     location: [],
// };

// //
// const defaultNotifcationState = {
//     notifications: [],
// };
// // end

// const locationReduser = (state, action) => {
//     // debugger
//     if (action.type === 'ADD_Location') {
//         let updatedItems = action.item;
//         return { location: updatedItems, }

//     }

//     if (action.type === 'CLEAR') {
//         return defaultlocationState;
//     }

//     return defaultlocationState;
// }
// //  REDUCERS are here
// const notificationReduser = (state, action) => {
//     if (action.type === "ADD_Notification") {
//         let updatedItems = action.item;
//         return { notifications: updatedItems, }
//     }
//     // debugger
//     if (action.type === 'CLEAR') {
//         // console.log("idr ata");
//         return defaultNotifcationState;
//     }
//     return defaultNotifcationState;
// }
// // end

// const LocationProvider = props => {

//     const [globalloader, setGlobalLoader] = useState(false);
//     const updateLoader = (isLoading) => {
//         setGlobalLoader(isLoading);
//       };
//     const [locationState, dispatchLocationAction] = useReducer(locationReduser, defaultlocationState);

//     const addItemToLocationHandler = item => {
//         dispatchLocationAction({ type: 'ADD_Location', item: item });
//     };

//     const clearItemFromLocationHandler = () => {
//         dispatchLocationAction({ type: 'CLEAR' })
//     };

//     useEffect(() => {
//         const fetchLocations = async () => {
//             setGlobalLoader(true);
//             try {
//                 const response = await getLocations();
//                 dispatchLocationAction({ type: 'ADD_LOCATION', locations: response.data });
//             } catch (error) {
//                 console.error('Failed to fetch locations:', error);
//             }
//             setGlobalLoader(false);
//         };

//         fetchLocations();
//     }, []);

//     //
//     const [notificationState, dispatchNotificationAction] = useReducer(notificationReduser, defaultNotifcationState);

//     const addNotificatonHandler = (item) => {
//         dispatchNotificationAction({ type: 'ADD_Notification', item: item })
//     };
//     const clearNotificatonHandler = () => {
//         dispatchNotificationAction({ type: 'CLEAR' })
//     };

//     // end

//     const locationContext = {
//         globalLoader: globalloader,
//         updateLoader: updateLoader,
//         locations: locationState.location,
//         addLocations: addItemToLocationHandler,
//         clearLocation: clearItemFromLocationHandler,
//         notifications: notificationState.notifications,
//         addNotifications: addNotificatonHandler,
//         clearNotifications: clearNotificatonHandler
//     };
//     return <AuthContext.Provider value={locationContext}>
//         {props.children}
//     </AuthContext.Provider>
// };

// export default LocationProvider;

import React, { useEffect, useReducer, useState } from "react";
import AuthContext from "./AuthContext";
import { getLocations } from "../services/locations/Locations";

const defaultLocationState = {
  locations: [],
};
const defaultNotifcationState = {
    notifications: [],
};

const locationReducer = (state, action) => {
  switch (action.type) {
    case "ADD_LOCATION":
      return { ...state, locations: action.locations };
    case "CLEAR":
      return defaultLocationState;
    default:
      return state;
  }
};

const LocationProvider = ({ children }) => {
  const [globalLoader, setGlobalLoader] = useState(false);
  const [locationState, dispatchLocation] = useReducer(
    locationReducer,
    defaultLocationState
  );

  useEffect(() => {
    const fetchLocations = async () => {
      setGlobalLoader(true);
      let locationsData = localStorage.getItem("locationsData");

      if (!locationsData) {
        try {
          // const response = await getLocations();
          const optionlocation = [];
          const response = await getLocations();
          response?.data?.map((singleLocation) => {
            return optionlocation.push({
              label:
                singleLocation.city +
                " " +
                singleLocation.state +
                "," +
                singleLocation.zip,
              value: singleLocation.id,
            });
          });
          locationsData = optionlocation;
          localStorage.setItem("locationsData", JSON.stringify(locationsData));
          dispatchLocation({ type: "ADD_LOCATION", locations: locationsData });
        } catch (error) {
          console.error("Failed to fetch locations:", error);
        }
      } else {
        dispatchLocation({
          type: "ADD_LOCATION",
          locations: JSON.parse(locationsData),
        });
      }

      setGlobalLoader(false);
    };

    fetchLocations();
  }, []);

  const clearLocations = () => {
    dispatchLocation({ type: "CLEAR" });
    localStorage.removeItem("locationsData"); // Clear cached data if needed
  };
  
        const notificationReduser = (state, action) => {
      if (action.type === "ADD_Notification") {
          let updatedItems = action.item;
          return { notifications: updatedItems, }
      }
      // debugger
      if (action.type === 'CLEAR') {
          // console.log("idr ata");
          return defaultNotifcationState;
      }
      return defaultNotifcationState;
  }

      const [notificationState, dispatchNotificationAction] = useReducer(notificationReduser, defaultNotifcationState);

    const addNotificatonHandler = (item) => {
        dispatchNotificationAction({ type: 'ADD_Notification', item: item })
    };
    const clearNotificatonHandler = () => {
        dispatchNotificationAction({ type: 'CLEAR' })
    };

  const contextValue = {
    globalLoader,
    updateLoader: setGlobalLoader,
    locations: locationState.locations,
    addLocations: (locations) =>
      dispatchLocation({ type: "ADD_LOCATION", locations }),
    clearLocation: clearLocations,
    notifications: notificationState.notifications,
        addNotifications: addNotificatonHandler,
        clearNotifications: clearNotificatonHandler
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default LocationProvider;
