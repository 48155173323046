import { useState, useEffect } from "react";
import { getSingleOrderDraft } from "../../services/order/GetSingleOrderDraft";

export function useSingleOrderDraftInfo(id) {
  const [data, setData] = useState(null);

  const getFunc = async () => {
    const resp = await getSingleOrderDraft(id);
    if (resp.status === 200) {
      setData(resp.data.order);
    }
  };
  useEffect(() => {
    getFunc();
  }, []);

  return data;
}
