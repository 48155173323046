import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { getAllTasks } from "../../services/tasks/AllTasks";
import Swal from "sweetalert2";
import ReactPixel from "react-facebook-pixel";
import Loader from "../loader/Loader";
import AdvancedSearchBarTask from "../advancesearchbar/AdvancedSearchBarTask";
import TaskCard from "../Home/task/TaskCard";
import InfiniteScroll from "react-infinite-scroll-component";

const TaskSearch = () => {
  const [loder, setLoder] = useState(true);
  const [displayedTasks, setDisplayedTasks] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [allTasks, setAllTasks] = useState([]);
  const [searchedValue, setSearchedValue] = useState();

  const getAllTaskFunc = async () => {
    const response = await getAllTasks();
    if (response.status === 200) {
      setAllTasks(response?.data?.data);
      setDisplayedTasks(response?.data?.data.slice(0, 12));
      setLoder(false);
    } else {
      Swal.fire({
        title: response.data.message,
        timer: 1500,
        icon: "error",
        showConfirmButton: false,
      });
      setLoder(false);
    }
  };

  useEffect(() => {
    getAllTaskFunc();
    ReactPixel.track("All Students");
  }, []);

  const handleViewStudent = (studentArray) => {
    console.log("got my search results: ", studentArray);
    setAllTasks(studentArray);
    setDisplayedTasks(studentArray?.slice(0, 12));
  };

  const fetch12MoreStudents = () => {
    const nextTasks = allTasks.slice(
      displayedTasks.length,
      displayedTasks.length + 12
    );
    setDisplayedTasks([...displayedTasks, ...nextTasks]);
    if (displayedTasks.length + nextTasks.length >= allTasks.length) {
      setHasMore(false);
    }
  };
  return (
    <>
      {loder ? (
        <div className="height100vh height10vh">
          <Loader />
        </div>
      ) : (
        <div className="viewAllStudents">
          <Container className="text-center pl90">
            <h2 className="mt70">
              Search <span className="green">Tasks</span>
              <div style={{ position: "relative" }}>
                <span>
                  <img
                    className="info3 info3C"
                    src="/assets/images/newlogos/Info.svg"
                    alt=""
                  />
                  <span className="hoverTextForR3 hoverTextForR3C">
                    Search for different tasks in your area by entering your
                    location and the task you want to work with. The advanced
                    search filter enables you to narrow your search. Click on
                    each task card to see more information.
                  </span>
                </span>
              </div>
            </h2>
            <AdvancedSearchBarTask
              searchedValue={setSearchedValue}
              updateStudentResult={(studentArray, searchValue) =>
                handleViewStudent(studentArray, searchValue)
              }
            />
            <InfiniteScroll
              dataLength={displayedTasks?.length}
              next={fetch12MoreStudents}
              hasMore={hasMore}
              style={{ overflow: "none" }}
            >
              <Row className="viewAllStCards">
                {displayedTasks?.map((singleTask) => {
                  return (
                    <Col md={4} className="mb-5" key={singleTask?.id}>
                      <TaskCard taskData={singleTask} />
                    </Col>
                  );
                })}
              </Row>
            </InfiniteScroll>
          </Container>
        </div>
      )}
    </>
  );
};

export default TaskSearch;
