import { Col, Container, Row } from 'react-bootstrap'
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper";
import HappyUsers from './HappyUsers';

function HomeHappyUsers() {

    const userDataTestimonial = [
        {name : 'Edward' , body : 'It was actually really easy to book a last minute babysitter for the weekend. Mia showed up on time and our daughter had a nice time with her. Would be using this service again! ' , taskName: 'Babysitting' , imageSec : '/assets/images/home/glassesMan.png' },
        {name : 'Caroline' , body : 'My kid has been enjoying the basketball lessons from Jose. He gets the one-on-one coaching and even asks to schedule more lessons during the week. Great way to keep him busy while his basketball skills get better. Definitely recommend.' , taskName: 'Coaching: Basketball', imageSec : '/assets/images/home/caroline.png'},
        {name : 'Michelle' , body : 'I don\'t usually trust anyone else to take my dog out for a walk but this time I had time to plan ahead a busy week. I booked a college student and she was excellent, she was friendly and showed her experience with dog-walking.' ,taskName: 'Pet Sitting', imageSec    : '/assets/images/home/Michelle.png'},
        {name : 'Julian' , body : 'I used Sparrow to get help with the groceries and it was fast and easy. I got my delivery on time and they guy even called to ask about a substitute item on the list. Saved myself some time and will definitely use this service again for other errands as well.' ,taskName: 'Grocery Delivery', imageSec : '/assets/images/home/Julian.png'},
        {name : 'Michael' , body : 'Peter did an amazing job carrying boxes and loading our truck. A great guy with lots of energy. I never thought it was that easy to get a helping hand' ,taskName: 'Moving', imageSec : '/assets/images/home/testmonial2.png'}
    ]

    return (
        <div className='homeUsers'>
            <div className='profileHead widthHomeR'>
                <h2>What Our Happy <span className='green'>Users Say</span></h2>
                <h6 className='subhead'>We take trust and safety seriously and provide only reliable students. </h6>
            </div>
            <Container>
                <div className='homeUserSlidr homeUserSlidrResponsive remove'>
                    <Swiper
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                            },
                            768: {
                                slidesPerView: 3,
                            },
                        }} 
                        slidesPerView={3}
                        spaceBetween={30}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Pagination]}
                        className="mySwiper"
                    >
                        {userDataTestimonial.map((singletestimonial) => (
                            <SwiperSlide key={singletestimonial.name}> <HappyUsers singletestimonial={singletestimonial} /></SwiperSlide>  
                        ))}
                    </Swiper>
                </div>
            </Container>
        </div>
    )
}

export default HomeHappyUsers