import { Container } from "react-bootstrap";
import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Pagination } from "swiper";
// import StudentProfileCard from './StudentProfileCard';
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { ShowHomePageFeaturedStudents } from "../../services/users/ShowHomePageFeaturedStudents";
import { getHomeTasks } from "../../services/tasks/GetHomeTasks";
import Loader from "../loader/Loader";
import TaskCard from "../Home/task/TaskCard";

function FreelancerTaskHome() {
  const [loder, setLoder] = useState(true);

  const [allStudent, setAllStudent] = useState(false);
  const [tasks, setTasks] = useState([]);

  let client_id = 0;
  if (localStorage.getItem("sparrowSignIn")) {
    client_id = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.id;
  }

  const getFeaturedStudent = async () => {
    const response = await ShowHomePageFeaturedStudents(client_id);
    if (response.status === 200) {
      setAllStudent(response?.data?.users);
      setLoder(false);
    } else {
      Swal.fire({
        title: response.data.message,
        timer: 1500,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };

  const getTasks = async () => {
    const response = await getHomeTasks();
    if (response.status === 200) {
      setTasks(response?.data?.data);
      console.log("data is: ", response.data);
    } else {
      Swal.fire({
        title: response.data.message,
        timer: 1500,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };

  useEffect(() => {
    getFeaturedStudent();
    getTasks();
  }, []);

  return (
    <div className="stdProfile" style={{ marginBottom: '4rem' }}>
      <Container>
        <div className="profileHead">
          <h2>
            Find Your Next <span className="green">Task Today</span>
          </h2>
          <h6 className="subhead">Find work whenever you need extra money during your academic career.</h6>
        </div>

        <div className="profileBoxes homeUserSlidr homeUserSlidrResponsive remove padding0">
          {loder ? (
            <div className="height100vh">
              <Loader />
            </div>
          ) : (
            <Swiper
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                922: {
                  slidesPerView: 3,
                },
              }}
              slidesPerView={3}
              spaceBetween={30}
              // loop={true}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              {tasks.map((singleTask) => {
                return (
                  <SwiperSlide key={singleTask?.id}>
                    <TaskCard taskData={singleTask} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}
        </div>

        <div>
          <Link to={JSON.parse(localStorage.getItem("sparrowSignIn")) === null ? 
          "/signInRoute/search-tasks/": "/dashboardRoute/search-tasks/"
          } className="primary viewAllbtn">
            View All
          </Link>
        </div>
      </Container>
    </div>
  );
}

export default FreelancerTaskHome;
