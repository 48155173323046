import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactStars from "react-stars";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import { capitalize } from "lodash";

export default function TaskCard({ taskData }) {
  let navigate = useNavigate();

  const [showAllTime, setShowAllTime] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleMouseEnter = () => {
    setShowDropdown(true);
  };

  // Function to handle mouse leaving the Dropdown
  const handleMouseLeave = () => {
    setShowDropdown(false);
  };

  const reArrangeArray = (array) => {
    let order = {
      monday: 1,
      tuesday: 2,
      wednesday: 3,
      thursday: 4,
      friday: 5,
      saturday: 6,
      sunday: 7,
    };
    array.sort(function (a, b) {
      return order[a.day] - order[b.day];
    });
    return array;
  };

  const renderTimeSpans = () => {
    const availabilityData = reArrangeArray(taskData?.availability_data);

    if (showAllTime) {
      return availabilityData.map((item) => (
        <span key={item.day} style={{ fontSize: 12 }}>
          {item?.day.slice(0, 1).toUpperCase() + item?.day.slice(1, 3)}:{" "}
          {item?.avail[0]?.start} - {item?.avail[0]?.end}
        </span>
      ));
    } else {
      // Only render the first time slot
      const firstItem = availabilityData[0];
      return (
        <span key={firstItem?.day} style={{ fontSize: 12 }}>
          {firstItem?.day.slice(0, 1).toUpperCase() +
            firstItem?.day.slice(1, 3)}
          : {firstItem?.avail[0]?.start} - {firstItem?.avail[0]?.end}
        </span>
      );
    }
  };

  return (
    <div className="profileBox border-mb-fields" id="TaskCardHeight">
      <Link
        to={
          JSON.parse(localStorage.getItem("sparrowSignIn")) === null ?
          `/signInRoute/task-detail/${taskData?.id}` :
          JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.role ===
            "client" &&
          taskData.user_id ==
            JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.id
            ? `/dashboardRoute/customer-notification/task-details/${taskData?.id}`
            : JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.role ===
              "student"
            ? `/dashboardRoute/task-history/student-task-descp/${taskData?.id}/${taskData?.order_draft?.id}`
            : `/dashboardRoute/task-history/student-task-descp-for-client/${taskData?.id}/${taskData?.order_draft?.id}`
        }
      >
        <h1></h1>
        <div className="profileContainer">
          <div className="profileImg">
            <img
              className="profileImgM imgBorder"
              src={taskData?.client?.image}
              alt=""
            />
          </div>
          <div
            className="profilHed profileBoxStars"
            //   onClick={() => navigate(pathPage)}
          >
            <h3 className="profileBoxH3">
              {taskData ? (
                <>
                  {taskData?.client?.first_name && taskData?.client?.first_name}
                  {taskData?.client?.last_name &&
                    " " + taskData?.client?.last_name.charAt(0).toUpperCase()}
                  {"."}
                </>
              ) : (
                ""
              )}
            </h3>
            <p className="profileBoxP elementCenter">
              {taskData?.location?.city}, {taskData?.location?.state}
            </p>
            <div className="houlyRateValueNewForTask profileBoxStars mt-2 taskCardRate">
              <span className="taskTitleEllipsis">{taskData?.title}</span>-
              <span>${taskData?.hourly_rate}/hr</span>
            </div>
          </div>
          <div
            className="profileContent row mt-3"
            // onClick={() => navigate(pathPage)}
          >
            <div style={{ width: "39.3%" }}>
              <h4 className="profileBoxSubhed">Job Type:</h4>
              <div className="profileTaks flex-wrap">
                <span style={{ fontSize: 14 }}>{taskData?.type}</span>
              </div>
            </div>
            <div style={{ width: "60.7%" }}>
              <h4 className="profileBoxSubhed">Time:</h4>
              {/* <div className="profileTaks flex-wrap">{renderTimeSpans()}</div> */}
              {taskData?.availability_data?.length > 1 ? (
                <Dropdown
                  show={showDropdown}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="dropdown-toggle"
                  >
                    {renderTimeSpans()}
                    <span className="dropdown-chevron-icon">
                      <img
                        src="/assets/images/chevron-down.svg"
                        alt="Chevron down icon"
                        width={18}
                      />
                    </span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {taskData.availability_data.map(
                      (item, index) =>
                        index !== 0 && ( // Exclude the first item from rendering
                          <Dropdown.Item key={index}>
                            {capitalize(
                              item?.day.slice(0, 1).toUpperCase() +
                                item?.day.slice(1, 3)
                            )}
                            : {item.avail[0].start} - {item.avail[0].end}
                          </Dropdown.Item>
                        )
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <div className="profileTaks flex-wrap">{renderTimeSpans()}</div>
              )}
            </div>
          </div>
          <div
            className="profileAbout"
            // onClick={() => navigate(pathPage)}
          >
            <h4 className="profileBoxSubhed">Description</h4>
            <p>
              {
                <span className="overflowText line-clamp">
                  {taskData?.description}
                </span>
              }
            </p>
          </div>
          {/* </Link> */}
        </div>
      </Link>
      <div className="profileBtn">
        <Link
          to={
            JSON.parse(localStorage.getItem("sparrowSignIn")) === null ?
            `/signUpRoute/welcome-to-sparrow` :
            JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.role ===
              "client" &&
            taskData.user_id ==
              JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.id
              ? `/dashboardRoute/customer-notification/task-details/${taskData?.id}`
              : JSON.parse(localStorage.getItem("sparrowSignIn"))?.user
                  ?.role === "student"
              ? `/dashboardRoute/task-history/student-task-descp/${taskData?.id}/${taskData?.order_draft?.id}`
              : `/dashboardRoute/task-history/student-task-descp-for-client/${taskData?.id}/${taskData?.order_draft?.id}`
          }
          className={`primary bookNowBtn ${
            taskData?.invited !== null ? "disalbed" : ""
          }`}
        >
          {taskData?.invited === null ? "Connect" : "Connected"}
        </Link>
      </div>
    </div>
  );
}
