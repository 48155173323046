import axios from "../axios";

export const createStudent = async (body) => {
    try {
        // debugger
        const config = {
            headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem('sparrowSignIn'))?.token}` }
        };
        const response = await axios.post("/student-details", body, config);
        return response;
    } catch (err) {
        return err.response
    }
}

// export const createStudent = async (body, tokenFromClientSignup) => {
//     try {
//         debugger
//         let config
//         if (tokenFromClientSignup) {
//             config = {
//                 headers: { Authorization: `Bearer ${tokenFromClientSignup}` }
//             };
//         } else {
//             config = {
//                 headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem('sparrowSignIn'))?.token}` }
//             };
//         }
//         const response = await axios.post("/student-details", body, config);
//         return response;
//     } catch (err) {
//         return err.response
//     }
// }