import axios from "../axios";

export const ShowAllStudents = async (role_id, ClientId, oder_id, body) => {
  try {
    const response = await axios.post(
      `/users/role/${role_id}?client=${ClientId}&order_id=${oder_id}`,
      body
    );
    return response;
  } catch (err) {
    return err.response;
  }
};