import React from "react";
import { Container } from "react-bootstrap";

const TermsOfServices = () => {
  return (
    <div className="mt140 termsPage">
      <Container>
        <h2 className="text-center">Sparrow Terms of Service</h2>
        <br />
        <h3 style={{ color: "black" }}>Last Updated: June 4, 2024</h3>
        <br />
        <p>
          These Terms of Service constitute a legally binding agreement between you and Findsparrow, Inc. ( “Sparrow” ) governing your use of the Sparrow
          Platform. Sparrow’s website (www.findsparrow.com), and related services, information and communications are collectively referred to as the
          “Sparrow Platform.”
          <br />
          <br />
          The use of all personal data you submit to the Sparrow Platform or which we collect about you is governed by our Global Privacy Policy (
          “Privacy Policy” ). You acknowledge that by using the Sparrow Platform you have reviewed the Privacy Policy.
          <br />
          <br />
          Your consent at registration and continued use of the Sparrow Platform constitutes your acceptance of and agreement to all of the terms and
          conditions in these Terms of Service the country in which the Task is performed, as well as any future amendments and additions to this
          Agreement (as defined below) we may publish from time to time. If any future changes to this Agreement are unacceptable to you or cause you
          to no longer be in compliance with this Agreement, you must deactivate your account, and immediately stop using the Sparrow Platform.
          <br /> <br />
          The Privacy Policy for the country in which the Task is performed are incorporated by reference into these Terms of Service and together
          form and are hereinafter referred to as the “Agreement” .
          <br /> <br />
          IF YOU DO NOT AGREE TO BE BOUND BY THIS AGREEMENT AND ABIDE BY ITS TERMS, YOU MAY NOT USE OR ACCESS THE SPARROW PLATFORM.
          <br /> <br />
          BY ACKNOWLEDGING THE TERMS OF SERVICE AND/OR USING THE SPARROW PLATFORM, YOU EXPRESSLY ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND AND AGREE,
          WITHOUT LIMITATION OR QUALIFICATION, TO BE BOUND BY THIS AGREEMENT (INCLUDING, AS APPLICABLE FOR U.S AND CANADIAN USERS, THE DISPUTE
          RESOLUTION AND ARBITRATION AGREEMENT) AND YOU ACCEPT ALL OF ITS TERMS.
          <br /> <br />
          Your use of the Sparrow Platform in a country other than the country in which you register constitutes your acceptance of and agreement to
          all of the terms and conditions in the Agreement for that country.
          <br /> <br />A few highlights of these Terms of Service include:
        </p>{" "}
        <br />
        <ul>
          <li>
            You must be at least the legally required age in the jurisdiction in which you reside (18 years old for U.S. based users), and otherwise
            capable of entering into binding contracts, in order to use or access the Sparrow Platform (Section 2).
          </li>
          <li>Your agreement that the technology for the Sparrow Platform is provided “as is” and without warranty (Section 17).</li>
          <li>
            Your agreement that Sparrow provides no warranty and has no liability regarding User action on the Sparrow Platform or the performance of
            Tasks (Section 17).
          </li>
          <li>
            Your acknowledgment and agreement that Sparrow does not supervise, scope, direct, control, or monitor a Students’s work and the Tasks
            performed (Section 1).
          </li>
          <li>
            Your acknowledgement and agreement that Clients are solely responsible for determining if the Student they hire is qualified to perform
            the Task (Sections 1 and 23).
          </li>
          <li>
            Your acknowledgement and agreement that Students are independent contractors of Clients and not employees, independent contractors or
            service providers of Sparrow (Section 1).
          </li>
          <li>
            Your agreement to hold harmless and indemnify Sparrow from claims due to your use of or inability to use the Sparrow Platform or content
            submitted from your account to the Sparrow Platform (Section 18).
          </li>
        </ul>
        <h3>1. The Sparrow Platform Connects Students and Clients</h3>
        <ul>
          <li>
            The Sparrow Platform is a web- and app-based two-sided marketplace which enables connections between Clients and Students.“Clients” are
            individuals and/or businesses seeking to obtain short-term services ( “Tasks” ) from Students and are therefore clients of Students, and
            “Students” are businesses seeking to perform Tasks for Clients. Clients and Students together are hereinafter referred to as “Users.” If
            you agree on the terms of a Task with another User, you and such other User form a Service Agreement directly between the two of you as
            set forth in more detail in Section 3 below.
          </li>
          <li>
            STUDENTS ARE INDEPENDENT BUSINESS OWNERS. STUDENTS ARE INDEPENDENT CONTRACTORS OF CLIENTS AND NOT EMPLOYEES, PARTNERS, REPRESENTATIVES,
            AGENTS, JOINT VENTURERS, INDEPENDENT CONTRACTORS OR FRANCHISEES OF SPARROW. SPARROW DOES NOT PERFORM TASKS AND DOES NOT EMPLOY INDIVIDUALS
            TO PERFORM TASKS. BY CONNECTING PEOPLE AND BUSINESSES SEEKING SERVICES WITH SERVICE PROVIDERS, SPARROW OPERATES AS AN ONLINE MARKETPLACE
            THAT CONNECTS CLIENTS WITH SERVICE PROVIDERS (STUDENTS) WHO WISH TO PERFORM A VARIETY OF TASKS.
          </li>
          <li>
            USERS HEREBY ACKNOWLEDGE THAT SPARROW DOES NOT SUPERVISE, SCOPE, DIRECT, CONTROL OR MONITOR A STUDENTS’S WORK AND EXPRESSLY DISCLAIMS (TO
            THE EXTENT PERMITTED BY LAW) ANY RESPONSIBILITY AND LIABILITY FOR THE WORK PERFORMED AND THE TASKS IN ANY MANNER, INCLUDING BUT NOT
            LIMITED TO A WARRANTY OR CONDITION OF GOOD AND WORKMANLIKE SERVICES, WARRANTY OR CONDITION OF QUALITY OR FITNESS FOR A PARTICULAR PURPOSE,
            OR COMPLIANCE WITH ANY LAW, STATUTE, ORDINANCE, REGULATION, OR CODE.
          </li>
          <li>
            Any reference on the Sparrow Platform to a Student being licensed or credentialed in some manner, or "badged," “reliable,” “reliability
            rate,” “elite,” “great value,” "background checked," “vetted” (or similar language) designations indicates only that the Student has
            completed a relevant account registration process or met certain criteria and does not represent anything else. Any such description is
            not an endorsement, certification or guarantee by Sparrow of such Students’s skills or qualifications or whether they are licensed,
            insured, trustworthy, safe or suitable. Instead, any such description is intended to be useful information for Clients to evaluate when
            they make their own decisions about the identity and suitability of Students whom they select, interact, or contract with via the Sparrow
            Platform.
          </li>
          <li>
            The Sparrow Platform enables connections between Users for the fulfillment of Tasks. Sparrow is not responsible for the performance or
            communications of Users, nor does it have control over the quality, timing, legality, failure to provide, or any other aspect whatsoever
            of Tasks, Students, or Clients, nor of the integrity, responsibility, competence, qualifications, or any of the actions or omissions
            whatsoever of any Users, or of any ratings or reviews provided by Users with respect to each other. Sparrow makes no warranties or
            representations about the suitability, reliability, timeliness, or accuracy of the Tasks requested or services provided by, or the
            communications of or between, Users identified through the Sparrow Platform, whether in public or private, via on- or off-line
            interactions, or otherwise howsoever.
          </li>
        </ul>
        <h3>2. Student Background Checks and User Representations and Warranties</h3>
        <h3>Student Background Checks</h3>
        <p>
          Students may be subject to a review process before they can register for and during their use of the Sparrow Platform, which may include but
          is not limited to identity verification and criminal background checks, using third party services as appropriate ( “Background Checks” ).
          Although Sparrow may perform Background Checks, Sparrow cannot confirm that any User is who they claim to be, and Sparrow cannot and does
          not assume any responsibility for the accuracy or reliability of Background Check information. When interacting with other Users, you should
          exercise caution and common sense to protect your personal safety, data, and property, just as you would when interacting with other persons
          whom you don’t know. Sparrow will not be liable for any false or misleading statements made by Users of the Sparrow Platform. NEITHER
          SPARROW, NOR ITS PARENTS, AFFILIATES OR LICENSORS, INCLUDING THEIR RESPECTIVE DIRECTORS, OFFICERS, SHAREHOLDERS, AGENTS, INVESTORS,
          SUBSIDIARIES, ATTORNEYS, REPRESENTATIVES, INSURERS, EMPLOYEES, SUCCESSORS AND ASSIGNS (COLLECTIVELY REFERRED TO AS “AFFILIATES” ) IS
          RESPONSIBLE OR LIABLE FOR THE CONDUCT, ACTS, OR OMISSIONS, WHETHER ONLINE OR OFFLINE, OF ANY USER OF THE SPARROW PLATFORM AND, TO THE EXTENT
          PERMITTED BY LAW, YOU HEREBY RELEASE SPARROW AND AFFILIATES FROM ANY AND ALL LIABILITY, CLAIMS, DEMANDS, OR DAMAGES OF EVERY KIND AND
          NATURE, KNOWN AND UNKNOWN, SUSPECTED AND UNSUSPECTED, DISCLOSED AND UNDISCLOSED, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE SPARROW
          PLATFORM.
        </p>
        <h4>User Representations and Warranties</h4>
        <h4>All Users represent and warrant that:</h4>
        <ul>
          <li>
            You are at least of the legally required age in the jurisdiction in which you reside (18 years of age or older for U.S. based users), and
            are otherwise capable of entering into binding contracts;
          </li>
          <li>
            You have the right, authority and capacity to enter into this Agreement and to abide by the terms and conditions of this Agreement, and
            that you will so abide;
          </li>
          <li>
            You have read, understand, and agree to be bound by these Terms of Service and the Privacy Policy applicable to the country where the Task
            is performed;
          </li>
          <li>Tasks shall only be performed in a country where the Sparrow Platform has a presence.</li>
          <li>
            You will respect the privacy (including without limitation private, family and home life), property, and data protection rights of Users
            and will not record (whether video or audio or otherwise) any Task or any interaction by or with any User and/or Sparrow in connection
            with the Sparrow Platform without the prior written consent of Sparrow and/or the relevant User, as applicable;
          </li>
          <li>
            You will fulfill the commitments you make to other Users, communicate clearly and promptly through the chat thread, be present and/or
            available at the time you agree upon with your Student or Client as the case may be, and only utilize the third party payment service
            provider specified or approved by Sparrow to make or receive payment for services provided through the Sparrow Platform (the “PSP” );
          </li>
          <li>You will act professionally and responsibly in your interactions with other Users;</li>
          <li>You will use your real name or business name on your profile;</li>
          <li>
            When using or accessing the Sparrow Platform, you will act in accordance with all applicable local, state, provincial, national, or
            international law or custom and in good faith;
          </li>
          <li>
            You will not use the Sparrow Platform for the purchase or delivery of alcohol, or any other controlled or illegal substances or services.
          </li>
          <li>
            Where you enter into this Agreement on behalf of a company or other organization, you represent and warrant that you have authority to act
            on behalf of that entity and to bind that entity to this Agreement.
          </li>
          <li>
            Other than as fully and promptly disclosed in writing to Sparrow, you do not have any motivation, status, or interest that Sparrow may
            reasonably wish to know about in connection with the Sparrow Platform, including without limitation, if you are using or will or intend to
            use the Sparrow Platform for any journalistic, academic, investigative, or unlawful purpose.
          </li>
        </ul>
        <p>Students additionally represent and warrant that:</p>
        <ul>
          <li>
            When using the Sparrow Platform, you are operating as a sole proprietor, partnership, limited liability company, limited liability
            partnership, corporation or other business entity;
          </li>
          <li>
            You are customarily engaged in an independently established business of the same nature as the services performed for Clients through the
            Sparrow Platform, and maintain an independent clientele;
          </li>
          <li>You have the unrestricted right to work in the jurisdiction in which you will be performing Tasks;</li>
          <li>
            If the Task is performed in a jurisdiction that requires you to have a business license or business tax registration, you must have the
            required business license or business tax registration;
          </li>
          <li>
            You are responsible for identifying and obtaining any required licenses, permits, or registrations before offering services and
            undertaking Tasks (including but not limited to a state contractor’s license pursuant to California Business and Professions Code section
            7000 et seq., if such license is applicable to the Task you are performing);
          </li>
          <li>You have any and all insurance required to operate your business and provide your services;</li>
          <li>You will use your real name or business name and an up-to-date photo on your profile;</li>
          <li>
            You will honor your commitments to other Users on the Platform, including by responding to invitations promptly; performing the Task(s) as
            agreed upon with your Client; and providing timely, high-quality services to your Clients;
          </li>
          <li>
            You will only offer and provide services for which you have the necessary skills and expertise and provide those services safely and in
            accordance with all applicable laws.
          </li>
        </ul>
        <h3> 3. Contract between Clients and Students</h3>
        <ul>
          <li>
            You acknowledge and agree that a legally binding contract with another User (the“ Service Agreement ” ) is formed when you and that User
            agree on the terms of a Task. The terms of the Service Agreement include the terms set forth in this Section 3, the engagement terms
            proposed and accepted on the Sparrow Platform, and any other contractual terms accepted by both the Student and their Client to the extent
            such terms do not conflict with the terms in this Agreement, including this Section 3, and do not expand Sparrow’s obligations or restrict
            Sparrow’s rights under this Agreement. Sparrow is not a party to any Service Agreement and the formation of a Service Agreement will not,
            under any circumstances, create an employment or other service relationship between Sparrow and the Student, nor will it create an
            employment relationship between the Client and the Student. Users do not have authority to enter into written or oral — whether implied or
            express — contracts on behalf of Sparrow.
          </li>
          <li>
            Where approved in advance by the Client,the Student is not obligated to personally perform the Task. Taskers may engage assistants,
            helpers, subcontractors or other personnel (collectively “Student Assistants” ). For safety reasons, such Student Assistants shall have
            been registered through the Sparrow Platform. A Student’s failure to comply with this provision shall be a violation of these Terms of
            Service and could lead to removal from the Sparrow Platform. The Student assumes full and sole responsibility for the acts and omissions
            of such Student Assistants and is fully responsible for the lawful payment of all compensation, benefits and expenses of Student
            Assistants, if any, and for all required and applicable tax withholdings as to such Student Assistants. Clients are responsible for
            confirming with their Student that any Student Assistants are registered Students on the Sparrow Platform.
          </li>

          <li>
            While using the Sparrow Platform, Clients, in their sole discretion, determine whether they will be present or not when a Task is
            performed. Clients agree that if someone other than them books the Task on their behalf or is present in their stead when the Task is
            performed, they are appointing that person as their agent (“ Client’s Agent ” ) and the Student may take and follow direction from the
            Client’s Agent as if such direction was given from the Client him- or herself. If you are a Client’s Agent and accessing and using the
            Sparrow Platform on behalf of a Client, you represent that you have the authority to act as their agent and to bind that person as the
            principal to all provisions of the Terms of Service. Client’s Agent may authorize any applicable waiver of the Client’s scoping,
            direction, or instruction of the Student’s work or performance of the Task in the Client’s stead. Client agrees that such direction and/or
            waiver is valid against Client and Client’s Agent, and Client shall be responsible and liable for all such direction and/or waiver as if
            made by Client him or herself. Client assumes full and sole responsibility for the acts and omissions of Client’s Agents. Client’s Agents
            are deemed third parties for the purposes of the Happiness Pledge and are thereby excluded from it.
          </li>
          <li>
            The Client shall pay their Student (s) in full for all Task services via the PSP as indicated on the Sparrow Platform, at the rates agreed
            to by the parties in the Service Agreement. Each User agrees to comply with the Service Agreement and this Agreement during the
            engagement, performance and completion of a Task.
          </li>
        </ul>
        <h3>4. Billing and Payment</h3>
        <p>
          Users of the Sparrow Platform contract for Tasks directly with other Users. Sparrow will not be a party to any contracts for Tasks or
          services. Client, and not Sparrow, is responsible for payment for all Task services through the Sparrow Platform. Such payment must be made
          via the PSP. Sparrow is not obligated to compensate Students for Client’s failure to pay for services. The Task Payment, service charge, and
          Trust & Support fee must be paid through the PSP. Clients on the Sparrow Platform will be required to provide their payment method details
          to Sparrow and the PSP. Students are responsible for accurately invoicing their Clients within 24 hours of the work being performed, even if
          the Task is not completed in its entirety or is designated as “ongoing”. Clients will be responsible for paying the invoice(s) for each Task
          (the “Invoice(s)” ), which will include (a) the pricing terms of the Task provided by the Tasker and agreed upon by the parties ( “Task
          Payment” ), (b) any out of pocket expenses agreed upon by the parties and submitted by the Tasker in connection with the Task, (c) the
          service charge Sparrow assesses to the Client for access to and information regarding Students, and (d) the Trust & Support Fee Sparrow
          assesses to the Client for customer support, and other various other services, or (e) cancellation charges, if applicable. In addition, a
          tip or gratuity, as applicable, may be added by the Client or at the Client’s direction to the Invoice(s), and all such tips or gratuities
          shall go directly to the Student. Clients may also be charged credit card processing fees equal to 3% of the aggregate expense amount if
          expenses related to a Task individually, or Tasks in the aggregate over a 30-day period, exceed $300 / 300£ / 300€, as applicable in your
          country. Student will be responsible for paying (i) registration fees, if applicable, and (ii) repayment of erroneous payments. Students
          will be required to set up an account with the PSP, which may require any or all of the following: registration with the PSP, consent to the
          terms of service of the PSP, and completion of a vetting process and/or account validation at the request of the PSP. By accepting these
          Terms of Service, each Student agrees that they have downloaded or printed, and reviewed and agreed to, the PSP Services Agreement (the “PSP
          Services Agreement” ). Please note that Sparrow is not a party to the PSP Services Agreement and that you, the PSP and any other parties
          listed in the PSP Services Agreement are the parties to the PSP Services Agreement and that Sparrow has no obligations, responsibility or
          liability to any Student or other party under the PSP Services Agreement. To help prevent fraud and safeguard User information from the risk
          of unauthorized access, Sparrow and/or the PSP may validate an account before activation and prior to each booking. As part of the
          validation process, temporary charges may be placed on the account associated with the User and then refunded within 1-5 business days. This
          temporary charge may vary depending on the estimated value of the Task and expenses. When Client receives confirmation through the Sparrow
          Platform or via email that a Task has been completed, Client automatically authorizes the PSP to process the Invoice(s). Clients may be
          billed a one-hour cancellation charge as damages at the Student’s hourly rate through the PSP if you book a Task but cancel it before the
          scheduled time for performance. Sparrow reserves the right (but not the obligation) upon request from Client or Student, or upon notice of
          any potential fraud, unauthorized charges or other misuse of the Sparrow Platform, to place on hold any Task Payment, out of pocket
          expenses, and/or tip or gratuity (if applicable), or refund or provide credits, or arrange for the PSP to do so. Users of the Sparrow
          Platform may be liable for any taxes or similar charges (including VAT, if applicable in the country where the Task is performed) required
          to be collected and/or paid on the Tasks and/or fees provided under the Agreement. In certain jurisdictions, applicable rules may require
          that we collect or report tax and/or revenue information about you. You agree that Sparrow may issue on your behalf receipts or similar
          documentation to facilitate accurate tax reporting.
        </p>
        <h3>5. Deactivation and Suspension</h3>
        <p>
          Sparrow may suspend your right to use the Sparrow Platform pending its investigation of a potential breach by you of this Agreement. Sparrow
          may deactivate your account or limit your use of the Sparrow Platform upon its determination that you breached a provision of this Agreement
          (a “User Breach” ). Sparrow will provide you with written notice of its determination as required by law, unless we have reason to believe
          the User’s account has been compromised such that the notice would go to the wrong person, or notice would otherwise be counterproductive or
          would create a risk to safety . If you wish to appeal this determination, please contact us within 14 days of receipt of such notice with
          the grounds for your appeal . If Sparrow suspends or deactivates your account or limits your use of the Sparrow Platform pursuant to this
          Section 8, you are thereafter prohibited from registering and creating a new account under your name or business name, a fake or borrowed
          name, or the name of any third party, even if you may be acting on behalf of the third party. Even after your right to use the Sparrow
          Platform is suspended, terminated or limited, this Agreement will remain enforceable against you. Sparrow reserves the right to take
          appropriate legal action pursuant to the Agreement. Sparrow reserves the right to modify or discontinue, temporarily or permanently, all or
          any portion of the Sparrow Platform at its sole discretion. Sparrow will provide you with notice of any such modification or discontinuation
          as required by law. To the extent permitted by law, Sparrow shall not be liable to you for any modification or discontinuance of all or any
          portion of the Sparrow Platform. Sparrow has the right to restrict anyone from completing registration as a Student if such person may
          threaten the safety and integrity of the Sparrow Platform, or if such restriction is necessary to address any other reasonable business
          concern. You may terminate this Agreement at any time by ceasing all use of the Sparrow Platform and deactivating your account.
        </p>
        <h3>6. Sparrow Operates as an Online Marketplace</h3>
        <p>
          Sparrow operates as an online marketplace that connects Clients with service providers (Students) who wish to perform a variety of Tasks.
          Sparrow does not perform Tasks and does not employ people to perform Tasks. Students operate as independent business owners and are
          customarily engaged in an independently established business of the same nature as that involved in the services performed for Clients
          through the Sparrow Platform. Sparrow does not control or direct the Students’ performance of their services or set their work locations,
          work hours, or terms of work. Students provide services under their own name or business name, and not under Sparrow’s name. Students
          provide their own tools and supplies to perform their services; Sparrow does not provide the tools or supplies. Students are free to
          maintain a clientele without any restrictions from Sparrow and are free to offer and provide their services elsewhere, including through
          competing platforms. Students are free to accept or reject Clients and contracts. Students are not penalized for rejecting Clients or
          contracts, though if Students accept a Client or contract through the Sparrow Platform, they are expected to fulfill their contractual
          obligations to their client. Students set their own rates for services performed in the Sparrow general marketplace, without deduction by
          Sparrow. The Sparrow Platform is not an employment agency service or business and Sparrow is not an employer of any User. Students
          acknowledge and confirm that they are responsible for exercising their own business judgment in entering into Service Agreements and
          performing Tasks and that, depending on how they exercise such business judgment, there is a chance for individual profit or loss.
        </p>
        <h3>7. Account, Password, Security, and Telephone Communications</h3>
        <p>
          You must register with Sparrow and create an account to use the Sparrow Platform. You are responsible for maintaining the confidentiality of
          any log-in, password, and account number provided by you or given to you by Sparrow for accessing the Sparrow Platform. You are solely and
          fully responsible for all activities that occur under your password or account, even if not authorized by you. Sparrow has no control over
          the use of any User’s account and expressly disclaims any liability derived therefrom. Should you suspect that any unauthorized party may be
          using your password or account or you suspect any other breach of security, you agree to notify Sparrow immediately. You acknowledge that
          telephone calls to or from Sparrow, together with its agents and Affiliates, may be monitored and recorded for the purposes of quality
          control and training. You verify that any contact information provided to Sparrow, its agents and Affiliates, and Users, including, but not
          limited to, your name, business name, mailing address, email address, your residential or business telephone number, and/or your mobile
          telephone number, is true and accurate. You verify that you are the current subscriber or owner of any telephone number that you provide.
          You are strictly prohibited from providing a phone number that is not your own. If we discover that any information provided in connection
          with your registration is false or inaccurate, we may suspend or deactivate your account. Should any of your contact information change, you
          agree to immediately notify Sparrow before the change goes into effect.
        </p>
        <h3>8. Sparrow “User Pledge”</h3>
        <p>
          The Sparrow User Pledge applicable to the country where the Task is performed is part of this Agreement and incorporated by reference.
          Students determine which categories they are qualified to task in, and scope the Task directly with their Client. Sparrow does not oversee,
          monitor or direct how a Student performs a Task, does not monitor Tasks or chat threads between Users, and does not otherwise assume
          responsibility for the actions of Users. Clients are advised to confirm with their Student that s/he is qualified to perform the Task prior
          to the Task taking place. Sparrow is not liable for the acts or omissions of Users, nor does Sparrow provide insurance against any losses
          sustained by Users. That said, Sparrow wants Users to be happy about their experience using the Sparrow Platform and the User Pledge is in
          place to encourage continued use of the Sparrow Platform. Payments made under the User Pledge are made at Sparrow’s sole discretion, and are
          subject to certain conditions, limitations and exclusions, as described in the User Pledge. The User Pledge does not supersede any of the
          terms set forth in these Terms of Service or the Privacy Policy, including any of the limitations on liability set forth in these Terms of
          Service. In the unlikely event of a conflict with the User Pledge, these Terms of Service prevail over any contrary interpretation of the
          User Pledge. The User Pledge is not insurance and Sparrow is not an insurer, as such terms are generally understood for regulatory purposes.
        </p>
        <h3>9. User Generated Content</h3>
        <p>
          “User Generated Content” is defined as any information and materials you provide to Sparrow, its agents, Affiliates, and corporate partners,
          or other Users in connection with your registration for and use of the Sparrow Platform and participation in Sparrow promotional campaigns,
          including without limitation the information and materials posted or transmitted for use in Public Areas. You are solely responsible for
          User Generated Content, and we act merely as a passive conduit for your online distribution and publication of your User Generated Content.
          You acknowledge and agree that Sparrow is not involved in the creation, development of User Generated Content, disclaims any responsibility
          for User Generated Content, and cannot be liable for claims arising out of or relating to User Generated Content. Further, you acknowledge
          and agree that Sparrow has no obligation to monitor or review User Generated Content, but reserves the right to limit or remove User
          Generated Content if it is not compliant with the terms of this Agreement. You hereby represent and warrant to Sparrow that your User
          Generated Content (a) will not be false, inaccurate, incomplete or misleading; (b) will not be fraudulent or involve the transfer or sale of
          illegal, counterfeit or stolen items; (c) will not infringe on any third party’s privacy, or copyright, patent, trademark, trade secret or
          other proprietary right or rights of publicity or personality (to the extent recognized by law in the country where the Task is performed);
          (d) will not violate any law, statute, ordinance, code, or regulation (including without limitation those governing export control, consumer
          protection, unfair competition, anti-discrimination, incitement of hatred or false or misleading advertising, anti-spam or privacy); (e)
          will not be defamatory, libellous, malicious, threatening, or harassing; (f) will not be obscene or contain pornography (including but not
          limited to child pornography) or be harmful to minors; (g) will not contain any viruses, scripts such as Trojan Horses, SQL injections,
          worms, time bombs, corrupt files, cancelbots or other computer programming routines that are intended to damage, detrimentally interfere
          with, surreptitiously intercept or expropriate any system, data or personal information; (h) will not claim or suggest in any way that you
          are employed or directly engaged by or affiliated with Sparrow or otherwise purport to act as a representative or agent of Sparrow; and (i)
          will not create liability for Sparrow or cause Sparrow to lose (in whole or in part) the services of its Internet Service Providers (ISPs)
          or other partners or suppliers. The Sparrow Platform hosts User Generated Content relating to reviews and ratings of specific Taskers (
          “Feedback” ). Feedback is such User’s opinion and not the opinion of Sparrow, and has not been verified or approved by TaskRabbit. You agree
          that Sparrow is not responsible or liable for any Feedback or other User Generated Content. Sparrow encourages each User to give objective,
          constructive and honest Feedback about the other Users with whom they have transacted . Sparrow is not obligated to investigate any remarks
          posted by Users for accuracy or reliability or to consider any statements or materials posted or submitted by Users about any Feedback but
          may do so at its discretion. You agree that Feedback enables Users to post and other Users to read about Users’ expression of their
          experiences and that you will not complain or take any action merely because you happen to disagree with such Feedback. You may request
          removal of a review that violates this Agreement by contacting the Support team. Each Client should undertake their own research prior to
          booking to be satisfied that a specific Student has the right qualifications for a Task. Sparrow respects the personal and other rights of
          others, and expects Users to do the same. Sparrow is entitled to identify a User to other Users or to third parties who claim that their
          rights have been infringed by User Generated Content submitted by that User, so that they may attempt to resolve the claim directly. If a
          User believes, in good faith, that any User Generated Content provided on or in connection with the Sparrow Platform is objectionable or
          infringes any of its rights or the rights of others (e.g. counterfeiting, insult, invasion of privacy), the User is encouraged to notify
          Sparrow. If a User discovers that User Generated Content promotes crimes against humanity, incites hatred and/or violence, or concerns child
          pornography, the User must notify Sparrow.
        </p>
        <h3>10. Intellectual Property Rights</h3>
        <p>
          All text, graphics, editorial content, data, formatting, graphs, designs, HTML, look and feel, photographs, music, sounds, images, software,
          videos, typefaces and other content, including Sparrow designs, trademarks, and logos (collectively “Proprietary Material” ) that Users see
          or read through the Sparrow Platform is owned by Sparrow, excluding User Generated Content, which Users hereby grant Sparrow a license to
          use as set forth above. Proprietary Material is protected in all forms, media and technologies now known or hereinafter developed. Sparrow
          owns all Proprietary Material, as well as the coordination, selection, arrangement and enhancement of such Proprietary Materials. The
          Proprietary Material is protected by domestic and international laws governing copyright, patents, and other proprietary rights. Users may
          not copy, download, use, redesign, reconfigure, or retransmit anything from the Sparrow Platform without Sparrow’s express prior written
          consent and, if applicable, the consent of the holder of the rights to the User Generated Content. Any use of such Proprietary Material
          other than as permitted herein is expressly prohibited. The service marks and trademarks of Sparrow, including without limitation Sparrow,
          Sparrow for Good, and associated logos, are service marks owned by Sparrow. Any other trademarks, service marks, logos and/or trade names
          appearing via the Sparrow Platform are the property of their respective owners. Sparrow’s proprietary marks and logos are not available for
          use by Students. You may not copy or use any of these marks, logos or trade names without the express prior written consent of the owner.
        </p>
        <h3>11. Copyright Complaints and Copyright Agent</h3>
        <p>
          Sparrow respects the intellectual property of others and expects Users to do the same. If you believe, in good faith, that any materials
          provided on or in connection with the Sparrow Platform infringe upon your copyright or other intellectual property right, please contact us.
        </p>
        <ul>
          <li>
            A description of the copyrighted work that you claim has been infringed, including the URL (Internet address) or other specific location
            on the Sparrow Platform where the material you claim is infringed is visible. Include enough information to allow Sparrow to locate the
            material, and explain why you think an infringement has taken place;
          </li>
          <li>
            A description of the location where the original or an authorized copy of the copyrighted work exists -- for example, the URL (Internet
            address) where it is posted or the name of the book in which it has been published;
          </li>
          <li>Your name, address, telephone number, and e-mail address;</li>
          <li>
            A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the
            law;
          </li>
          <li>
            A statement by you, made under penalty of perjury, that the information in your notice is accurate, and that you are the copyright owner
            or authorized to act on the copyright owner’s behalf; and
          </li>
          <li>
            Your electronic or physical signature as the owner of the copyright or the person authorized to act on behalf of the owner of the
            copyright interest.
          </li>
        </ul>
        <h3>12. Confidential Information</h3>
        <p>
          You acknowledge that Confidential Information (as defined below) is a valuable, special and unique asset of Sparrow and agree that you will
          not, for the lifetime of your account on Sparrow plus 10 years thereafter, disclose, transfer, or use (or seek to induce others to disclose,
          transfer or use) any Confidential Information for any purpose other than use of the Sparrow Platform in accordance with these Terms of
          Service. If relevant, you may disclose the Confidential Information to your authorized employees and agents provided that they are also
          bound to maintain the confidentiality of Confidential Information. You shall use best efforts to protect Confidential Information from
          unauthorized disclosure, transfer or use. You shall promptly notify Sparrow in writing of any circumstances that may constitute unauthorized
          disclosure, transfer, or use of Confidential Information. You shall return all originals and any copies of any and all materials containing
          Confidential Information to Sparrow promptly upon deactivation of your User account or termination of this Agreement for any reason
          whatsoever. The term “Confidential Information” shall mean any and all of Sparrow’s trade secrets, confidential and proprietary information,
          and all other information and data of Sparrow that is not generally known to the public or other third parties who could derive value,
          economic or otherwise, from its use or disclosure. Confidential Information shall be deemed to include technical data, know-how, research,
          product plans, products, services, customers, markets, software, developments, inventions, processes, formulas, technology, designs,
          drawings, engineering, hardware configuration information, marketing, finances, strategic and other Proprietary Materials and confidential
          information relating to Sparrow or Sparrow’s business, operations or properties, including information about Sparrow’s staff, Users or
          partners, or other business information disclosed or obtained directly or indirectly in writing, orally or by drawings or observation.
        </p>
        <h3>13. Disclaimer of Warranties</h3>
        <p>A. Use Of The Sparrow Platform Is Entirely At Your Own Risk</p>
        <p>
          THE TECHNOLOGY OF THE SPARROW PLATFORM IS PROVIDED ON AN “AS IS” BASIS WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR
          IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, GOOD AND WORKMANLIKE
          SERVICES, AND NON-INFRINGEMENT. SPARROW MAKES NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE CONTENT PROVIDED
          THROUGH THE SPARROW PLATFORM OR THE CONTENT OF ANY SITES LINKED TO THE SPARROW PLATFORM AND ASSUMES NO LIABILITY OR RESPONSIBILITY IN
          CONTRACT, WARRANTY OR IN TORT FOR ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT, (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY
          NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SPARROW PLATFORM, (III) ANY ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR
          ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN; AND (IV) EVENTS BEYOND OUR REASONABLE CONTROL. Sparrow does
          not warrant, endorse, guarantee or assume responsibility for any service advertised or offered by a third-party through the Sparrow Platform
          or any hyperlinked website or featured in any banner or other advertising, and Sparrow will not be a party to or in any way be responsible
          for any transaction between you and other Users, or you and third-party providers of products or services. As with the purchase of a product
          or service through any medium or in any environment, you should use your best judgment and exercise caution where appropriate. Without
          limiting the foregoing, Sparrow and Affiliates do not warrant that access to the Sparrow Platform will be uninterrupted or that the Sparrow
          Platform will be error-free; nor do they make any warranty as to the results that may be obtained from the use of the Sparrow Platform, or
          as to the timeliness, accuracy, reliability, completeness or content of any Task, service, information or materials provided through or in
          connection with the use of the Sparrow Platform. Sparrow and Affiliates are not responsible for the conduct, whether online or offline, of
          any User. Sparrow and Affiliates do not warrant that the Sparrow Platform is free from computer viruses, system failures, worms, trojan
          horses, or other harmful components or malfunctions, including during hyperlink to or from third-party websites. Sparrow and Affiliates will
          implement appropriate technical and organizational measures to ensure a level of security adapted to the risk for any personal information
          supplied by you. Notwithstanding any feature or service that a Client may use to expedite Student selection, each Client is responsible for
          determining the Task and selecting or otherwise approving their Student and Sparrow does not warrant any goods or services purchased by a
          Client and does not recommend any particular Student. Sparrow does not provide any warranties or guarantees regarding any Student’s ability,
          professional accreditation, registration or licensure.
        </p>
        <p>B. No Liability</p>
        <p>
          You acknowledge and agree that Sparrow is only willing to provide the Sparrow Platform if you agree to certain limitations of our liability
          to you and third parties. Therefore, you agree not to hold Sparrow and Affiliates, or their corporate partners, liable for any claims,
          demands, damages, expenses, losses, governmental obligations, suits, and/or controversies of every kind and nature, known and unknown,
          suspected and unsuspected, disclosed and undisclosed, direct, indirect, incidental, actual, consequential, economic, special, or exemplary,
          including attorney’s fees and costs (collectively, “Liabilities” ) that have arisen or may arise, relating to your or any other party’s use
          of or inability to use the Sparrow Platform, including without limitation any Liabilities arising in connection with the conduct, act or
          omission of any User (including without limitation stalking, harassment that is sexual or otherwise, acts of physical violence, and
          destruction of personal property), any dispute with any User, any instruction, advice, act, or service provided by Sparrow and Affiliates,
          and any destruction of your User Generated Content. UNDER NO CIRCUMSTANCES WILL SPARROW AND AFFILIATES OR THEIR CORPORATE PARTNERS BE LIABLE
          FOR, AND YOU HEREBY RELEASE SPARROW AND AFFILIATES AND THEIR CORPORATE PARTNERS FROM ANY DIRECT, INDIRECT, INCIDENTAL, ACTUAL,
          CONSEQUENTIAL, ECONOMIC, SPECIAL OR EXEMPLARY DAMAGES (INCLUDING BUT NOT LIMITED TO LOST PROFITS, LOSS OF DATA, LOSS OF GOODWILL, SERVICE
          INTERRUPTION, COMPUTER DAMAGE, SYSTEM FAILURE, FAILURE TO STORE ANY INFORMATION OR OTHER CONTENT MAINTAINED OR TRANSMITTED BY SPARROW, THE
          COST OF SUBSTITUTE PRODUCTS OR SERVICES, OR ATTORNEYS FEES AND COSTS) ARISING OUT OF OR IN ANY WAY CONNECTED WITH YOUR USE OF OR INABILITY
          TO USE THE SPARROW PLATFORM OR THE TASK SERVICES, EVEN IF ADVISED OF THE POSSIBILITY OF THE SAME. Some jurisdictions do not allow the
          exclusion of certain warranties or limitation of incidental or consequential damages; in such cases the above limitations may not apply to
          you in their entirety. SPARROW AND AFFILIATES EXPRESSLY DISCLAIM ANY LIABILITY THAT MAY ARISE BETWEEN USERS OF ITS SPARROW PLATFORM. SPARROW
          AND AFFILIATES ALSO DO NOT ACCEPT ANY LIABILITY WITH RESPECT TO THE QUALITY OR FITNESS OF ANY WORK PERFORMED VIA THE SPARROW PLATFORM. IF,
          NOTWITHSTANDING THE FOREGOING EXCLUSIONS, IT IS DETERMINED THAT SPARROW AND AFFILIATES OR THEIR CORPORATE PARTNERS ARE LIABLE FOR DAMAGES,
          IN NO EVENT WILL THE AGGREGATE LIABILITY, WHETHER ARISING IN CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE, EXCEED THE TOTAL FEES PAID BY
          YOU TO SPARROW (IF YOU ARE A CLIENT) OR TOTAL TASK PAYMENTS PAID TO YOU BY CLIENTS (IF YOU ARE A STUDENT), DURING THE 6 MONTHS PRIOR TO THE
          TIME SUCH CLAIM AROSE, TO THE EXTENT PERMITTED BY APPLICABLE LAW.
        </p>
        <h3>14. Indemnification</h3>
        <p>
          You hereby agree to indemnify, defend, and hold harmless Student and Affiliates from and against any and all Liabilities incurred in
          connection with (i) your use or inability to use, or your participation on, the Student Platform; (ii) your participation in Tasks, or your
          ability or inability to perform or obtain the performance of Tasks or to receive payment therefore; (iii) your breach or violation of this
          Agreement; (iv) your violation of any law, or the rights of any User or third party; (v) your failure to abide by your representations and
          warranties in Section 2; (vi) any content submitted by you or using your account to the Sparrow Platform, including but not limited to the
          extent such content may infringe on the intellectual rights of a third party or otherwise be illegal or unlawful; and (vii) if you are a
          Client, the acts or omissions of any Client’s Agents. Sparrow reserves the right, in its own sole discretion, to assume the exclusive
          defense and control of any matter otherwise subject to your indemnification. You will not, in any event, settle any claim or matter without
          the prior written consent of Sparrow.
        </p>
        <h3>15. Dispute Resolution</h3>
        <p>
          To expedite resolution and reduce the cost of any dispute, controversy or claim related to, arising from or regarding your use of the
          Sparrow Platform, your relationship with Sparrow, Tasks, or this Agreement (including previous versions), ( “Dispute” ), you and Sparrow
          agree to first attempt to negotiate any Dispute informally for at least 30 days before initiating any out of court settlement ( such as
          mediation or arbitration) or court proceeding. Such informal negotiations will commence upon written notice. Your address for such notices
          is the one associated with your account, with an email copy to the email address you have provided to Sparrow.
        </p>
        <h3>16. No Agency; No Employment</h3>
        <p>
          No agency, partnership, joint venture, employer-employee or franchiser-franchisee relationship is intended or created by this Agreement.
        </p>
        <h3>17. General Provisions</h3>
        <p>
          Failure by Sparrow to enforce any provision(s) of this Agreement will not be construed as a waiver of any provision or right. This Agreement
          constitutes the complete and exclusive agreement between you and Sparrow with respect to its subject matter, and supersedes and governs any
          and all prior agreements or communications. The provisions of this Agreement are intended to be interpreted in a manner which makes them
          valid, legal, and enforceable. Except for the “Agreement Prohibiting Class Actions and Non-Individualized Relief” provision in the section
          applicable to U.S. Residents, in the event any provision is found to be partially or wholly invalid, illegal or unenforceable, (1) such
          provision shall be modified or restructured to the extent and in the manner necessary to render it valid, legal, and enforceable or, (2) if
          such provision cannot be so modified or restructured, it shall be excised from the Agreement without affecting the validity, legality or
          enforceability of any of the remaining provisions. You hereby acknowledge and agree that we may assign or transfer this Agreement without
          your consent, including but not limited to assignments: (i) to a parent or subsidiary, (ii) to an acquirer of assets, or (iii) to any other
          successor or acquirer. Upon the effective date of the assignment of the Agreement (a) Sparrow shall be relieved of all rights, obligations
          and/or liabilities to you arising in respect of events postdating the effective date of the assignment and, (b) the assignee entity shall
          replace Sparrow for the performance of this Agreement. This Agreement may not be assigned or transferred by you without our prior written
          approval. Any assignment in violation of this Section 22 shall be null and void. This Agreement will inure to the benefit of Sparrow, its
          successors and assigns. All parts of this Agreement which by their nature should survive the expiration or termination of this Agreement
          shall continue in full force and effect subsequent to and notwithstanding the expiration or termination of this Agreement or your use of the
          Sparrow Platform.
        </p>
        <h3>18. Licensing</h3>
        <p>
          Students alone are responsible for identifying and obtaining any required licenses, permits, or registrations before offering services and
          undertaking Tasks. Indeed, certain types of Tasks and services may be prohibited altogether, and it is the responsibility of Students to
          avoid such prohibited Tasks and services. Penalties may include fines or other law enforcement. If you have questions about how national/
          state/ provincial/ territorial and local laws apply to your Tasks and services on the Sparrow Platform, you should first seek appropriate
          legal guidance. Because Sparrow does not supervise, scope, direct, control, or monitor a Student’s work or performance of Tasks, Clients
          must determine for themselves whether a Student has the skills and qualifications necessary to perform the specific Task at hand. Clients
          may wish to consult their national/state/provincial/territorial or local requirements to determine whether certain Tasks are required to be
          performed by a licensed or otherwise registered professional. Clients may also wish to discuss with their Student any specific hazards,
          obstacles, or impediments in the Task location (both visible and concealed) that may impact the performance of the Task.
        </p>
        <h3>19. Changes to this Agreement and the Sparrow Platform</h3>
        <p>
          Sparrow reserves the right, at its sole and absolute discretion, to change, modify, add to, supplement, suspend, discontinue, or delete any
          of the terms and conditions of this Agreement (including the Terms of Service, Privacy Policy, and User Pledge) and review, improve, modify
          or discontinue, temporarily or permanently, the Sparrow Platform or any content or information through the Sparrow Platform at any time,
          effective with or without prior notice and without any liability to Sparrow. Sparrow may also impose limits on certain features or restrict
          your access to part or all of the Sparrow Platform without notice or liability. Sparrow will endeavor to notify you of material changes to
          this Agreement by email, but will not be liable for any failure to do so. If any future changes to this Agreement are unacceptable to you or
          cause you to no longer be in compliance with this Agreement, you must deactivate your account, and immediately stop using the Sparrow
          Platform. Your continued use of the Sparrow Platform following any revision to this Agreement constitutes your complete and irrevocable
          acceptance of any and all such changes, except where prohibited by any laws or regulations in your jurisdiction.
        </p>
        <h3>20. No Rights of Third Parties</h3>
        <p>
          The provisions of this Agreement are for the sole benefit of the Parties and their permitted successors and assigns, and they will not be
          construed as conferring any rights to any third party (including any third party beneficiary rights or to give any person or entity other
          than the User any interest, remedy, claim, liability, reimbursement, claim of action or any other claim of action with respect to or in
          connection with any agreement or provision contained herein or contemplated hereby. None of the terms of this Agreement are enforceable by
          any persons who are not a party to this Agreement, provided, however, that a Client’s Agent may act in the name of and on behalf of his/her
          Client.
        </p>
        <h3>21. Notices and Consent to Receive Notices Electronically</h3>
        <p>
          You consent to receive any agreements, notices, disclosures and other communications (collectively, “Notices” ) to which this Agreement
          refers electronically, including without limitation by email or by posting Notices on the Sites. You agree that all Notices that we provide
          to you electronically satisfy any legal requirement that such communications be in writing. Unless otherwise specified in this Agreement,
          all Notices under this Agreement will be in writing and will be deemed to have been duly given when received, if personally delivered or
          sent by certified or registered mail, return receipt requested; when receipt is electronically confirmed, if transmitted by facsimile or
          email; or the day it is shown as delivered by the overnight delivery service’s tracking information, if sent for next day delivery by a
          recognized overnight delivery service.
        </p>
        <h3>22. Consent to Electronic Signatures</h3>
        <p>
          By using the Sparrow Platform, you agree to transact electronically through the Sparrow Platform. You also agree to the following: (i) your
          electronic signature is the legal equivalent of your manual signature and has the same legal effect, validity and enforceability as a
          paper-based signature; (ii) your use of a keypad, mouse or other device to select an item, button, icon or similar act/action, constitutes
          your signature as if actually signed by you in writing; and (iii) no certification authority or other third party verification is necessary
          to validate your electronic signature, and the lack of such certification or third party verification will not in any way affect the
          enforceability of your electronic signature.
        </p>
      </Container>
    </div>
  );
};

export default TermsOfServices;
