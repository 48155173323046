import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { deleteStudent } from "../../services/savestudents/DeleteStudent";
import { getAllSaveStudents } from "../../services/savestudents/GetAllStudents";
import { saveStudent } from "../../services/savestudents/SaveStudent";
import { ShowAllStudents } from "../../services/users/ShowAllStudents";
import ReactStars from "react-stars";

export default function StudentProfileCardFav({ studentData, bookNow, setSavedStudents }) {
  // console.log(searchedValue);
  let navigate = useNavigate();
  const [isSaved, setIsSaved] = useState(() => {
    // Determine if the student is saved initially
    return !!studentData?.student_saved_by?.length || !!studentData?.student_saved_id;
  });

  const getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  // defing userrole and user id
  let user_role = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.role;
  let user_id = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.id;
  // delete student
  const action_DeleteFav = async (std_id, num) => {
    if (JSON.parse(localStorage.getItem("sparrowSignIn"))?.user) {
      if (user_role === "client") {
        const resp = await deleteStudent(std_id);
        // if num is 1 then view all screen api calls and for 2 savedStudents screen api is called || 3 is for featured student from home page
        if (num === 1) {
          await ShowAllStudents(2, JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.id);
        } else if (num === 2) {
          const respSavedStudents = await getAllSaveStudents(user_id);
          setSavedStudents(respSavedStudents?.data?.student);
        }
        //  else if (num === 3) {
        //     const respSavedStudents = await ShowHomePageFeaturedStudents()
        //     setAllStudent(respSavedStudents?.data?.users)
        // }
        // checking student is removed or so
        if (resp.status === 200) {
          setIsSaved(false); // Mark as not saved
          console.log("removed");
        } else {
          // debugger
          Swal.fire({
            title: "Too many requests" || resp.data.message,
            timer: 1500,
            icon: "error",
            showConfirmButton: false,
          });
        }
      } else if (user_role === "student") {
        Swal.fire({
          title: "Please log in as a client",
          timer: 1500,
          icon: "error",
          showConfirmButton: false,
        });
      }
    } else {
      setTimeout(() => {
        navigate("/signin");
      }, 0);
    }
  };

  let countPrimaryTask = 0;
  let terminateLoop = false;

  // papulate about variable from home page featured api aur student api

  let imageUrl = "/assets/images/Groupa21765.png";
  if (studentData?.image) {
    imageUrl = studentData?.image;
  }

  // let studentAboutData = "Morbi et placerat lorem. In nec dui mattis, iaculis nulla erat et, scelerisque erat metus. Donec id euismod euismod erat. Morbi et placerat lorem. In nec dui mattis,";
  // if (studentData?.student_details?.about) {
  //     studentAboutData = (studentData?.student_details?.about.length > 0) ? studentData?.student_details?.about + ' ' : 'None';

  // } else if (studentData?.about) {
  //     studentAboutData = (studentData?.about) ? studentData?.about + ' ' : 'None';
  // }
  // papulate minum rate variable from home page featured api aur student api
  let studentMinumunHourlyRate = 0;
  if (studentData?.student_details?.minimum_hourly_rate) {
    studentMinumunHourlyRate = studentData?.student_details?.minimum_hourly_rate;
  } else if (studentData?.minimum_hourly_rate) {
    studentMinumunHourlyRate = studentData?.minimum_hourly_rate;
  }

  let studentDateOfBirth = studentData?.student_details?.dob
    ? studentData?.student_details?.dob && " " + getAge(studentData?.student_details?.dob) + "'"
    : studentData?.dob && " " + getAge(studentData?.dob) + "'";
  //paulate value for star rating
  // let starRatingValue = 0
  // if (studentData?.rating[0]?.overall_rating) {
  //     starRatingValue = studentData?.rating[0]?.overall_rating
  // } else if (studentData?.overall_rating) {
  //     starRatingValue = studentData?.rating[0]?.overall_rating
  // }

  let pathPage = `/signInRoute/all-students/single-student-profile/${studentData.id}`;
  if (localStorage.getItem("sparrowSignIn")) {
    pathPage = `/dashboardRoute/all-students/customer-student-profile/${studentData.id}`;
  }
  let skillValue = studentData?.student_details?.skills ? studentData?.student_details?.skills : studentData?.skills;

  const resultForPrimaryTask =
    skillValue?.length > 0
      ? skillValue.split(/\s*,\s*/).map((item, index) => {
          if (!terminateLoop) {
            let displayItem = item;

            // Handle tutoring and coaching cases
            if (item.toLowerCase().includes("tutoring") && item.length > 27) {
              displayItem = "Tutoring";
            } else if (item.toLowerCase().includes("coaching") && item.length > 27) {
              displayItem = "Coaching";
            }

            if (displayItem.toLowerCase().includes("sports")) {
              displayItem = displayItem.replace("Sports ", "");
            }

            if (displayItem.includes("|")) {
              displayItem = displayItem.replace("|", " | ");
            }

            countPrimaryTask += displayItem.length;

            if (countPrimaryTask <= 28 && index < 4) {
              return <span key={index}>{displayItem.trim()}</span>;
            } else {
              terminateLoop = true;
              return (
                <Link
                  to={{
                    pathname: `/dashboardRoute/all-students/customer-student-profile/${studentData.id}`,
                  }}
                  role="button"
                  key={index}
                >
                  <img src="/assets/images/customerDashboard/ActionsPlus_bold.svg" className="imageForMoreTask" alt="" />
                </Link>
              );
            }
          }
          return null;
        })
      : "";

  // saving students
  const action_AddToFav = async (std_id) => {
    if (JSON.parse(localStorage.getItem("sparrowSignIn"))?.user) {
      if (user_role === "client") {
        const data = { client_id: user_id, student_id: std_id };
        const resp = await saveStudent(data);

        if (resp.status === 200) {
          setIsSaved(true); // Mark as saved
        } else {
          Swal.fire({
            title: "Too many requests" || resp.data.message,
            timer: 1500,
            icon: "error",
            showConfirmButton: false,
          });
        }
      } else {
        Swal.fire({
          title: "Please log in as a client",
          timer: 1500,
          icon: "error",
          showConfirmButton: false,
        });
      }
    } else {
      setTimeout(() => {
        navigate("/signin");
      }, 0);
    }
  };
  // console.log(studentData?.totalOrders)

  // const handleImageError = (e) => {
  //   e.target.src = '/assets/images/dummy12.jpeg'; // Set the src attribute to your default image URL
  //   e.target.alt = 'Default Image'; // Optional: Change the alt text for the default image
  // };
  return (
    <div className="profileBox border-mb-fields profileBoxForStudent">
      <div className="profileContainer">
        <div className="profileImg">
          <img className="profileImgM imgBorder" src={imageUrl} alt="" />
          {studentData?.totalOrders > 50 ? (
            <div className="hoverTextDiv">
              <img className="badgeStdnt" src="/assets/images/badges/goldA.svg" alt="" />
              <span className="hoverText setResonive">This student has done over 100 tasks</span>
            </div>
          ) : studentData?.totalOrders > 25 && studentData?.totalOrders <= 50 ? (
            <div className="hoverTextDiv">
              <img className="badgeStdnt" src="/assets/images/badges/silverb.svg" alt="" />
              <span className="hoverText setResonive">This student has done 25-50 tasks</span>
            </div>
          ) : (
            <div className="hoverTextDiv">
              <img className="badgeStdnt" src="/assets/images/badges/bronzec.svg" alt="" />
              <span className="hoverText setResonive">This student has done 0-25 tasks</span>
            </div>
          )}
          {/* student_saved_id coming from customermyStudent component and is for checking student is saved so show goldenRomal img  || none is coming from HomeStudentProfile component || 3 in function and isSaved is for featured student from home page*/}
          <div className={`hoverTextDiv`}>
            {isSaved || studentData?.student_saved_by?.length > 0 || studentData?.student_saved_id ? (
              // Render "Remove from saved students"
              <>
                {console.log(studentData?.student_saved_id || studentData?.student_saved_by?.[0]?.id || studentData?.id)}
                <Link onClick={() => action_DeleteFav(studentData?.id, 2)}>
                  <img src="/assets/images/home/goldenlabelicon.png" alt="Remove from saved students" className="romaalimg favClassLabel" />
                  <span className="hoverText setResonive">Remove from saved students</span>
                </Link>
              </>
            ) : (
              // Render "Add to favorite students"
              <Link onClick={() => action_AddToFav(studentData?.id)}>
                <img src="/assets/images/home/greynlabelicon.png" alt="Add to favorite students" className="romaalimg" />
                <span className="hoverText setResonive">Add to favorite students</span>
              </Link>
            )}
          </div>
        </div>
        <div className="profilHed profileBoxStars mb-3" onClick={() => navigate(pathPage)}>
          <h3 className="profileBoxH3">
            {studentData ? (
              <>
                {studentData.first_name && studentData?.first_name}
                {studentData.last_name && " " + studentData?.last_name.charAt(0).toUpperCase()}
                {studentDateOfBirth}
              </>
            ) : (
              ""
            )}
          </h3>
          <p className="profileBoxP elementCenter">
            {/* <span><img src="/assets/images/home/cap.svg" alt="" /></span> */}
            {studentData?.student_details?.college || studentData?.college
              ? studentData?.student_details?.college || studentData?.college
              : "No college available"}
          </p>
          <div className="profileBoxStars">
            {studentData?.overall_rating || studentData?.rating?.length > 0 ? (
              <ReactStars
                count={5}
                size={25}
                edit={false}
                color2={"#FFC700"}
                color1={"#FFC70040"}
                className="pe-none d-flex justify-content-center"
                value={
                  studentData?.overall_rating || studentData?.rating?.length > 0
                    ? studentData?.overall_rating || studentData?.rating[0]?.overall_rating
                    : 5
                }
              />
            ) : (
              <div className="marginNeg">
                {/* <img
                  style={{ marginBottom: "6px" }}
                  // src="/assets/images/customerDashboard/dullStarForAdvSearch.svg"
                  src="/assets/images/customerDashboard/Star 8.svg"
                  alt=""
                /> */}
                <span className="newStd">New student</span>
              </div>
            )}
          </div>
          <div className="houlyRateValueNew profileBoxStars">Avg. ${studentMinumunHourlyRate}/hr</div>
        </div>
        <div className="profileContent" onClick={() => navigate(pathPage)}>
          <h4 className="profileBoxSubhed">Featured Tasks</h4>
          <div className="profileTaks flex-wrap">
            {studentData?.skills || studentData?.student_details?.skills ? (
              resultForPrimaryTask
            ) : (
              <>
                <span>None</span>
              </>
            )}
          </div>
        </div>
        <div className="profileAbout" onClick={() => navigate(pathPage)}>
          <h4 className="profileBoxSubhed">About me</h4>
          <p>
            {
              <span className="overflowText line-clamp">
                {studentData?.student_details?.about || studentData?.about
                  ? studentData?.student_details?.about || studentData?.about
                  : "Not available"}
              </span>
            }
            {/* {studentData && (studentData?.student_details?.about?.length > 130 || studentData?.about?.length > 130) ? <Link to={{ pathname: `/dashboardRoute/all-students/customer-student-profile/${studentData.id}` }} className="green stdcardViewMore"> View More</Link>: null }  */}
          </p>
        </div>
      </div>
      <div className="profileBtn">
        <Link
          to={
            JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.role === "client"
              ? `/dashboardRoute/all-students/create-tasks/${studentData?.id}/${studentData?.fcm_token}`
              : JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.role === "student"
              ? "/dashboardRoute/student-profile/"
              : "/signin"
          }
          className="primary bookNowBtn"
        >
          {bookNow}
        </Link>
      </div>
    </div>
  );
}
