import { Col, Container, Row } from "react-bootstrap";
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import { Pagination } from "swiper";
import { Link } from "react-router-dom";

const HomeTasksResponsive = () => {
  return (
    <div className="HomeTasksResponsive">
      <div className="homeUserSlidrResponsive">
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          // loop={true}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="taskBox">
              <div className="boxImg">
                <img
                  src="/assets/images/studentfreelance/Housekeeping.svg"
                  alt=""
                />
              </div>
              <div className="taskBoxContent">
                <h4 className="green">Housekeeping</h4>
                <p>
                  Find a student to help with any <br /> cleaning chores inside
                  your house.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="taskBox">
              <div className="boxImg">
                <img
                  src="/assets/images/studentfreelance/Tutoring.svg"
                  alt=""
                />
              </div>
              <div className="taskBoxContent">
                <h4 className="green">Tutoring</h4>
                <p>
                  Book a tutor to help your child improve grades and class
                  performance.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="taskBox">
              <div className="boxImg">
                <img
                  src="/assets/images/studentfreelance/Pet Sitting.svg"
                  alt=""
                />
              </div>
              <div className="taskBoxContent">
                <h4 className="green">Pet Sitting</h4>
                <p>
                  Find a student pet-lover to take your furry friend out for a
                  long walk.{" "}
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="taskBox">
              <div className="boxImg">
                <img
                  src="/assets/images/studentfreelance/Sports Coaching.svg"
                  alt=""
                />
              </div>
              <div className="taskBoxContent">
                <h4 className="green">Sports Coaching</h4>
                <p>
                  Book a student-athlete to improve your kid's athletic skills
                  or just have fun.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="taskBox">
              <div className="boxImg">
                <img src="/assets/images/studentfreelance/Moving.svg" alt="" />
              </div>
              <div className="taskBoxContent">
                <h4 className="green">Moving</h4>
                <p>
                  Find a student to carry boxes and furniture to prepare your
                  new home.
                </p>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="taskBox">
              <div className="boxImg">
                <img
                  src="/assets/images/studentfreelance/Child Care.svg"
                  alt=""
                />
              </div>
              <div className="taskBoxContent">
                <h4 className="green">Child Care</h4>
                <p>
                  Get a babysitter to look after your child, while you focus on other daily activities.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="taskBox">
              <div className="boxImg">
                <img
                  src="/assets/images/studentfreelance/Eldercare.svg"
                  alt=""
                />
              </div>
              <div className="taskBoxContent">
                <h4 className="green">Eldercare</h4>
                <p>
                  Let a cheerful student provide care and home visits for your
                  loved ones.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="taskBox">
              <div className="boxImg">
                <img
                  src="/assets/images/studentfreelance/Yard Work.svg"
                  alt=""
                />
              </div>
              <div className="taskBoxContent">
                <h4 className="green">Yard Work</h4>
                <p>
                  Let a student water plants, remove dirt or mow the lawn in
                  your backyard.
                </p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default HomeTasksResponsive;
