export const navList = [
  {
    id: 1,
    name: "Clients",
    link: "/",
    type: "global",
  },
  {
    id: 2,
    name: "Student Freelancers",
    link: "/signInRoute/student-freelancer",
    type: "global",
  },
  {
    id: 3,
    name: "Our Mission",
    link: "/signInRoute/about",
    type: "global",
  },
  {
    id: 7,
    name: "Contact Us",
    link: "/signInRoute/contact-us",
    type: "mobileOnly",
  },
  {
    id: 4,
    taskName: "Post Task",
    link: "/dashboardRoute/all-students/create-tasks/null/null",
    type: "client",
  },
  {
    id: 5,
    auth: "Sign In",
    link: "/signin",
    type: "signInBtn",
  },
  {
    id: 6,
    imageName: "Image",
    link: "/assets/images/userimg.png",
    type: "globalImg",
  },
];
