import React, { useEffect } from 'react'
import DashboardNav from '../dashboardnavsidebar/DashboardNav'
import Footer from '../navbarfooter/Footer'
import { useLocation } from 'react-router';

const NavbarSignedIn = ({ children }) => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return (
        <div>
            <DashboardNav />
            <div className="navMain5pagesSignedIn" >
                {children}
            </div>
            <Footer/>
        </div>
    )
}

export default NavbarSignedIn