import React, { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { collection, getDocs, getFirestore, query, where } from "firebase/firestore";
import { firebaseConfig } from "../../firebase/FireBase";
import moment from "moment";
import { saveStudent } from "../../services/savestudents/SaveStudent";
// import { getAllSaveStudents } from "../../services/savestudents/GetAllStudents";
import { savedStdArray } from "./ChatArray";
import { deleteStudent } from "../../services/savestudents/DeleteStudent";

const ChatRooms = ({ key, userId, getMessages, roomId, lastMessage, lastTime, seen, messageSenderId, isActive, appendRoomId, sendReminder }) => {
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  let user_id = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.id.toString();
  let user_Role = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.role;

  let finalLastTime = moment.unix(lastTime / 1000).calendar();
  const [item, setItem] = useState([]); // Store user details
  const [isFavorite, setIsFavorite] = useState(false); // Track favorite state
  const [isReminderSent, setIsReminderSent] = useState(false); // Track if reminder is sent
  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    getUserById();
    // getSavedStudentFunc();
  }, []);

  const getUserById = async () => {
    let finalUserId = userId.find((id) => id !== user_id);
    const usersCol = query(collection(db, "Users"), where("id", "==", finalUserId.trim()));
    const usersSnapshot = await getDocs(usersCol);
    const usersList = usersSnapshot.docs.map((doc) => doc.data());
    setItem(usersList);
  };

  const [loder, setLoder] = useState(false);
  // const getSavedStudentFunc = async () => {
  //   const response = await getAllSaveStudents(parseInt(user_id));
  //   console.log(response);
  //   if (response.status === 200) {
  //     const savedStudents = response?.data?.student || [];
  //     const isSaved = savedStudents.some((student) => student.id === item[0]?.id);
  //     setIsFavorite(isSaved);
  //   }
  // };

  const toggleFavorite = async (userData) => {
    setLoder(true);
    if (isFavorite) {
      // Remove from favorites
      const response = await deleteStudent(userData.id);
      if (response.status === 200) {
        setIsFavorite(false);
      }
    } else {
      // Add to favorites
      const data = {
        client_id: parseInt(user_id),
        student_id: parseInt(userData.id),
      };
      const response = await saveStudent(data);
      if (response.status === 200) {
        setIsFavorite(true);
      }
    }
    setLoder(false);
  };

  return (
    <div
      className={`chatBody ${isActive ? "chatBodyActive" : ""} ${messageSenderId !== user_id && seen === false ? "unreadChatColor" : ""}`}
      // onClick={(e) => getMessages(item[0], roomId, e)}
      onClick={(e) => {
        getMessages(item[0], roomId, e);
        appendRoomId(roomId); // Call the function to append roomId
      }}
    >
      <div className="chatUser">
        <div>
          <img className="chatImg" src={item[0]?.imageUrl} alt="" />
        </div>
        <div className="chatUserContent">
          <div className="setContentChatUser">
            <h5 className="chatUserContentH5">{item[0]?.name?.charAt(0).toUpperCase() + item[0]?.name?.slice(1)}</h5>
            <div className="chatUserTimeMain dispFlrxChht">
              <span className="chatUserTime">{finalLastTime}</span> &nbsp; &nbsp;
              {loder ? (
                <div className="lds-spinner minorLod">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              ) : (
                <div className="hoverTextDiv hver1" style={{ zIndex: "9" }}>
                  <button
                    disabled={isReminderSent}
                    // className="primary sendReminderButton"
                    className={`primary bookNowBtn ${isReminderSent ? "disalbed" : "primary"}`}
                    style={{
                      color: isReminderSent ? "white" : "white",
                      border: "none",
                      padding: "4px 8px",
                      borderRadius: "4px",
                      fontSize: isMobile ? "10px" : "11px", // Adjust font size dynamically
                      padding: isMobile ? "3px 6px" : "4px 8px",
                      cursor: isReminderSent ? "not-allowed" : "pointer",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsReminderSent(true); // Disable the button
                      sendReminder(Number(user_id), Number(item[0].id), lastMessage, roomId);
                    }}
                  >
                    {isReminderSent ? "Sent" : "Send Reminder"}
                  </button>
                </div>
              )}
            </div>
          </div>
          <p className="chatUserContentp">{lastMessage}</p>
        </div>
      </div>
    </div>
  );
};

export default ChatRooms;
