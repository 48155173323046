import React from "react";
import { Col } from "react-bootstrap";

const StudentDetails = ({ orderData, getAge }) => {
  {
    console.log("data is: ", orderData);
  }
  return (
    <Col md={12}>
      <div className="studentDetails">
        <div className="studentDetailsImg">
          <img src={orderData?.image} alt="" />
        </div>
        <h5 style={{ marginBottom: "10px" }} className="upH5">
          {orderData?.first_name
            .charAt(0)
            .toUpperCase() +
            orderData?.first_name.slice(1)}{" "}
          &nbsp;
          {orderData?.last_name.charAt(0).toUpperCase()}
          .
          {orderData?.student_details?.dob &&
            " " +
              getAge(orderData?.student_details?.dob) +
              "'"}
        </h5>
        <h5 className="belowH5">
          {orderData?.student_details?.grade}{" "}
        </h5>
        <div className="studentContent ml-1">
          <div className="mb-3 block ml-3232">
            <img src="/assets/images/customerDashboard/capvector.svg" alt="" />
            <span className="ml-7">
              {orderData?.student_details?.college
                ? orderData?.student_details?.college
                : "Melo College"}
            </span>
          </div>
          <div className="mb-3 block ml-3232">
            <img
              src="/assets/images/customerDashboard/lcoationvector.svg"
              alt=""
            />
            <span className="ml-7">
              {orderData?.location
                ? `${orderData?.location?.city?.trim()}, ${orderData?.location?.state.trim()}`
                : "New York"}
            </span>
          </div>
          <div className="mb-3 block ml-3232">
            <img
              src="/assets/images/customerDashboard/verified-dashboar.svg"
              alt=""
            />
            <span className="ml-7">
              {orderData?.email_verified_at !== null
                ? "Verified"
                : "Not Verified"}
            </span>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default StudentDetails;
