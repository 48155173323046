import { Container } from 'react-bootstrap'
import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Pagination } from "swiper";
// import StudentProfileCard from './StudentProfileCard';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { ShowHomePageFeaturedStudents } from '../../services/users/ShowHomePageFeaturedStudents';
import FeaturedStudent from './featuredstudentcards/FeaturedStudent';
import Loader from '../loader/Loader';

function HomeStudentProfile() {
    const [loder, setLoder] = useState(true);

    const [allStudent, setAllStudent] = useState(false);
    let client_id = 0
    if (localStorage.getItem('sparrowSignIn')) {
        client_id = JSON.parse(localStorage.getItem('sparrowSignIn'))?.user?.id;
    }
    const getFeaturedStudent = async () => {
        const response = await ShowHomePageFeaturedStudents(client_id);
        if (response.status === 200) {
            setAllStudent(response?.data?.users);
            setLoder(false);
        } else {
            Swal.fire({
                title: response.data.message,
                timer: 1500,
                icon: "error",
                showConfirmButton: false,
            });
        }
    };

    useEffect(() => {
        getFeaturedStudent();
    }, [])

    const bookNow = 'Connect';
    // const response1 = await ShowHomePageFeaturedStudents();
    // setAllStudent(response1.data.users)
    return (
        <div className='stdProfile'>
            <Container>
                <div className='profileHead mt-4'>
                    <h2>Find your vetted <span className='green'>Student Helper</span></h2>
                    <h6 className='subhead'>A platform exclusively built on ambitious, creative, and reliable students.</h6>
                </div>

                <div className='profileBoxes homeUserSlidr homeUserSlidrResponsive remove padding0'>
                    {loder ? (
                        <div className="height100vh">
                            <Loader />
                        </div>
                    ) : (
                        <Swiper
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                },
                                768: {
                                    slidesPerView: 2,
                                },
                                922: {
                                    slidesPerView: 3,
                                },
                            }}
                            slidesPerView={3}
                            spaceBetween={30}
                            // loop={true}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Pagination]}
                            className="mySwiper"
                        >
                            {allStudent.slice(0, 5).map((singleStudent) => {
                                if (singleStudent?.email_verified_at !== null) {
                                    return (
                                        <SwiperSlide key={singleStudent?.id}>
                                            <FeaturedStudent studentData={singleStudent} bookNow={bookNow} setAllStudent={setAllStudent} />
                                        </SwiperSlide>
                                    )
                                }
                            })
                            }
                        </Swiper>
                    )}
                </div>

                <div>
                    <Link to="/signInRoute/all-students" className='primary viewAllbtn'>View All</Link>
                </div>
            </Container>
        </div>
    )
}

export default HomeStudentProfile