import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { getStudentOrders } from "../../services/order/StudentOrderList";
import moment from "moment";
import Loader from "../loader/Loader";

const StudentTaskHistory = () => {
  const navigate = useNavigate();

  const [loder, setLoder] = useState(true);
  const std_id = parseInt(
    JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.id
  );

  const [orderList, setOrderList] = useState([]);
  // getting orders list
  const getOrderListFunc = async () => {
    const response = await getStudentOrders(std_id);

    if (response.status === 200) {
      setOrderList(response?.data?.orders);
      setLoder(false);
    } else {
      Swal.fire({
        title: response.data.message,
        timer: 1500,
        icon: "error",
        showConfirmButton: false,
      });
      setLoder(false);
    }
  };

  useEffect(() => {
    getOrderListFunc();
  }, []);

  const columns = [
    {
      name: "Client",
      button: true,
      cell: (row) => (
        <div className="studentHed row w-100">
          <div className="col-5 col-sm-6 px-0" style={{ textAlign: "right" }}>
            <img
              style={{ border: "2px solid #82d051" }}
              src={row?.client?.image}
              alt=""
            />
          </div>
          <div className="col-7 col-sm-6 px-0">
            <p>{row?.client?.first_name?.charAt(0).toUpperCase() + row?.client?.first_name?.slice(1)}</p>
            <p className="taskNameForHistoryTable d-none">{row?.task?.title}</p>
          </div>
        </div>
      ),
      width: "230px",
    },
    {
      name: "Task",
      selector: (row) => row?.task?.title,
      hide: "sm",
    },
    {
      name: "Hourly pay",
      selector: (row) => "$" + row?.task?.hourly_rate,
      hide: "sm",
    },
    {
      name: "Start date",
      selector: (row) => moment(row?.task?.start_date).format("L"),
      hide: "sm",
    },
    {
      name: "End date",
      cell: (row) => (
        <div className="studentHed">
          {/* {row?.feedback?.created_at
            ? moment(row?.feedback?.created_at).format("L")
            : ""} */}
          {row?.task?.end_date ? moment(row?.task?.end_date).format("L") : ""}
        </div>
      ),
      hide: "sm",
    },
    {
      name: "Status",
      button: true,
      cell: (row) => (
        <div className="statusForTaskHistory">
          {/* {adding onClick={() => onRowClicked(row)} fucntion here just to add click functionality works also for buttons} */}
          {row?.status === "pending" ? (
            <button
              onClick={() => onRowClicked(row)}
              className="tableBtn ornage"
            >
              Pending
            </button>
          ) : row?.status === "in progress" || row?.status === "unpaid" ? (
            <button
              onClick={() => onRowClicked(row)}
              className="tableBtn colorActive"
            >
              Active
            </button>
          ) : row?.status === "closed" ? (
            <button onClick={() => onRowClicked(row)} className="tableBtn">
              Completed
            </button>
          ) : (
            <button
              onClick={() => onRowClicked(row)}
              className="tableBtn activeRed"
            >
              Cancelled
            </button>
          )}
          <p className="formobileStatusTaskHistory d-none">
            {/* {row?.feedback?.created_at &&
              moment(row?.feedback?.created_at).format("DD MMM") + " - "} */}
            {moment(row?.task?.start_date).format("DD MMM")}
            {row?.task?.end_date &&
              " - " + moment(row?.task?.end_date).format("DD MMM")}
          </p>
        </div>
      ),
      width: "150px",
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontSize: "18px",
        fontWeight: "600",
        color: "#000000",
        fontFamily: "Manrope",
        backgroundColor: "white",
        borderBottom: "1px solid #EDEDED",
        paddingTop: "19px !important",
        paddingBottom: "19px !important",
      },
    },
    cells: {
      style: {
        color: "#000000",
        fontSize: "15px",
        fontWeight: "500",
        fontFamily: "Poppins",
        backgroundColor: "#fff",
        paddingTop: "12px !important",
        paddingBottom: "12px !important",
      },
    },
    headRow: {
      style: {
        borderRadius: "8px",
        color: "#FFFFFF",
        fontSize: "14px",
        fontWeight: "400",
        border: "none !important",
      },
    },
  };

  const onRowClicked = (single_row) => {
    // sending order id
    navigate(
      `/dashboardRoute/task-history/student-task-descp/${single_row?.task_id}/${single_row?.id}`
    );
  };

  return (
    <div className="bgNotiLines">
      <h2 className="text-center">
        Task <span className="green">History</span>
      </h2>
      <Container>
        {loder ? (
          <div className="height100vh">
            <Loader />
          </div>
        ) : (
          <div className="orderDataTable TxtCenter">
              <div style={{ position: "relative" }}>
          <span><img className="info5" src="/assets/images/newlogos/Info.svg" alt="" />
            <span className="hoverTextForR5">Your task history provides an overview of the tasks you've been hired for. To access more detailed information about each task and the respective client, simply click on the status button.
            </span>
          </span>
        </div>
            <DataTable
              title="My Tasks"
              customStyles={customStyles}
              columns={columns}
              data={orderList}
              onRowClicked={onRowClicked}
              className="studentTaskHistoryTable"
            />
          </div>
        )}
      </Container>
    </div>
  );
};

export default StudentTaskHistory;
