import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
// import { uploadFile } from "../../services/uploadfile/FileUpload";
import { createUser } from "../../services/users/User";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { verificationAccount } from "../../services/authentication/VerificationNotification";
import { useState } from "react";
import "react-phone-number-input/style.css";
import Input from "react-phone-number-input/input";
// firebase
import {
  collection as fireStoreCollectione,
  getFirestore,
  addDoc,
} from "firebase/firestore";
import { db, firebaseConfig } from "../../firebase/FireBase";
import { initializeApp } from "firebase/app";
import { useCookies } from "react-cookie";
import { bucket_url } from "../../services/axios";
// import { base64ToBlob, convertImageToBase64 } from "../../utils/constants";

const SignUpStudent = () => {
  // firebase
  const appNew = initializeApp(firebaseConfig);
  const dbNew = getFirestore(appNew);
  //
  const [loader, setLoader] = useState(false);
  const [cookies] = useCookies(["userSellData"]);

  // for image
  const [picture, setPicture] = useState(null);
  const [imgData, setImgData] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const imagesPreview = (e) => {
    if (e.target.files[0]) {
      const allowedImage = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/gif",
      ];
      let checkImageTypeAndSize = allowedImage.includes(
        e.target.files[0]?.type
      );
      if (e.target.files[0]?.size <= 2097152 && checkImageTypeAndSize) {
        setPicture(e.target.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.addEventListener("load", () => {
          setImgData(reader.result);
        });
      } else {
        Swal.fire({
          title: "Must be an Image of type png,jpg,jpeg,gif under size of 2MB",
          timer: 2500,
          icon: "error",
          showConfirmButton: false,
        });
      }
    }
  };

  //for navigation
  let navigate = useNavigate();
  // handling form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (fData) => {
    try {
      setLoader(true);
      if (fData.agree_Check === false) {
        Swal.fire({
          title: "Please agree to the terms to continue!",
          timer: 2000,
          icon: "info",
          showConfirmButton: false,
        });
        setLoader(false);
        return;
      }

      if (fData.notification_check === false) {
        Swal.fire({
          title: "Please agree to the notification check!",
          timer: 2000,
          icon: "info",
          showConfirmButton: false,
        });
        setLoader(false);
        return;
      }

      const data = {
        first_name: fData.fname,
        last_name: fData.lname,
        email: fData.email,
        password: fData.pass,
        password_confirmation: fData.CPass,
        image: `${bucket_url}/adQShgcDw3AfreoYNtdVUucNm7tu3hx52WthCM2O.jpg`,
        phone: phoneNumber,
        role: 2,
      };
      if (cookies.userSellData) {
        data["is_sell_data_allowed"] = parseInt(cookies.userSellData);
      }

      const cleanedNumber =
        phoneNumber && phoneNumber.length > 2 ? phoneNumber.slice(2) : "";
      if (cleanedNumber == "") {
        setPhoneError("Phone number is required"); // Set the phone number error
        setLoader(false);
        return;
      }

      const resp = await createUser(data);
      const errors = resp?.data?.errors;

      if (resp.status === 200) {
        // firebase
        // if (imageResponse) {
        await addDoc(fireStoreCollectione(dbNew, "Users"), {
          email: fData.email,
          id: String(resp?.data?.user?.id),
          imageUrl: `${bucket_url}/adQShgcDw3AfreoYNtdVUucNm7tu3hx52WthCM2O.jpg`,
          // imageUrl: imageResponse.data.url,
          name: fData.fname.charAt(0).toUpperCase() + fData.fname.slice(1),
        });
        Swal.fire({
          title: "User has been Created!",
          timer: 1500,
          icon: "success",
          showConfirmButton: false,
        });

        // seting item in local storage so student will show logged in screen
        // localStorage.setItem("sparrowSignIn", JSON.stringify(resp.data));
        // calling api for send email to user
        const resp1 = await verificationAccount(resp?.data);
        if (resp1.status !== 200) {
          Swal.fire({
            title: "Email limit has been exceeded!",
            timer: 3000,
            icon: "error",
            showConfirmButton: false,
          });
        } else {
          setTimeout(() => {
            Swal.fire({
              title:
                "Please verify your account. An email has been sent to you!",
              timer: 3000,
              icon: "success",
              showConfirmButton: false,
            });
          }, 1000);
        }

        setTimeout(() => {
          navigate("/signUpRoute/confirm-email");
        }, 3500);
      } else {
        Swal.fire({
          title: errors.email || errors.password || errors.image,
          timer: 3500,
          icon: "error",
          showConfirmButton: false,
        });
        setLoader(false);
      }
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };
  // const isiPhone = window.navigator.platform === 'iPhone';

  return (
    <>
      <div className="signUpStudent">
        <div className="signUpStudentAlignment  mb-signup-form">
          <div className="mb-green-arrow">
            <Link to="/signUpRoute/welcome-to-sparrow">
              <img src="./assets/images/Vector_backIcon.svg" alt="123" />
            </Link>
          </div>
          <div className="block-img-mb mb-head-img">
            <img src="/assets/images/Ellipse 51.png" alt="123" />
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="signUpStudentForm mb-studentsign"
          >
            <div className="parrot-mb">
              {/* {window.navigator.platform == "iPhone" ? (
                <img
                  className="parrot-forgt"
                  // src="/assets/images/newlogos/mobile logo.png"
                  // src="./assets/images/findSparrow_mobileLogo.png"
                  style={{width:"60px",marginBottom:"10px"}}
                  // src="/assets/images/newlogos/mobile logo.png"
                  src="/assets/images/newlogos/mobilelogog.png"
                  alt="parrot"
                  // style={{ width: "82px", height: "78px" }}
                />
              ) : ( */}
              <img
                className="parrot-forgt"
                // src="./assets/images/4A4A4A2022-06-08T1224541.svg"
                style={{ width: "112px", marginBottom: "-17px" }}
                src="/assets/images/newlogos/mobilelogog.png"
                alt="parrot"
              />
              {/* // )} */}
            </div>
            <h2 className="formHeadStudent text-center">Sign Up</h2>
            <div
              className="upperForm signupFormUpload text-center"
              style={{ display: "none" }}
            >
              <div>
                {imgData ? (
                  <img
                    style={{ padding: "7px", borderWidth: "8px" }}
                    className="sizeSet imgBorder"
                    src={imgData}
                    alt=""
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="createFormUploadBtns mb-createFormUploadBtns">
                <div className="uploadBtn text-center">
                  <img
                    src="/assets/images/Uploadphoto.svg"
                    alt=""
                    className="setResonive"
                  />
                  <img
                    src="/assets/images/uploadMobileUploadBtn.svg"
                    alt=""
                    className="d-none makeMobileOnly"
                  />
                  <input
                    onChange={imagesPreview}
                    type="file"
                    accept="image/*"
                  />
                </div>
              </div>
            </div>

            <div className="mb35 d-flex mb-dflex safari">
              <div className="first-mb mb-first">
                <p className="mb-1">First Name</p>
                <input
                  style={{ color: "#C4C4C4" }}
                  placeholder="First Name"
                  className="nameField"
                  type="text"
                  {...register("fname", { required: true })}
                />
                {errors.fname && (
                  <span className="eror">This field is required</span>
                )}
              </div>
              <div className="last-mb mb-last">
                <p className="mb-1 last-signupname">Last Name</p>
                <input
                  style={{ color: "#C4C4C4" }}
                  placeholder="Last Name"
                  className="nameField ml-1"
                  type="text"
                  {...register("lname", { required: true })}
                />
                {errors.lname && (
                  <span className="eror">This field is required</span>
                )}
              </div>
            </div>

            <div className="mb35">
              <p className="mb-1">School Email Address</p>
              <div>
                <input
                  style={{ color: "#C4C4C4" }}
                  placeholder="Email Address"
                  className="createFormLInput mbsignup-form"
                  type="email"
                  {...register("email", {
                    required: true,
                    // pattern: /^[a-zA-Z0-9._%+-]+@(ssmd\.com|([a-zA-Z0-9-]+\.)?([a-zA-Z]{2,}\.)?(edu|org|gov|net|us))$/i,
                    pattern:
                      /^(?!.*@gmail\.)([a-zA-Z0-9._%+-]+@(ssmd\.com|([a-zA-Z0-9-]+\.)?([a-zA-Z]{2,}\.)?(edu|org|gov|net|us)))$/i,
                  })}
                />
                {errors?.email?.type === "required" && (
                  <span className="eror">This field is required</span>
                )}
                {errors?.email?.type === "pattern" && (
                  <span className="eror">
                    Please enter a valid school email address
                    {/* Please enter a valid email address ending with ssmd.com, .edu, .gov, .net, or .us domain */}
                  </span>
                )}
              </div>
            </div>
            {/* <div className="mb35">
              <p className="mb-1">Social Security Number</p>
              <div className="ssnField_mobile">
                <input
                  placeholder="000-00-0000"
                  className="createFormLInput input-mb-create"
                  type="text"
                  value={ssn_number}
                  {...register("socialSecNum", {
                    required: true,
                    onChange: (e) => handleInput(e),
                  })}
                />
              </div>
              {errors.socialSecNum && (
                <span className="eror">This field is required</span>
              )}
            </div> */}
            <div className="mb35">
              <p className="mb-1">Phone Number</p>
              <div>
                <div className="d-flex createFormLInput setPhoneField mr-0">
                  <div className="col-2 phoneFlagPart">
                    <img
                      className="flagStylePhone"
                      src="/assets/images/createForm/US.svg"
                      alt="Flag"
                      width="23"
                    />
                  </div>
                  <Input
                    name="phoneInput"
                    className="col-9 phoneInputNumOnly"
                    country="US"
                    international
                    withCountryCallingCode
                    maxLength="15"
                    onChange={(phone_number) => {
                      const cleanedNumber =
                        phone_number && phone_number.length > 2
                          ? phone_number.slice(2)
                          : "";
                      setPhoneNumber(phone_number);
                      if (cleanedNumber != "") {
                        setPhoneError(""); // Clear the phone number error
                      }
                    }}
                    // {...register("number", { required: true })}
                  />
                </div>
                {phoneError && <span className="eror">{phoneError}</span>}
              </div>
            </div>
            <div className="mb35">
              <p className="mb-1">Create a Password</p>
              <div>
                <input
                  placeholder="Create a Password"
                  className="createFormLInput mbsignup-form"
                  type="password"
                  {...register("pass", { required: true })}
                />
                {errors.pass && (
                  <span className="eror">This field is required</span>
                )}
              </div>
            </div>
            <div className="mb35">
              <p className="mb-1">Confirm Password</p>
              <div>
                <input
                  placeholder="Confirm Password"
                  className="createFormLInput mbsignup-form"
                  type="password"
                  {...register("CPass", { required: true })}
                />
                {errors.CPass && (
                  <span className="eror">This field is required</span>
                )}
              </div>
            </div>
            <div className="mb35">
              <div className="d-flex check__Iacknowledge">
                <input
                  type="checkbox"
                  {...register("agree_Check")}
                  className="mx-3 agreeCheckBox"
                />
                <div>
                  <p>
                    I acknowledge that I agree to the{" "}
                    <Link to="/terms">Terms of Use</Link> and have read the{" "}
                    <Link to="/privacy-policy">Privacy Policy</Link>.{" "}
                  </p>
                  {errors.CPass && (
                    <span className="eror">This field is required</span>
                  )}
                </div>
              </div>
            </div>

            <div className="mb35">
              <div className="d-flex check__Iacknowledge">
                <input
                  type="checkbox"
                  {...register("notification_check")}
                  className="mx-3 agreeCheckBox"
                />
                <div>
                  <p>
                    I agree to receive automated notifications about new
                    contacts, requests and upcoming appointments.
                  </p>
                </div>
              </div>
            </div>

            <div className="gnder display-mb">
              {loader ? (
                <div className="relative">
                  <div className="loader alignLoader"></div>
                  <input
                    className="primary fnt18 mb-fnt18 pl40"
                    type="button"
                    value="Sign Up"
                  />
                </div>
              ) : (
                <input
                  className="primary fnt18 mb-fnt18"
                  type="submit"
                  value="Sign Up"
                />
              )}
            </div>
            <div className="mb-4626">
              <h5>Already have an account?</h5>
            </div>
            <div className="mb-4626 mb-4342">
              <Link to="/signin" className="studentLogIn">
                Log In
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SignUpStudent;
