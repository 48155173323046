import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ReactStars from "react-stars";
import { deleteStudent } from "../../../services/savestudents/DeleteStudent";
import { saveStudent } from "../../../services/savestudents/SaveStudent";
import { ShowHomePageFeaturedStudents } from "../../../services/users/ShowHomePageFeaturedStudents";
// import { ReactComponent as SaveLabelIcon } from window.location.origin+'/assets/images/labelForFavrate.svg';

export default function FeaturedStudent({
  className = "",
  invite,
  bookNow,
  studentData,
  setAllStudent,
  none,
}) {
  let navigate = useNavigate();

  // console.log(studentData)
  const getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  // defing userrole and user id
  let user_role = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.role;
  let user_id = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.id;

  let countPrimaryTask = 0;
  let terminateLoop = false;

  // papulate about variable from home page featured api aur student api

  let imageUrl = "/assets/images/Groupa21765.png";
  if (studentData?.image) {
    imageUrl = studentData?.image;
  }

  // let studentAboutData = "Morbi et placerat lorem. In nec dui mattis, iaculis nulla erat et, scelerisque erat metus. Donec id euismod euismod erat. Morbi et placerat lorem. In nec dui mattis,";
  // if (studentData?.student_details?.about) {
  //     studentAboutData = (studentData?.student_details?.about.length > 0) ? studentData?.student_details?.about + ' ' : 'None';

  // } else if (studentData?.about) {
  //     studentAboutData = (studentData?.about) ? studentData?.about + ' ' : 'None';
  // }
  // papulate minum rate variable from home page featured api aur student api
  let studentMinumunHourlyRate = 0;
  if (studentData?.student_details?.minimum_hourly_rate) {
    studentMinumunHourlyRate =
      studentData?.student_details?.minimum_hourly_rate;
  } else if (studentData?.minimum_hourly_rate) {
    studentMinumunHourlyRate = studentData?.minimum_hourly_rate;
  }

  let studentDateOfBirth = studentData?.student_details?.dob
    ? studentData?.student_details?.dob &&
      " " + getAge(studentData?.student_details?.dob) + "'"
    : studentData?.dob && " " + getAge(studentData?.dob) + "'";
  //paulate value for star rating
  // let starRatingValue = 0
  // if (studentData?.rating[0]?.overall_rating) {
  //     starRatingValue = studentData?.rating[0]?.overall_rating
  // } else if (studentData?.overall_rating) {
  //     starRatingValue = studentData?.rating[0]?.overall_rating
  // }

  let pathPage = `/signInRoute/all-students/single-student-profile/${studentData.id}`;
  if (user_role == "client") {
    pathPage = `/dashboardRoute/all-students/customer-student-profile/${studentData.id}`;
  }

  if (user_role == "student" || user_role == undefined) {
    pathPage = `/signInRoute/all-students/single-student-profile/${studentData.id}`;
  }

  let skillValue = studentData?.student_details?.skills
    ? studentData?.student_details?.skills
    : studentData?.skills;

  const resultForPrimaryTask =
    skillValue?.length > 0
      ? skillValue
          .split(/\s*,\s*/)
          .sort((a, b) => a.length - b.length)
          .map((item, index) => {
            if (!terminateLoop) {
              if (item.toLowerCase().includes("sports")) {
                item = item.replace("Sports ", "");
              }
              if (item.includes("|")) {
                item = item.replace("|", " | ");
              }
              countPrimaryTask += item.length;
              if (countPrimaryTask < 28 && index < 4) {
                return <span>{item.trim()}</span>;
              } else {
                terminateLoop = true;
                return (
                  <Link
                    to={{
                      pathname: `/dashboardRoute/all-students/customer-student-profile/${studentData.id}`,
                    }}
                    role="button"
                  >
                    <img
                      src="/assets/images/customerDashboard/ActionsPlus_bold.svg"
                      className="imageForMoreTask"
                      alt=""
                    />
                  </Link>
                  // break;
                );
              }
            }
          })
      : "";

  // saving students
  const action_AddToFav = async (std_id) => {
    if (JSON.parse(localStorage.getItem("sparrowSignIn"))?.user) {
      // for getting client as a user
      if (user_role === "client") {
        const data = {
          client_id: user_id,
          student_id: std_id,
        };
        const resp = await saveStudent(data);
        const respSavedStudents = await ShowHomePageFeaturedStudents(user_id);
        setAllStudent(respSavedStudents?.data?.users);

        if (resp.status === 200) {
        } else {
          Swal.fire({
            title: resp.data.message,
            timer: 1500,
            icon: "error",
            showConfirmButton: false,
          });
        }
      } else {
        Swal.fire({
          title: "Please log in as a client",
          timer: 1500,
          icon: "error",
          showConfirmButton: false,
        });
      }
    } else {
      setTimeout(() => {
        navigate("/signin");
      }, 0);
    }
  };
  // delete student
  const action_DeleteFav = async (std_save_id) => {
    if (JSON.parse(localStorage.getItem("sparrowSignIn"))?.user) {
      if (user_role === "client") {
        const resp = await deleteStudent(std_save_id);
        const respSavedStudents = await ShowHomePageFeaturedStudents(user_id);
        setAllStudent(respSavedStudents?.data?.users);

        // checking student is removed or so
        if (resp.status === 200) {
        } else {
          Swal.fire({
            title: resp.data.message,
            timer: 1500,
            icon: "error",
            showConfirmButton: false,
          });
        }
      } else if (user_role === "student") {
        Swal.fire({
          title: "Please log in as a client",
          timer: 1500,
          icon: "error",
          showConfirmButton: false,
        });
      }
    } else {
      setTimeout(() => {
        navigate("/signin");
      }, 0);
    }
  };

  // console.log(studentData?.totalOrders);
  return (
    <div className="profileBox border-mb-fields">
      <div className="profileContainer">
        <div className="profileImg">
          <img className="profileImgM imgBorder" src={imageUrl} alt="" />
          {studentData?.totalOrders > 50 ? (
            <div className="hoverTextDiv">
              <img
                className="badgeStdnt"
                src="/assets/images/badges/goldA.svg"
                alt=""
              />
              <span className="hoverText setResonive">
                This student has done over 100 tasks
              </span>
            </div>
          ) : studentData?.totalOrders > 25 &&
            studentData?.totalOrders <= 50 ? (
            <div className="hoverTextDiv">
              <img
                className="badgeStdnt"
                src="/assets/images/badges/silverb.svg"
                alt=""
              />
              <span className="hoverText setResonive">
                This student has done 25-50 tasks
              </span>
            </div>
          ) : (
            <div className="hoverTextDiv">
              <img
                className="badgeStdnt"
                src="/assets/images/badges/bronzec.svg"
                alt=""
              />
              <span className="hoverText setResonive">
                This student has done 0-25 tasks
              </span>
            </div>
          )}
          {Number.isInteger(studentData?.saved_student_id) ? (
            <div className={`hoverTextDiv ${none}`}>
              <img
                className="romaalimg"
                src="/assets/images/home/romaalG.svg"
                alt=""
                onClick={() => action_DeleteFav(studentData?.saved_student_id)}
              />
              <span className="hoverText setResonive">
                Remove from saved students
              </span>
            </div>
          ) : (
            <div className={`hoverTextDiv ${none}`} id={className}>
              <img
                src="/assets/images/customerDashboard/greynlabelicon.png"
                alt=""
                className="romaalimg"
                onClick={() => action_AddToFav(studentData?.id)}
              />
              <span className="hoverText setResonive">
                Add to favorite students
              </span>
            </div>
          )}
        </div>
        <div
          className="profilHed profileBoxStars"
          onClick={() => navigate(pathPage)}
        >
          <h3 className="profileBoxH3">
            {studentData ? (
              <>
                {studentData.first_name && studentData?.first_name}
                {studentData.last_name &&
                  " " + studentData?.last_name.charAt(0).toUpperCase()}
                {studentDateOfBirth}
              </>
            ) : (
              ""
            )}
          </h3>
          <p className="profileBoxP elementCenter">
            {studentData?.student_details?.college || studentData?.college
              ? studentData?.student_details?.college || studentData?.college
              : "No college available"}
          </p>
          <div className="profileBoxStars">
            {studentData?.overall_rating || studentData?.rating?.length > 0 ? (
              <ReactStars
                count={5}
                size={25}
                edit={false}
                color2={"#FFC700"}
                color1={"#FFC70040"}
                className="pe-none d-flex justify-content-center"
                value={
                  studentData?.overall_rating || studentData?.rating?.length > 0
                    ? studentData?.overall_rating ||
                      studentData?.rating[0]?.overall_rating
                    : 5
                }
              />
            ) : (
              <div className="marginNeg">
                {/* <img
                  style={{ marginBottom: "6px" }}
                  src="/assets/images/customerDashboard/Star 8.svg"
                  // 3rc="/assets/images/customerDashboard/dullStarForAdvSearch.svg"
                  alt=""
                /> */}
                <span className="newStd">New student</span>
              </div>
            )}
          </div>
          <div className="houlyRateValueNew profileBoxStars">
            Avg. ${studentMinumunHourlyRate}/hr
          </div>
        </div>
        <div className="profileContent" onClick={() => navigate(pathPage)}>
          <h4 className="profileBoxSubhed">Featured Tasks</h4>
          <div className="profileTaks flex-wrap">
            {studentData?.skills || studentData?.student_details?.skills ? (
              resultForPrimaryTask
            ) : (
              <>
                <span>None</span>
              </>
            )}
          </div>
        </div>
        <div className="profileAbout" onClick={() => navigate(pathPage)}>
          <h4 className="profileBoxSubhed">About me</h4>
          <p>
            {
              <span className="overflowText line-clamp">
                {studentData?.student_details?.about || studentData?.about
                  ? studentData?.student_details?.about || studentData?.about
                  : "Not available"}
              </span>
            }
          </p>
        </div>
      </div>
      {user_role != "student" ? (
        <div className="profileBtn">
          <Link
            to={
              JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.role ===
              "client"
                ? `/dashboardRoute/all-students/create-tasks/${studentData?.id}/${studentData?.fcm_token}`
                : JSON.parse(localStorage.getItem("sparrowSignIn"))?.user
                    ?.role === "student"
                ? "/dashboardRoute/student-profile/"
                : "/signin"
            }
            className="primary bookNowBtn"
          >
            {invite}
            {bookNow}
          </Link>
        </div>
      ) : null}
    </div>
  );
}
