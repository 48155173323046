import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Moment from 'react-moment';

const StudentReview = (props) => {

    // props.reviewStudent
    return (
        <div>
            <Modal
                size="lg"
                show={props.modalValue}
                onHide={props.onClose}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title className='ml-5' id="example-modal-sizes-title-lg">
                        Student <span className='green'>Reviews</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='studentDetailModalBody'>
                    <div>
                        {props?.reviewStudent?.map((singleReview) => {
                            return (
                                <div className='ratingComment'>
                                    <div className='ratingCommentHed'>
                                        <div><img className='commentImg'
                                            src={singleReview?.client?.image}
                                            alt="" /></div>
                                        <div>
                                            <p className='aboutMainhed'>{singleReview?.client?.first_name && singleReview?.client?.last_name ? singleReview?.client?.first_name + " " + singleReview?.client?.last_name.charAt(0).toUpperCase() : ""}  </p>
                                            <div className='setComentHed d-flex modalViewStudent'>
                                                <p className='aboutMainCC'>{singleReview?.task?.title}</p>
                                                <p className='setCommntContent aboutContent mb-aboutsec'>
                                                    <span>
                                                        <img src="/assets/images/customerDashboard/Star 8.svg" alt="" />
                                                    </span>
                                                    <span className="green underline2" >
                                                    {parseFloat((singleReview?.feedback?.responsibility + singleReview?.feedback?.performance + singleReview?.feedback?.communication + singleReview?.feedback?.attitude) / 4).toFixed(1)}
                                                    </span>

                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <p className='aboutContent'>{singleReview?.feedback?.comment ? singleReview?.feedback?.comment : ""}</p>
                                    <p className='publishP'>Published <Moment fromNow>{singleReview?.feedback?.created_at}</Moment></p>
                                </div>
                            )
                        })}
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onClose}> Close </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default StudentReview