import moment from "moment";
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { getClientTaskList } from "../../services/order/ClientTaskList";
import Loader from "../loader/Loader";
import { handleImageError } from "../../utils/constants";

const CustomerTaskHistoryNew = ({}) => {
  const navigate = useNavigate();

  const [loder, setLoder] = useState(true);
  const client_id = parseInt(JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.id);
  const [orderList, setOrderList] = useState([]);
  // sending total tasks to parent
  // setTotaltasks(orderList?.length);

  // getting orders list
  const getOrderListFunc = async () => {
    // debugger
    const response = await getClientTaskList(client_id);
    if (response.status === 200) {
      setOrderList(response?.data?.order);
      setLoder(false);
    } else {
      Swal.fire({
        title: response.data.message,
        timer: 1500,
        icon: "error",
        showConfirmButton: false,
      });

      setLoder(false);
    }
  };
  useEffect(() => {
    getOrderListFunc();
  }, []);

  // react datatable
  // const currentDate = new Date();

  const columns = [
    {
      name: "Students",
      button: true,
      cell: (row) => (
        <>
          {row?.invited_students?.length != 0 && row?.status == "pending" ? (
            <div className="studentHed specialClss row w-100" onClick={() => onRowClicked(row)}>
              <div className="col-7 col-sm-6 px-0" style={{ textAlign: "right" }}>
                {row?.invited_students.slice(0, 3).map((item, index) => (
                  <img
                    key={index}
                    style={{ border: "2px solid #82d051" }}
                    className="profileImgM1"
                    src={item?.student?.image}
                    alt=""
                    referrerpolicy="no-referrer"
                  />
                ))}
              </div>
              <div className="col-5 col-sm-6 px-0">
                <p>
                  {/* {row?.invited_students.length === 1
                    ? row?.invited_students[0]?.student?.first_name
                        ?.charAt(0)
                        .toUpperCase() +
                      row?.invited_students[0]?.student?.first_name?.slice(1)
                    : `${row?.invited_students[0]?.student?.first_name} and ${
                        row?.invited_students.length - 1
                      } other(s)`} */}
                  {row?.invited_students.length === 1
                    ? row?.invited_students[0]?.student?.first_name?.charAt(0).toUpperCase() + row?.invited_students[0]?.student?.first_name?.slice(1)
                    : row?.invited_students.length + " Students Invited"}
                </p>
                <p className="taskNameForHistoryTable d-none">{row?.title}</p>
              </div>
            </div>
          ) : row?.order != null && row?.invited_students?.length != 0 ? (
            <div className="studentHed specialClss row w-100" onClick={() => onRowClicked(row)}>
              <div className="col-7 col-sm-6 px-0" style={{ textAlign: "right" }}>
                <img
                  key={row?.order?.id}
                  style={{ border: "2px solid #82d051" }}
                  className="profileImgM1"
                  src={row?.order?.student?.image}
                  alt=""
                  referrerpolicy="no-referrer"
                />
              </div>
              <div className="col-5 col-sm-6 px-0">
                <p>{row?.order?.student?.first_name}</p>
                <p className="taskNameForHistoryTable d-none">{row?.title}</p>
              </div>
            </div>
          ) : (
            <div className="studentHed specialClss w-100" onClick={() => onRowClicked(row)}>
              <div className="col-12 col-sm-12 text-center px-0">
                <p>Pending Invites</p>
                <p className="taskNameForHistoryTable d-none">{row?.title}</p>
              </div>
            </div>
          )}
        </>
      ),
      width: "230px",
    },
    {
      name: "Task",
      selector: (row) => row?.title,
      hide: "sm",
    },
    {
      name: "Hourly pay",
      selector: (row) => "$" + row?.hourly_rate,
      hide: "sm",
    },
    {
      name: "Start date",
      selector: (row) => moment(row?.start_date).format("L"),
      hide: "sm",
    },
    {
      name: "End date",
      // width: '200px'
      cell: (row) => <div className="studentHed">{row?.end_date ? moment(row?.end_date).format("L") : ""}</div>,
      hide: "sm",
    },
    {
      name: "Status",
      button: true,
      cell: (row) => (
        <div className="statusForTaskHistory">
          {/* {adding onClick={() => onRowClicked(row)} fucntion here just to add click functionality works also for buttons} */}
          {row?.status === "pending" ? (
            <button onClick={() => onRowClicked(row)} className="tableBtn orange">
              Pending
            </button>
          ) : row?.status === "in progress" || row?.status === "unpaid" ? (
            <>
              {/* {row?.task?.end_date ? (
                  currentDate >= moment(row?.task?.end_date) ? (
                    <button
                      onClick={() => onRowClicked(row, "review")}
                      className="tableBtn colorActive"
                    >
                      Review
                    </button>
                  ) : ( */}
              <button onClick={() => onRowClicked(row)} className="tableBtn colorActive">
                Active
              </button>
              {/* )
                ) : (
                  ""
                )} */}
            </>
          ) : row?.status === "closed" ? (
            <button onClick={() => onRowClicked(row)} className="tableBtn">
              Completed
            </button>
          ) : row?.status === "canceled" ? (
            <button onClick={() => onRowClicked(row)} className="tableBtn activeRed">
              Cancelled
            </button>
          ) : row?.status === "review" ? (
            <button onClick={() => onRowClicked(row, "review")} className="tableBtn colorActive">
              Review
            </button>
          ) : (
            ""
          )}
          <p className="formobileStatusTaskHistory d-none">
            {moment(row?.start_date).format("DD MMM")}
            {row?.end_date && " - " + moment(row?.end_date).format("DD MMM")}
          </p>
        </div>
      ),
      width: "150px",
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontSize: "18px",
        fontWeight: "600",
        color: "#000000",
        fontFamily: "Manrope",
        backgroundColor: "white",
        borderBottom: "1px solid #EDEDED",
        paddingTop: "19px !important",
        paddingBottom: "19px !important",
      },
    },
    cells: {
      style: {
        color: "#000000",
        fontSize: "15px",
        fontWeight: "500",
        fontFamily: "Poppins",
        backgroundColor: "#fff",
        paddingTop: "12px !important",
        paddingBottom: "12px !important",
      },
    },
    headRow: {
      style: {
        borderRadius: "8px",
        color: "#FFFFFF",
        fontSize: "14px",
        fontWeight: "400",
        border: "none !important",
      },
    },
  };

  const onRowClicked = (task) => {
    // sending task id
    // debugger
    if (task?.status == "review") {
      navigate(`/dashboardRoute/feedback/${task.id}`);
    }
    // else if (task?.status == "pending") {
    //   navigate(
    //     `/dashboardRoute/customer-notification/order-draft-details/${task.id}`
    //   );
    // }
    else if (task?.status == "in progress" || task?.status == "closed") {
      navigate(`/dashboardRoute/customer-notification/order-details/${task?.order?.id}`);
    } else {
      navigate(`/dashboardRoute/customer-notification/task-details/${task?.id}?orderId=${task?.order_draft?.id}`);
    }
  };

  // console.log(orderList);

  return (
    <div className="orderDataTable">
      {loder ? (
        <div className="height100vh height10vh">
          <Loader />
        </div>
      ) : (
        <DataTable
          title="My Tasks"
          customStyles={customStyles}
          columns={columns}
          data={orderList}
          onRowClicked={onRowClicked}
          className="customerProfileTaskHistory"
        />
        // <div>
        //   <h1>hi</h1>
        // </div>
      )}
    </div>
  );
};

export default CustomerTaskHistoryNew;
