import React, { Component } from 'react'

export default function HappyUsers (props) {

        return (
            <div className='usrBox'>
                <div className='usrBoxContainer'>
                    <div><img className='mt-10' src="/assets/images/home/Group 8795.svg" alt="" /></div>           
                    <div className='usrStars'>
                        <img src="/assets/images/home/Star 5.svg" alt="" />
                        <img src="/assets/images/home/Star 5.svg" alt="" />
                        <img src="/assets/images/home/Star 5.svg" alt="" />
                        <img src="/assets/images/home/Star 5.svg" alt="" />
                        <img src="/assets/images/home/Star 5.svg" alt="" />
                    </div>
                    <p style={{height:'132px'}}>{props.singletestimonial.body}</p>
                </div>
                <div className='usrBoxBottom'>
                    <div>
                        <img src={`${props.singletestimonial.imageSec}`} alt="" />
                    </div>
                    <div className='usrBoxBottomCotent'>
                        <h5 className='usrBoxH'>{props.singletestimonial.name}</h5>
                        <p>{props.singletestimonial.taskName}</p>
                    </div>
                </div>
            </div>
        )
    
}
