import React from "react";
import { Container } from "react-bootstrap";

const PrivacyPolicy = () => {
  return (
    <div className="termsPage mt140">
      <Container>
        <h2 className="text-center">Sparrow Privacy Policy</h2>
        <br />
        <p>Last Updated: June 4, 2024</p>
        <br />
        <h3>1. Introduction</h3>
        <div className="ml-1 mb-2">
          <p>
            This Privacy Policy (“Privacy Policy”) describes how Findsparrow, Inc. and its affiliates will gather, use, and maintain your Personal
            Information on the Sparrow Platform. It will also explain your legal rights with respect to that information.
          </p>
          <br />
          <p>
            {" "}
            By using the Sparrow Platform, you confirm that you have read and understand this Privacy Policy, and our Global Terms of Service
            (together referred to herein as the “Agreement”). The Agreement governs the use of the Sparrow Platform. Sparrow will collect, use, and
            maintain information consistent with the Agreement.
          </p>
        </div>
        <h3>2. General Terms</h3>
        <div className="ml-1 mb-2">
          <p>In this Privacy Policy:</p>
          <br />
          <ul>
            <li>
              Findsparrow, Inc. may be referred to as <b>"Sparrow," “we,” “our,”or “us.”</b>
            </li>
            <li>
              We call a user or client of the Sparrow Platform <b>“User,” “Users,” “you,” “your,” or “Client(s)” and “Student(s)”</b> as appropriate.
            </li>
            <li>
              <b>Students</b> are categorized as school students attending at least 12 units or more at any school or university.
            </li>
            <li>
              The community platform that we offer at our website (findsparrow.com) is referred to as the <b>“Site(s).”</b>
            </li>
            <li>
              Sparrow’s Site and related services, information and communications are collectively referred to as the <b>“Spaarrow Platform."</b>
            </li>
            <li>
              <b>“Terms of Service”</b> refers to our Terms of Service, which can be found here. This Privacy Policy is incorporated into, and
              considered a part of, the Terms of Service.
            </li>
          </ul>
        </div>
        <h3>3. Information Collection</h3>
        <div className="ml-1 mb-2">
          <p>Information you provide to Sparrow</p>
          <p>
            Sparrow collects certain personally identifiable information about you, including information that can identify, relate to, describe, be
            associated with you, or is reasonably capable of being associated with you <b>(“Personal Information”)</b>. Examples of Personal
            Information that Sparrow collects include but are not limited to:
          </p>
          <br />
          <p>
            <b>Contact Information</b>. This may include your first and last name, postal address, telephone number, and email address.
          </p>
          <br />
          <p>
            <b>Billing Data</b>, including your payment instrument number (such as a credit or debit card number), expiration date, and security code
            as necessary to process your payments.
          </p>
          <br />
          <p>
            <b>Identity Information</b>. If you are a Student, we may collect Personal Information, such as your date of birth and address, national
            identification number if applicable, together with the result of basic criminal record checks as provided by you, or by our Third-Party
            Agents (as defined below), as applicable and to the extent permitted by law in your jurisdiction, as well as such information as may be
            needed to validate your identity.
          </p>
          <br />
          <p>
            <b>Financial Information</b>. To help Students set up a payment account and help Clients make payments to Students and pay fees to
            Sparrow, we may collect financial information, including credit card number, bank routing numbers, tax information, taxpayer
            identification number, and other payment information, as applicable. We use Financial Information in order to operate the Sparrow Platform
            and to ensure that Students are paid by Clients. We do this as necessary for our legitimate interests in providing our platform and
            services and to fulfill our contracts with Users.
          </p>
          <br />
          <p>
            <b>Promotional Information</b>. Certain offerings of the Sparrow Platform, such as newsletters, surveys, contests, and the like are
            optional and so you are not required to enter them or to give us your data in connection with them. Where you do consent to take advantage
            of Sparrow Platform offerings, we will use your data to (as applicable) send you newsletters and other communications that are tailored
            based on information we have about you, or to operate and manage the survey, contest or similar offering in connection with our legitimate
            interest in promoting our business and the Sparrow Platform.
          </p>
          <br />
          <p>
            <b>Employment Information</b>. We collect employment and education history, transcripts, writing samples, and references as necessary to
            consider your job application for open positions.
          </p>
          <br />
          <p>
            <b>Content Information</b>. You also may choose to send Sparrow Personal Information in an email or chat message containing inquiries
            about the Sparrow Platform and we use this Information in order to help us respond to your inquiry. We also collect content within any
            messages you exchange with other Users through the Sparrow Platform (such as through our chat functionality).
          </p>
          <br />
          <p>
            We require that you furnish certain information when you register an account with us in order to provide services through the Sparrow
            Platform. For example, if you are a Client, we collect your first and last name, email address, and your zip or postal code in order to
            create and administer your Sparrow account. We also collect additional information in order to facilitate your booking request, such as
            information about the task you are seeking, the time, date and location of the task, and Billing Data. If you are a Student, we collect
            your first and last name, email address, mobile phone number and zip or postal code in order to create and administer your Sparrow account
            and facilitate communications between you and your Clients through the Sparrow Platform. We also collect information about your tasks,
            rates, and skills, as well as Identity Information and Financial Information.
          </p>
          <br />
          <p>
            <b>Third Party Information</b>. Financial Information, or fraud detection information, from Third-Party Agents (as defined below) and
            combine it with other information that we have about you, to the extent permitted by law, in order to comply with our legal obligations
            and for the legitimate interest in improving the Sparrow Platform. Sparrow may work with Third-Party Agents to perform identity checks and
            criminal background checks on Students, if applicable and permitted by local law, in order to advance our legitimate interests in ensuring
            the safety of our Users/Families and maintaining the integrity of the Sparrow Platform.
          </p>
        </div>
        <h3>4. Sparrow's Use of Information</h3>
        <div className="ml-1 mb-2">
          <p>
            We collect and use information for business and commercial purposes in accordance with the practices described in this Privacy Policy. Our
            business purposes for collecting and using information include:
          </p>
          <ul>
            <li>
              To operate and make available the Sparrow Platform. We use your data to connect you with other Users/Families to enable the posting of,
              selection for, completion of, and payment for Tasks, in order to fulfill our contracts with Users;
            </li>
            <li>
              For billing and fraud prevention, on the basis of our legitimate interests in ensuring a safe and secure environment in which Clients
              and Students can meet and conduct business, and in order to comply with our legal obligations;
            </li>
            <li>
              To conduct identification and criminal background checks, if applicable and to the extent permitted by local law, on Users, in order to
              advance our legitimate interests as well as for the substantial public interest of ensuring the safety of our Users both online and
              offline, preventing or detecting unlawful acts, protecting the public against dishonesty, and maintaining the integrity of the Sparrow
              Platform given that Students often interact directly with Clients and may enter their homes;
            </li>
            <li>
              To analyze Sparrow Platform usage as necessary for our legitimate interest in improving the Sparrow Platform to grow our business;
            </li>
            <li>
              To contact you and deliver (via email, SMS, push notifications, or otherwise) transactional information, administrative notices,
              marketing notifications, offers and communications relevant to your use of the Sparrow Platform, with your consent when required under
              applicable law, as necessary for our legitimate interests in proper communication and engagement with our Users, and in promoting our
              business;
            </li>
            <li>To provide you with customer support in order to fulfill our contracts with Users;</li>
            <li>For internal market research for our legitimate interest in improving the Sparrow Platform to grow our business;</li>
            <li>For troubleshooting problems for our legitimate interests in ensuring a safe and secure environment in which Users can meet;</li>
            <li>
              To assist Users in the resolution of complaints and disputes between them, as necessary for our legitimate interests in facilitating
              good relations among Users;
            </li>
            <li>To enforce our Terms of Service and our legitimate interests in ensuring our Agreement is complied with; and</li>
            <li>As otherwise set forth in the Agreement.</li>
          </ul>
          <br />
          <p>
            <b>Interest-Based Advertising</b>. Ads are a standard part of user experience on the Internet, and Sparrow believes that targeted
            advertising enhances this experience. Sparrow and affiliate third parties may use cookies and other technologies to place ads where they
            believe interested Users will see them. In addition to banner ads, Sparrow may advertise products, companies and events that we think
            might interest you through the email address and/or phone number you provide. We may incorporate Tracking Technologies on the Sparrow
            Platform (including our website and emails) as well as into our ads displayed on other websites and services. Some of these Tracking
            Technologies may track your activities across time and services for purposes of associating the different devices you use, and delivering
            relevant ads and/or other content to you (<b>“Interest-Based Advertising”</b>).
          </p>
          <br />
          <p>
            For more information and to understand your choices regarding third-party ads, please see our Cookie Policy. Advertising and marketing is
            carried out as necessary for our legitimate interests in providing an engaging and relevant experience, promoting our services, and
            growing our business.
          </p>
          <p>
            <b>Analytics and Market Analysis</b>. Sparrow may analyze information (<b>“Market Analysis”</b>) as necessary for our legitimate interests
            in providing an engaging and relevant experience, and promoting and growing the Sparrow Platform.
          </p>
          <br />
          <p>
            Sparrow uses information to offer services to Users who express an interest in these services, through a poll for example, or to Users who
            can be presumed to have an interest based on results from our Market Analysis. We do this as necessary for our legitimate interests in
            providing an engaging and relevant experience, promoting our services, and growing our business.
          </p>
          <br />
          <p>
            <b>Cell Phone Numbers</b>. Sparrow may use your cell phone number to call or send recurring text messages to you, using an autodialer or
            prerecorded voice, in order to provide you notifications about Tasks, for marketing purposes (with your consent where required by law),
            and to administer the Sparrow Platform. If you would like more information about our policy, or how to opt out, please review the “Your
            Rights and Choices” section below or Section 9 of our Terms of Service You may be liable for standard SMS and per-minute charges by your
            mobile carrier. Sparrow may also message you via push notifications (with your consent when required under applicable law), which you can
            opt-out of on your mobile device. Data rates may apply.
          </p>
          <br />
          <p>
            <b>Call recordings</b>. Calls to or from Sparrow or its Third Party Agents may be monitored and recorded for the purposes of quality
            control and training. Such calls may contain Personal Information.
          </p>
        </div>
        <h3>5. Information Sharing</h3>
        <div className="ml-1 mb-2">
          <p>
            We only share the Information we collect about you as described in this Privacy Policy or as described at the time of collection or
            sharing, including as follows:
          </p>
          <br />
          <p>
            <b>Third Party Agents</b>. We share information, including Identity Information, with entities that process information on our behalf for
            our business purposes. We contractually prohibit our Third-Party Agents from retaining, using, or disclosing information about you for any
            purposes other than performing the services for us, although we may permit them to use information that does not identify you (including
            information that has been aggregated or de-identified) for any purpose except as prohibited by applicable law.
          </p>
          <br />
          <p>
            To operate the Sparrow Platform, including processing your transactions, we may share your Personal Information with our agents,
            representatives, vendors and service providers (<b>“Third Party Agents”</b>) so they can provide us with support services as follows:
          </p>
          <ul>
            <li>Email origination;</li>
            <li>
              Identity checks (currently carried out by our providers IDology (U.S.), and criminal background checks (currently carried out by our
              provider Sterling in the U.S. and Canada), to the extent permitted by applicable law;
            </li>
            <li>
              Fraud prevention and detection (currently carried out by our providers Sift Science and Emailage). Our fraud detection providers also
              use information relating to you and your use of and activity on the Sparrow Platform to provide fraud detection services to their other
              clients;
            </li>
            <li>Receipt, invoice, or support services;</li>
            <li>Customer relationship management services;</li>
            <li>Data analytics;</li>
            <li>Marketing and advertising;</li>
            <li>Website hosting;</li>
            <li>Communications services;</li>
            <li>Technical support;</li>
            <li>
              Financial transaction fulfillment (in which we are currently supported by Stripe, who processes your Personal Information in accordance
              with its own privacy policy) and related chargeback and collection services; and
            </li>
            <li>To otherwise help us provide the Sparrow Platform.</li>
          </ul>
          <br />
          <p>
            <b>Partners</b>. Some Sparrow content is "sponsored by" or "presented by" other companies. If you connect to the Sparrow Platform through
            a co-branded version of our Sparrow Platform or otherwise participate in one of our partner programs, we may share information about your
            use of the Sparrow Platform with that Partner in order to offer the integrated platform and to evaluate the partner program. We may also
            share your Personal Information with our Partners in order to receive additional information about you, or in order to create offers that
            may be of interest to you. Please check such Partner’s privacy policy before revealing information about yourself. If you don't want these
            Partners to have your Personal Information, you can choose to not participate.
          </p>
          <br />
          <p>
            <b>Promotions</b>. When you voluntarily enter a sweepstakes, contest, or other promotion, we share information as set out in the official
            rules that govern the promotion as well as for administrative purposes and as required by law (e.g., on a winners’ list). By entering a
            promotion, you agree to the official rules that govern that promotion, and may, except where prohibited by applicable law, allow the
            sponsor and/or other entities to use your name, voice, and/or likeness in advertising or marketing materials. We may occasionally contact
            you, if you want us to, with information about special events, activities, promotions, contests, submission opportunities, and programs.
            You always have the ability, in your Account, to ask Sparrow not to contact you with this type of information.
          </p>
          <br />
          <p>
            <b>Matched Ads</b>. We use Matched Ads by sharing personal information with another party or incorporating a pixel from another party into
            our own Sites, and the other party matching common factors between our data and their data or other datasets for advertising purposes. For
            instance, we incorporate the Facebook pixel on our Sites and may share your email address with Facebook as part of our use of Facebook
            Custom Audiences.
          </p>
          <br />
          <p>
            <b>Other Users</b>. Sparrow facilitates contact between Clients and Sudents and reserves the right to share one User’s contact information
            (e.g., name, phone number, email, or postal address) with other Users, or with the recipient User’s legal or other authorized
            representative, in order to:
            <br />
            <ol type="1">
              <li>
                facilitate or communicate the resolution of an investigation or dispute related to or arising from an interaction between two or more
                Users of the Sparrow Platform; or
              </li>
              <li>facilitate the performance of a Task. This exchange of information is essential to the operation of the Sparrow Platform.</li>
            </ol>
          </p>
          <br />
          <p>
            <b>Legal Obligations</b>. Sparrow and our Third Party Agents may disclose your Personal Information or User Generated Content to courts,
            law enforcement, governmental or public authorities, tax authorities or authorised third parties, if and to the extent required or
            permitted to do so by law or where disclosure is reasonably necessary to:
            <br />
            <ol type="i">
              <li>comply with Spaarrow’s legal or regulatory obligations;</li>
              <li>respond to a court order, warrant or subpoena;</li>
              <li>respond to a valid legal request relating to an investigation into alleged criminal or illegal activity; or </li>
              <li> respond to or address any other activity that may expose us to legal or regulatory liability. </li>
            </ol>
          </p>
          <br />
          <p>
            In jurisdictions where Sparrow is, according to applicable law, required to collect or remit information about Users’ permits, licences,
            tax, or social security registrations, Sparrow and our Third Party Agents may disclose information, including but not limited to, User
            contact details, identification information, transaction dates and amounts, license and permit status, and tax identification number(s) to
            applicable governmental authorities.
          </p>
          <br />
          <p>
            Sparrow reserves the right to disclose Personal Information from both private and public areas of the Sparrow Platform in the absence of a
            court order, warrant, or subpoena, to the extent permitted by applicable law, if we are given reason to believe, in our sole discretion,
            that someone is causing injury to or interfering with the rights of Users, the general public, or Sparrow or its partners, parents or
            affiliates.
          </p>
          <p>
            It is our policy to provide notice to Users before producing their information in response to law enforcement requests unless
            <br />
            <ol type="i">
              <li>we are prohibited or otherwise constrained by law or court order from doing so,</li>
              <li>
                we have reason to believe the User’s account has been compromised such that the notice would go to the wrong person, or notice would
                otherwise be counterproductive or would create a risk to safety, or{" "}
              </li>
              <li>it is an emergency request and prior notice would be impractical (in which case we may provide notice after the fact).</li>
            </ol>
          </p>
          <br />
          <p>
            <b>Merger or Acquisition</b>. Sparrow reserves the right to share information in connection with, or during negotiations of, any proposed
            or actual merger, purchase, sale, or any other type of acquisition or business combination of all or any portion of our assets, or
            transfer of all or a portion of our business to another business.
          </p>
          <br />
          <p>
            <strong>Public Areas</strong>. Your profile on the Sparrow Platform consists of information about you and your business, and may include
            information such as photographs, your years in business, skills and expertise, hourly pay rates, feedback/rating information, and other
            information, including your username (<strong>“Profile”</strong>). The information in your User Profile may be visible to all Users and
            the general public. If you choose to post a Task via the Sparrow Platform, the contents of such listing will be viewable to Students you
            select on the Sparrow Platform.
          </p>
        </div>
        <h3>6. Sparrow's Information Retention Policy</h3>
        <div className="ml-1 mb-2">
          <p>
            We retain personal data for as long as you are a User in order to meet our contractual obligations to you, and for such longer period as
            may be in our legitimate interests and to comply with our legal obligations (see Exhibit A for exemplar document retention periods). We
            may also retain data from which you cannot directly be identified, for example where stored against a randomly-generated identifier so we
            know the data relates to a single User, but we cannot tell who that User is. We use this kind of data for research purposes and to help us
            develop and improve our services, and we take appropriate measures to ensure you cannot be re-identified from this data.
          </p>
        </div>
        <h3>7. Spaarrow’s Security of Collected Information</h3>
        <div className="ml-1 mb-2">
          <p>
            Your Sparrow account is password-protected so that only you and authorized Sparrow staff have access to your account information. In order
            to maintain this protection, do not give your password to anyone. Also, if you share a computer, you should sign out of your Sparrow
            account and close the browser window before someone else logs on. This will help protect your information entered on public terminals from
            disclosure to third parties.
          </p>
          <br />
          <p>
            Sparrow implements and maintains reasonable administrative, physical, and technical security safeguards to help protect information about
            you from loss, theft, misuse and unauthorized access, disclosure, alteration and destruction. Nevertheless, transmission via the internet
            is not completely secure and we cannot guarantee the security of information about you.
          </p>
        </div>
        <h3>8. Children’s Privacy</h3>
        <div className="ml-1 mb-2">
          <p>
            This service is intended for a general audience, and is not directed at children under 15 years of age. In certain jurisdictions, this
            minimum age may be higher. Please check the Jurisdiction-specific Provisions below for more information.
          </p>
          <br />
          <p>
            We do not knowingly gather personal information (as defined by the U.S. Children’s Privacy Protection Act, or “COPPA”) in a manner not
            permitted by COPPA. If you are a parent or guardian and you believe that we have collected information from your child in a manner not
            permitted by law, please let us know by contacting us. We will remove the data.
          </p>
          <br />
          <p>We do not knowingly collect or “sell” the Personal Information of minors under 18 years old who are California residents.</p>
        </div>
        <h3>9. Jurisdiction-specific Provisions</h3>
        <div className="ml-1 mb-2">
          <p>A. Residents of the United States of America</p>
          <br />
          <p>
            <strong>Information of U.S. Users</strong>. Our collection, use, and retention of the Personal Information of U.S. Users is in accordance
            with applicable U.S. laws, as is our determination of what is deemed to be “personal data and/or information.”
          </p>
          <br />
          <p>
            Sparrow expressly disclaims any liability that may arise should any other individuals obtain the information you submit to the Sparrow
            Platform.
          </p>
          <br />
          <p>
            <strong>Interest-Based Advertising in the United States</strong>. For more information about interest-based ads, or to opt out of having
            your web-browsing information used for behavioral advertising purposes, please visit&nbsp;
            <a href="https://www.aboutads.info/choices" aria-describedby="a11y-external-message">
              www.aboutads.info/choices
            </a>
            .
          </p>
          <br />
          <p>
            <strong>Spaarrow’s Security of Collected Information</strong>. While Sparrow will use commercially reasonable efforts to ensure the
            security of all Personal Information, we expressly disclaim any liability for any unauthorized access to or use of our secure servers
            and/or any and all Personal Information stored therein, and you agree to hold Sparrow harmless for any damages that may result therefrom.
          </p>
          <br />
          <p>Additional Disclosures for California Residents</p>
          <br />
          <p>
            These additional disclosures for California residents apply only to individuals who reside in California. The California Consumer Privacy
            Act of 2018 (<strong>“CCPA”</strong>) provides additional rights to know, delete and opt out, and requires business collecting or
            disclosing Personal Information to provide notice of rights California residents have and can exercise.
          </p>
          <br />
          <p>
            <strong>California Notice of Collection</strong>. We have collected Personal Information corresponding to the following categories
            enumerated in the CCPA, including within the last 12 months.
          </p>
          <br />
          <ul>
            <li>
              <em>Identifiers</em>, including name, address, email address, account name, Social Security Number, IP address - and an ID number
              assigned to your account.
            </li>
            <li>
              <em>Customer records</em>, phone number, billing address, credit or debit card information, employment or education information.
            </li>
            <li>
              <em>Demographic information</em>, such as your age or gender. This category includes pieces of Personal Information that also qualify as
              protected classification characteristics under other pre-existing California or federal laws.
            </li>
            <li>
              <em>Analytics and Advertising</em>, including purchases and engagement with the Sparrow Platform.
            </li>
            <li>
              <em>Internet activity</em>, including history of visiting and interacting with our Service, browser type, browser language and other
              information collected automatically.
            </li>
            <li>
              <em>Geolocation data</em>, including location enabled services such as WiFi and GPS.
            </li>
            <li>
              <em>Inferences</em>, including information about your interests and preferences.
            </li>
          </ul>
        </div>
      </Container>
    </div>
  );
};

export default PrivacyPolicy;
