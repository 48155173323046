import { Col, Container, Row } from 'react-bootstrap'
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import { Pagination } from "swiper";
import { Link } from 'react-router-dom';

const FreelanceTaskResponsive = () => {
    return (
        <div className='HomeTasksResponsive freelancePage'>
            <div className='homeUserSlidrResponsive'>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={0}
                    // loop={true}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <div className='taskBox'>

                            <div className='boxImg'><img src="/assets/images/studentfreelance/Housekeeping.svg" alt="" /></div>
                            <div className='taskBoxContent'>
                                <h4 className='green'>Housekeeping</h4>
                                <p>Help with dishes, vacuuming, window cleaning and other errands in the house. </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='taskBox'>

                            <div className='boxImg'><img src="/assets/images/studentfreelance/Tutoring.svg" alt="" /></div>
                            <div className='taskBoxContent'>
                                <h4 className='green'>Tutoring</h4>
                                <p>Tutor children in any subject to help improve their grades in the classroom.</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='taskBox'>

                            <div className='boxImg'><img src="/assets/images/studentfreelance/Pet Sitting.svg" alt="" /></div>
                            <div className='taskBoxContent'>
                                <h4 className='green'>Pet Sitting</h4>
                                <p>Get paid to play around with pets or take them for a long walk in your neighbourhood. </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='taskBox'>

                            <div className='boxImg'><img src="/assets/images/studentfreelance/Sports Coaching.svg" alt="" /></div>
                            <div className='taskBoxContent'>
                                <h4 className='green'>Sports Coaching</h4>
                                <p>Coach kids in your favorite school sport to advance and develop their athletic skills.</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='taskBox'>

                            <div className='boxImg'><img src="/assets/images/studentfreelance/Moving.svg" alt="" /></div>
                            <div className='taskBoxContent'>
                                <h4 className='green'>Moving</h4>
                                <p>Give families an extra hand carrying boxes and preparing for their new home.</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    {/*  */}
                    <SwiperSlide>
                        <div className='taskBox'>

                            <div className='boxImg'><img src="/assets/images/studentfreelance/Child Care.svg" alt="" /></div>
                            <div className='taskBoxContent'>
                                <h4 className='green'>Child Care</h4>
                                <p>Babysit and play with young children, while their parents are busy with other errands.</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='taskBox'>

                            <div className='boxImg'><img src="/assets/images/studentfreelance/Eldercare.svg" alt="" /></div>
                            <div className='taskBoxContent'>
                                <h4 className='green'>Eldercare</h4>
                                <p>Take care of the elderly by cherishing and helping them carry out their daily tasks.</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='taskBox'>

                            <div className='boxImg'><img src="/assets/images/studentfreelance/Yard Work.svg" alt="" /></div>
                            <div className='taskBoxContent'>
                                <h4 className='green'>Yard Work</h4>
                                <p>Earn money by mowing the lawn, remove dirt and water plants to clean the backyard. </p>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
            <div>
                <Link to={(JSON.parse(localStorage.getItem('sparrowSignIn')))?.user?.role === "client" ? "/dashboardRoute/all-students/" :
                    (JSON.parse(localStorage.getItem('sparrowSignIn')))?.user?.role === "student" ? "/dashboardRoute/student-profile/" :
                        "/signin"} className='primary viewAllbtn mt-22px'>Get Started</Link>
            </div>
        </div>
    )
}

export default FreelanceTaskResponsive