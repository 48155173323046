import React from 'react'
import LowerCircle from './LowerCircle'
import SignUpNav from './SignUpNav'

const SignUpLayout = ({ children }) => {
  return (
    <div className='h-100 d-flex flex-column justify-content-between signUpLayout_theme'>
      <SignUpNav />
      {children}
      <LowerCircle />
    </div>
  )
}

export default SignUpLayout