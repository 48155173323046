import React, { Children, useContext, useEffect, useState } from "react";
import Routes from "./Components/Routes";
import ReactDOM from "react-dom";
import Popup from "./popup_body/Popup";
import AuthContext from "./context/AuthContext";
import { getLocations } from "./services/locations/Locations";
import { useCookies } from "react-cookie";
import { getAllNotifications } from "./services/notifications/GetNotifications";
import { lastLogin } from "./services/users/LastLogin";
import { HelmetProvider, Helmet } from "react-helmet-async";

const App = () => {
  const auth = useContext(AuthContext);

  let user_id = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.id;
  useEffect(() => {
    getFunc();
  }, []);

  //

  // // get all notifications

  const getFunc = async () => {
    // debugger
    if (JSON.parse(localStorage.getItem("sparrowSignIn"))) {
      const optionNotifications = [];
      const resp = await getAllNotifications(user_id);
      await lastLogin(user_id);
      resp.data?.notification?.map((single) => {
        return optionNotifications.push(single);
      });
      auth.addNotifications(optionNotifications);
    }
  };

  // console.log("app.js")
  // end
  const [cookies] = useCookies(["userSellData"]);
  const [isOpen, setIsOpen] = useState(true);

  const togglePopup = () => {
    setIsOpen(!isOpen);
    document.body.style.overflow = "unset";
  };

  // make a check to remove session storage for page search

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta
            name="description"
            content="Hire a trusted college student to carry out your daily tasks and errands."
          />
          <meta property="og:title" content="Sparrow" />
          <meta
            property="og:description"
            content="Hire a trusted college student to carry out your daily tasks and errands."
          />
          <meta property="og:url" content="https://www.findsparrow.com/" />
          <meta
            property="og:image"
            content="https://findsparrow-app-bucket.s3.us-west-1.amazonaws.com/images/bstI8p6YAK2HduKPF2xdAOJQg3sDVa67tV99O2Ek.png"
          />
          <meta property="og:type" content="website" />
        </Helmet>

        {isOpen &&
          !sessionStorage.getItem("popup_status") &&
          !cookies.userSellData &&
          ReactDOM.createPortal(
            <Popup content={Children} handleClose={togglePopup} />,
            document.getElementById("overlays")
          )}

        <Routes />
      </HelmetProvider>
    </>
  );
};

export default App;
