import { useState, useEffect, useMemo } from "react";
import Swal from "sweetalert2";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import {
  timeSlots,
  days,
} from "../../../services/availibility&timeslots/Availibility";
import { createAvailability } from "../../../services/availibility&timeslots/CreateAvailability";
import { DeleteUserAvailability } from "../../../services/availibility&timeslots/DeleteUserAvailability";
import Loader from "../../loader/Loader";

const StudentWorkAvalibityUpdate = (props) => {

  const [loder, setLoder] = useState(true);
  const [loader, setLoader] = useState(false);
  const [workdays, setworkdays] = useState();
  const [timeSlot, setTimeSlot] = useState();

  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getDaysFunc();
    getTimeFunc();
  }, []);

  // get working days
  const getDaysFunc = async () => {
    const response = await days();

    if (response.status === 200) {
      let order = {
        monday: 1,
        tuesday: 2,
        wednesday: 3,
        thursday: 4,
        friday: 5,
        saturday: 6,
        sunday: 7,
      };
      let daysArray = response.data;
      daysArray.sort(function (a, b) {
        return order[a.day] - order[b.day];
      });

      setworkdays(daysArray);
    } else {
      Swal.fire({
        title: response.data.message,
        timer: 1500,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };

  // get timing slots
  const getTimeFunc = async () => {
    const response1 = await timeSlots();

    if (response1.status === 200) {
      setTimeSlot(response1.data);
      setLoder(false);
    } else {
      Swal.fire({
        title: response1.data.message,
        timer: 1500,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };

  const optionTime = [];
  if (timeSlot?.length > 0) {
    timeSlot?.map((singleTime) => {
      return optionTime.push({
        label: singleTime?.slot,
        value: singleTime.id,
      });
    });
  }

  // post Api
  const [selectPlaceholder, setSelectPlaceholder] = useState("Select ...");

  const [start, setStart] = useState();
  const [startTimes, setStartTimes] = useState({});
  const [end, setEnd] = useState();
  const [endTimes, setEndTimes] = useState({});
  const [slotsUpdated, setSlotsUpdated] = useState(false);
  const [userSlots, setuserSlots] = useState(props.workAvaliblity);
  // functions
  const handleEndSelectChange = (selectedOption, itemID) => {
    setEndTimes((prevEndTimes) => ({
      ...prevEndTimes,
      [itemID]: selectedOption.value,
    }));

    if (selectedOption.value !== null) {
      makeArray(itemID, selectedOption.value);
    }
  };

  const makeArray = (dayID, end) => {
    setEnd(end);
    let copyUseState = [...userSlots];
    const start = startTimes[dayID];

    if (start && end && start !== end && start < end) {
      let toBeAdd = false;
      let countSameDaySlot = 0;

      copyUseState?.forEach((singleIndex) => {
        if (
          (singleIndex.start_time_slot_id === start ||
            singleIndex.end_time_slot_id === end) &&
          singleIndex.day_id === dayID
        ) {
          toBeAdd = true;
        }
        if (singleIndex.day_id === dayID) {
          countSameDaySlot++;
        }
      });

      if (countSameDaySlot >= 3) {
        toBeAdd = true;
      }

      if (!toBeAdd) {
        copyUseState.push({
          start_time_slot_id: start,
          end_time_slot_id: end,
          day_id: dayID,
          user_id: JSON.parse(localStorage.getItem("sparrowSignIn")).user.id,
        });

        setuserSlots(copyUseState);
        setStartTimes((prevStartTimes) => ({
          ...prevStartTimes,
          [dayID]: null,
        }));
        setEndTimes((prevEndTimes) => ({
          ...prevEndTimes,
          [dayID]: null,
        }));
        setSelectPlaceholder("Select ...");
        setStart(null);
        setEnd(null);
        setSlotsUpdated(true);
      }
    }
  };

  useEffect(() => {
    if (slotsUpdated) {
      setSlotsUpdated(false);
    }
  }, [slotsUpdated]);

  const sortedUserSlots = useMemo(() => {
    const sortedSlots = userSlots
      .sort((a, b) => {
        if (a.day_id !== b.day_id) {
          return a.day_id - b.day_id;
        } else {
          return a.start_time_slot_id - b.start_time_slot_id;
        }
      });

    return sortedSlots;
  }, [userSlots, slotsUpdated]);

  const searchtimeID = (nameKey, myArray) => {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].id == nameKey) {
        return myArray[i].slot;
      }
    }
  };

  const removeValueFromArray = (index_single, day_id) => {
    let copyUseState = [...userSlots];
    copyUseState.splice(index_single, 1);

    setuserSlots(copyUseState);
  };
  // on click of update
  const onSubmit = async (fData) => {
    setLoader(true);
    // debugger
    const data = {
      availabilities: userSlots,
    };

    if (props.workAvail) {
      const resp1 = await DeleteUserAvailability(
        JSON.parse(localStorage.getItem("sparrowSignIn")).user.id
      );
    }

    const resp = await createAvailability(userSlots);
    if (resp.status === 200) {
      Swal.fire({
        title: "Work details have been added!",
        timer: 1500,
        icon: "success",
        showConfirmButton: false,
      });

      setLoader(false);
    } else {
      Swal.fire({
        title: resp.data.message,
        timer: 3500,
        icon: "error",
        showConfirmButton: false,
      });
    }
    props.modalDone();
  };

  return (
    <div>
      <Modal
        size="lg"
        show="true"
        onHide={props.onClose}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Edit <span className="green">Details</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modalBodyTimeAvalible">
          <div>
            {loder ? (
              <div className="height100vh">
                <Loader />
              </div>
            ) : (
              <div className="setLower2">
                <form
                  id="updateStudentWorkAvalibityForm"
                  onSubmit={handleSubmit(onSubmit)}
                  className="SignupConfirmEmail stepForm mt-5"
                >
                  <div className="stepOne">
                    {workdays?.map((item) => {
                      return (
                        <div className="mb35 timeSlotD">
                          <div className="workPoint">
                            <div className="state">
                              <label>
                                {item?.day.charAt(0).toUpperCase() +
                                  item?.day.slice(1)}
                              </label>
                            </div>
                          </div>
                          {errors.dayId && (
                            <span className="eror">Select Day</span>
                          )}
                          <div className="mbl-mb-time">
                            <label
                              className="displaynone-mb-551"
                              htmlFor="appt"
                            >
                              Time of day:
                            </label>
                          </div>
                          <div className="workingTime justifyNone time-mb-select">
                            <div className="mb-time-zone px-2">
                              <label className="displaynone-mb" htmlFor="appt">
                                Select a time:
                              </label>
                              <Select
                                className="timingInput_select mb-time-1 d-inline-block"
                                options={optionTime}
                                placeholder={selectPlaceholder}
                                onChange={(selectedOption) => {
                                  // Update the start time for the specific workday
                                  setStartTimes((prevStartTimes) => ({
                                    ...prevStartTimes,
                                    [item.id]: selectedOption.value,
                                  }));
                                }}
                                value={startTimes[item.id] ? optionTime.find(option => option.value === startTimes[item.id]) : null}
                              />
                              <span className="d-inline-flex timeBetweenStartEnd px-2">
                                -
                              </span>
                              <Select
                                className="timingInput_select mb-time-1 d-inline-block"
                                options={optionTime}
                                placeholder={selectPlaceholder}
                                onBlur={() => {
                                  const selectedEndTime = endTimes[item.id];

                                  // Call makeArray with the selected end time only if it's not null
                                  if (selectedEndTime !== null) {
                                    makeArray(item?.id, selectedEndTime);
                                  }
                                }}
                                onChange={(selectedOption) => {
                                  // Call the separate function to handle the change
                                  handleEndSelectChange(selectedOption, item.id);
                                }}
                                value={endTimes[item.id] ? optionTime.find(option => option.value === endTimes[item.id]) : null}
                                isDisabled={!startTimes[item.id]} // Disable if start is null
                              />
                            </div>
                          </div>
                          <div className="timingBox w-100">
                            <>
                              {sortedUserSlots?.map((parentObjArray, index) => {
                                return (
                                  <>
                                    {parentObjArray?.day_id === item?.id ? (
                                      <>
                                        <div className="timeSlotPills">
                                          {searchtimeID(
                                            parentObjArray.start_time_slot_id,
                                            timeSlot
                                          )}{" "}
                                          -{" "}
                                          {searchtimeID(
                                            parentObjArray.end_time_slot_id,
                                            timeSlot
                                          )}{" "}
                                          <span
                                            style={{
                                              color: "white",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              removeValueFromArray(
                                                index,
                                                item?.id
                                              )
                                            }
                                          >
                                            &#x2715;
                                          </span>{" "}
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                );
                              })}
                            </>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </form>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onClose}>
            {" "}
            Close{" "}
          </Button>
          <Button
            form="updateStudentWorkAvalibityForm"
            className="btn btn-success gender-mb-1  gender-mb-1"
            type="submit"
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default StudentWorkAvalibityUpdate;
