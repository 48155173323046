import { useState, useEffect } from 'react';
import { showStudent } from '../../services/users/ShowSingleStudent';

export function useSingleUserInfo(id) {
    const [data, setData] = useState(null);

    const getFunc = async () => {
        const resp = await showStudent(id)
        if (resp.status === 200) {
            setData(resp.data.user)
            // console.log(resp.data.user)
        }
    }
    useEffect(() => {
        getFunc()
    }, [])

    return data;
}