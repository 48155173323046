import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

function AboutBanner() {
    return (
        <div className='aboutBanner logInClass pt-5'>
            <Container>
                <Row className='center'>
                    <Col md={6} className="aboutBannerMobileHeading">
                        <h2>Welcome to Sparrow!</h2>
                        <h6 className='seth6abou'>Our mission is to help all students obtain academic success by making work more flexible and accessible.</h6>
                    </Col>
                    <Col md={6}>
                        <div>
                            <img className='setResonive' src="/assets/images/about/Group 22680.png" alt="" />
                            {/* <img className='setResonive' src="/assets/images/about/Group21894.png" alt="" /> */}
                            {/* style={{ width: "auto", maxWidth: "100%", height: "auto" }}  */}
                            <img className='setResonive1 elementCenter' src="/assets/images/testOurmission.png" alt="" />
                            {/* <img className='setResonive1 elementCenter' src="/assets/images/about/mobilegirl.png" alt="" /> */}
                            {/* <img className='setResonive1 elementCenter' src="/assets/images/about/imageForMobileBanner1.png" alt="" /> */}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default AboutBanner