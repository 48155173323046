import axios from "../axios";

export const getAllTasks = async () => {
  try {
    // debugger
    const config = {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("sparrowSignIn"))?.token
        }`,
      },
    };
    const response = await axios.get(`/tasks`, config);
    return response;
  } catch (err) {
    return err.response;
  }
};
