import { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
// import FooterNav from "../mobilefooternav/FooterNav";
import CustomerTaskHistory from "./CustomerTaskHistory";
// import { Link, useNavigate } from 'react-router-dom'
import { showStudent } from "../../services/users/ShowSingleStudent";
// import { getLocations } from '../../services/locations/Locations';
import CustomerUpdate from "./customerModal/CustomerUpdate";
import { verificationAccount } from "../../services/authentication/VerificationNotification";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
// import Swal from 'sweetalert2';
import ReactPixel from "react-facebook-pixel";
import Loader from "../loader/Loader";
import { handleImageError } from "../../utils/constants";
import CustomerTaskHistoryNew from "./CustomerTaskHistoryNew";
import { getClientTaskList } from "../../services/order/ClientTaskList";

const CustomerMyProfile = () => {
  let navigate = useNavigate();
  const [loder, setLoder] = useState(true);

  const [updateContent, setUpdateContent] = useState(false);
  const [clientDetail, setClientDetails] = useState(false);

  // console.log(clientDetail)
  // declare the data fetching function
  const client_id = parseInt(
    JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.id
  );

  const fetchData = async () => {
    const clientApiResponce = await showStudent(client_id);
    if (clientApiResponce.status === 200) {
      setLoder(false);
      if (clientApiResponce?.data?.user?.email_verified_at === null) {
        Swal.fire({
          title: "Email is Not Verified!",
          text: "Click yes, to visit verify email page.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#82d051",
          cancelButtonColor: "#d33",
          confirmButtonText: "Verify",
        }).then((result) => {
          if (result.isConfirmed) {
            const resp1 = verificationAccount(clientApiResponce?.data);
            setTimeout(() => {
              Swal.fire({
                title: "Email has been sent!",
                timer: 1500,
                icon: "success",
                showConfirmButton: false,
              });
            }, 1000);

            setTimeout(() => {
              navigate("/signUpRoute/confirm-email");
            }, 2000);
          }
        });
      }

      setClientDetails(clientApiResponce.data.user);
    }
  };
  // geting total tasks value from child
  const [totaltasks, setTotaltasks] = useState();
  // console.log(totaltasks);
  /** this is for student modal **/
  const [clientEditModal, setClientEditModal] = useState(false);
  const handleClientClose = () => setClientEditModal(false);

  useEffect(() => {
    ReactPixel.track("Customer Profile");

    fetchData();
  }, [updateContent, clientEditModal]);

  const [orderList, setOrderList] = useState([]);
  const getOrderListFunc = async () => {
    const response = await getClientTaskList(client_id);
    setOrderList(response?.data?.order);
  };
  useEffect(() => {
    getOrderListFunc();
  }, []);
  return (
    <>
      {loder ? (
        <div style={{ height: "100vh" }} className="height100vh">
          <Loader />
        </div>
      ) : (
        <div className="bgNotiLines cMyProfile">
          <Container>
            <h2 className="mb-5 text-center" style={{ position: "relative" }}>
              My <span className="green">Profile</span>
              <span>
                <img
                  className="info"
                  src="/assets/images/newlogos/Info.svg"
                  alt=""
                />
                <span className="hoverTextForR">
                  Your personal information like email and phone number are
                  hidden on your profile and only used for sending
                  notifications. Create a task and invite a few suitable
                  students.
                </span>
              </span>
            </h2>
            <Row>
              <Col md={4}>
                <div className="studentDetails customerProfileDetail">
                  <div className="studentDetailsImg">
                    <img src={clientDetail?.image} alt="" />
                  </div>
                  <h5 className="upH5 mt-2">
                    {clientDetail?.first_name?.charAt(0)?.toUpperCase() +
                      clientDetail?.first_name?.slice(1)}
                    {clientDetail?.last_name &&
                      " " + clientDetail?.last_name.charAt(0).toUpperCase()}
                    .
                  </h5>
                  <div className="studentContent mt-3 ml-20 mb-new-ml">
                    {clientDetail?.location && (
                      <div className="mb-3 block ml-3 locationVerifyDiv">
                        <div className="with20px d-inline-block">
                          <img
                            src="/assets/images/customerDashboard/lcoationvector.svg"
                            alt=""
                          />
                        </div>
                        <span className="ml-7">
                          {clientDetail?.location?.city.trim()},{" "}
                          {clientDetail?.location?.state.trim()}
                        </span>
                      </div>
                    )}

                    <div className="block ml-3 locationVerifyDiv">
                      <div className="with20px d-inline-block">
                        <img
                          src="/assets/images/customerDashboard/verified-dashboar.svg"
                          alt=""
                        />
                      </div>
                      <span className="ml-7">
                        {clientDetail?.email_verified_at
                          ? "Verified"
                          : "Not Verified"}
                      </span>
                    </div>

                    <div className="mb-locationVerifyDiv d-inline-flex justify-content-center">
                      <Link
                        to="/dashboardRoute/all-students/create-tasks/null/null"
                        className="primary dashboardHederBtn my-4 ml-0"
                      >
                        Post Task
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={8}>
                <div className="personalDetails mb-personal-detail position-relative">
                  <h4>Personal Details</h4>

                  {clientEditModal && (
                    <CustomerUpdate
                      onClose={handleClientClose}
                      modalValue={clientEditModal}
                      clientDetail={clientDetail}
                      modalDone={() => setClientEditModal(false)}
                    />
                  )}

                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => setClientEditModal(true)}
                  >
                    <img
                      className="editImager"
                      src="/assets/images/customerDashboard/editButtonModal.svg"
                      alt=""
                    />
                  </a>

                  <Row className="PersonalDetailsHed mt-4">
                    <Col md={6}>
                      <div className="mb-4">
                        <p className="mb-1" style={{ color: "black" }}>
                          Name
                        </p>
                        <p className="aboutContent">
                          {clientDetail?.first_name?.charAt(0)?.toUpperCase() +
                            clientDetail?.first_name?.slice(1) +
                            " " +
                            clientDetail?.last_name?.charAt(0)?.toUpperCase() +
                            clientDetail?.last_name?.slice(1)}
                        </p>
                      </div>
                      <div>
                        <p className="mb-1" style={{ color: "black" }}>
                          Location
                        </p>
                        <p className="aboutContent">
                          {clientDetail?.location
                            ? // locations?.map((x) => {
                              //     if (x?.id == clientDetail?.location?.id) {
                              //         return x.city + ', ' + x.state + ' ' + x.zip
                              //     }
                              // })\
                              clientDetail?.location?.city +
                              ", " +
                              clientDetail?.location?.state +
                              " " +
                              clientDetail?.location?.zip
                            : "No location available"}
                        </p>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-4 mTop10">
                        <p className="mb-1" style={{ color: "black" }}>
                          Email
                        </p>
                        <p className="aboutContent">{clientDetail?.email}</p>
                      </div>
                      <div>
                        <p className="mb-1" style={{ color: "black" }}>
                          Phone Number
                        </p>
                        <p className="aboutContent">{clientDetail?.phone}</p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="paymentDetails mb-payment-detail mb-payment-detail1 mt-4">
                  <div className="PersonalDetailsHed">
                    <h4>Your Stats</h4>
                  </div>
                  <Row className="yourStatsDetails_client mt-2">
                    <Col md={4}>
                      <div className="mb-4">
                        <h5 className="mb-1" style={{ color: "black" }}>
                          Saved Students
                        </h5>
                        <span>
                          <img
                            className="yourStats_client"
                            src="/assets/images/customerDashboard/VectorPerson_icon.svg"
                            alt=""
                          />
                          &nbsp;&nbsp;
                          <p className="aboutContent d-inline-block">
                            {clientDetail?.totalSavedStudents} Students
                          </p>
                        </span>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="mb-4 mTop10">
                        <h5 className="mb-1" style={{ color: "black" }}>
                          Tasks Created
                        </h5>
                        <span>
                          <img
                            className="yourStats_client"
                            src="/assets/images/customerDashboard/VectorTask_icon.svg"
                            alt=""
                          />
                          &nbsp;&nbsp;
                          <p className="aboutContent d-inline-block">
                            {orderList?.length} Tasks
                          </p>
                        </span>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="mb-4 mTop10">
                        <h5 className="mb-1" style={{ color: "black" }}>
                          Common Task
                        </h5>
                        <span>
                          <img
                            className="yourStats_client"
                            src="/assets/images/customerDashboard/greenStar.svg"
                            alt=""
                          />
                          &nbsp;&nbsp;
                          <p className="aboutContent d-inline-block">
                            {clientDetail?.common_task ?? "No task"}
                          </p>
                        </span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row className="mx-0">
              <div style={{ position: "relative" }}>
                <span>
                  <img
                    className="info2"
                    src="/assets/images/newlogos/Info.svg"
                    alt=""
                  />
                  <span className="hoverTextForR2">
                    The task section provides you an overview of your current
                    and previous tasks. For more details about each task and the
                    students you've invited, simply click on the status button.
                  </span>
                </span>
              </div>
              <CustomerTaskHistoryNew />
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

export default CustomerMyProfile;
