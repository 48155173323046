import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import Swal from "sweetalert2";
import { getSingleOrderDraft } from "../../services/order/GetSingleOrderDraft";
import moment from "moment";
import Loader from "../loader/Loader";
import { handleImageError } from "../../utils/constants";
import StudentDetails from "./StudentDetailsCard";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Pagination } from "swiper";

const CustomerOrderDraftDetails = () => {
  let { orderId } = useParams();

  // for payment
  // const stripe = useStripe();
  // const elements = useElements();

  // get order details data
  const [orderData, setOrderData] = useState([]);
  const [loder, setLoder] = useState(true);
  // console.log(orderData)

  const getOrderFunc = async () => {
    const response = await getSingleOrderDraft(orderId);

    if (response.status === 200) {
      setOrderData(response.data.order);
      setLoder(false);
    } else {
      Swal.fire({
        title: response.data.message,
        timer: 1500,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };
  useEffect(() => {
    getOrderFunc();
  }, []);

  const getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };
  // console.log(orderData?.order_details?.task?.location)
  return (
    <>
      {loder ? (
        <div style={{ height: "100vh" }} className="height100vh">
          <Loader />
        </div>
      ) : (
        <div className="bgNotiLines">
          <Container>
            <h2 className="text-center alignIt">
              Task <span className="green">Details</span>
            </h2>
            <div className="orderContentMain orderContentMainmb">
              <Row className="mb-justify-center setResonive">
                <Col md={4}></Col>
                <Col md={5}>
                  <div className="orderHedg">
                    <>
                      <img src="/assets/images/offer/boxg.svg" alt="" />
                    </>
                    <p>Hourly Pay</p>
                    <>
                      <button style={{ cursor: "unset" }}>
                        ${orderData?.order_details?.task?.hourly_rate}
                      </button>
                    </>
                  </div>
                </Col>
              </Row>
              {/* <Row className="mb-justify-center"> */}
              <Col md={12}>
                <Row
                  className="mb-justify-center setResonive1"
                  style={{ paddingBottom: "0", marginBottom: "-3px" }}
                >
                  <Col md={12}></Col>
                  <Col md={12}>
                    <div className="orderHedg">
                      <>
                        <img src="/assets/images/offer/boxg.svg" alt="" />
                      </>
                      <p>Hourly Pay</p>
                      <>
                        <button style={{ cursor: "unset" }}>
                          ${orderData?.order_details?.task?.hourly_rate}
                        </button>
                      </>
                    </div>
                  </Col>
                </Row>
                <Row style={{ justifyContent: "space-around", padding: "5%" }}>
                  <div className="orderDetailRight settaskDEtailg specialPage">
                    <div className="orderfirstBox mb-orderfirstBox-right">
                      <div className="orderfirstBoxP">
                        <div>
                          <img
                            src="/assets/images/customerDashboard/jobtype.svg"
                            alt=""
                          />
                        </div>
                        <div>
                          <p>Job Type</p>
                          <h5>{orderData?.order_details?.task?.type}</h5>
                        </div>
                      </div>
                      <div className="orderfirstBoxP">
                        <div>
                          <img
                            src="/assets/images/customerDashboard/tasks.svg"
                            alt=""
                          />
                        </div>
                        <div>
                          <p>Task</p>
                          <h5>{orderData?.order_details?.task?.title}</h5>
                        </div>
                      </div>
                      <div className="orderfirstBoxP">
                        <div>
                          <img
                            src="/assets/images/customerDashboard/locations.svg"
                            alt=""
                          />
                        </div>
                        <div>
                          <p>Location</p>
                          <h5>
                            {orderData?.order_details?.task?.location?.city},{" "}
                            {orderData?.order_details?.task?.location?.state}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className={`orderfirstBox mb-orderfirstBox-left`}>
                      <div className="orderfirstBoxP">
                        <div>
                          <img
                            src="/assets/images/customerDashboard/startdate.svg"
                            alt=""
                          />
                        </div>
                        <div>
                          <p>Start Date</p>
                          <h5>
                            {moment(
                              orderData?.order_details?.task?.start_date
                            ).format("MM-DD-YYYY")}
                          </h5>
                        </div>
                      </div>
                      {orderData?.order_details?.task?.end_date && (
                        <div className="orderfirstBoxP">
                          <div>
                            <img
                              src="/assets/images/customerDashboard/Vectordate.svg"
                              alt=""
                            />
                          </div>
                          <div>
                            <p>End Date</p>
                            <h5>
                              {moment(
                                orderData?.order_details?.task?.end_date
                              ).format("MM-DD-YYYY")}
                            </h5>
                          </div>
                        </div>
                      )}
                      <div className="orderfirstBoxP">
                        <div>
                          <img
                            src="/assets/images/customerDashboard/timee.svg"
                            alt=""
                          />
                        </div>
                        <div>
                          <p>Time</p>
                          <h5>
                            {orderData?.availability?.map((item) => {
                              return (
                                <div>
                                  <div>
                                    {item?.day.charAt(0).toUpperCase() +
                                      item?.day.slice(1)}
                                  </div>
                                  <div>
                                    {item.avail[0].start} - {item.avail[0].end}
                                  </div>
                                </div>
                              );
                            })}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* 2nd section */}

                  <div className="orderDetailRight taskDescriptionClass">
                    <p className="ml-1" style={{ color: "#82D051" }}>
                      Task Description
                    </p>
                    <p style={{ fontFamily: "Poppins" }} className="pd0101">
                      {orderData?.order_details?.task?.description}
                    </p>
                  </div>
                </Row>

                {/* <CardElement /> */}
                {orderData?.order_details?.status === "in progress" ? (
                  <Link
                    style={{ fontFamily: "Manrope" }}
                    to={`/dashboardRoute/feedback/${orderId}`}
                    className="primary common alignRiht"
                  >
                    Review Student
                  </Link>
                ) : (
                  ""
                )}
              </Col>
              <h2 className="text-center alignIt">
                Invited Students <span className="green">Details</span>
              </h2>
              <div className="homeUserSlidr homeUserSlidrResponsive remove padding0">
                {loder ? (
                  <div className="height100vh">
                    <Loader />
                  </div>
                ) : (
                  <Swiper
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                      },
                      768: {
                        slidesPerView: 2,
                      },
                      922: {
                        slidesPerView: 3,
                      },
                    }}
                    slidesPerView={3}
                    spaceBetween={30}
                    pagination={{
                      clickable: true,
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                  >
                    {orderData?.order_details?.invited_student.map(
                      (singleStudent) => {
                        if (singleStudent?.email_verified_at !== null) {
                          return (
                            <SwiperSlide
                              key={singleStudent?.id}
                              style={{ justifyContent: "center" }}
                            >
                              {/* Pass data and getAge function to the StudentDetails component */}
                              <StudentDetails
                                orderData={singleStudent}
                                getAge={getAge}
                              />
                            </SwiperSlide>
                          );
                        }
                      }
                    )}
                  </Swiper>
                )}
              </div>
            </div>
          </Container>
        </div>
      )}
    </>
  );
};

export default CustomerOrderDraftDetails;
