import React from "react";
import DashboardNav from "./DashboardNav";
import Sidebar from "./SideBar";
import { useContext } from "react";
import AuthContext from "../../context/AuthContext";
import { Fragment } from "react";

const DashboardNoFooter = ({ children }) => {
  const auth = useContext(AuthContext);
  const { globalLoader } = auth;

  return (
    <div>
      {globalLoader ? (
        <div className="height100vh height10vh">
          <div className="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <Fragment>
          <DashboardNav />
          <Sidebar />
          {children}
        </Fragment>
      )}
    </div>
  );
};

export default DashboardNoFooter;
