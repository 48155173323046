import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router";
import Swal from "sweetalert2";
import { getSingleOrder } from "../../services/order/GetSingleOrder";
import moment from "moment";
import Loader from "../loader/Loader";
import { emailUrl } from "../../utils/constants";
import { cancelOrder } from "../../services/order/CancelOrder";
import { sendEmail } from "../../services/contactus/ContactUs";
import { createNotification } from "../../services/notifications/CreateNotification";
import { createChatRoom } from "./customerinvitecards/CreatingChatRoom";

const CustomerOrderDetails = () => {
  let { orderId } = useParams();
  let navigate = useNavigate();
  const [orderData, setOrderData] = useState([]);
  const [btnLodrChat, setBtnLoderChat] = useState(false);
  const [loder, setLoder] = useState(true);
  const getOrderFunc = async () => {
    const response = await getSingleOrder(orderId);
    if (response.status === 200) {
      setOrderData(response.data.order);
      // if (response?.data?.order?.order_details?.status == "closed") {
      //   navigate(
      //     `/dashboardRoute/task-history/student-task-descp-for-client/${response?.data?.order?.order_details?.task_id}/${response?.data?.order?.order_details?.id}`
      //   );
      // }
      setLoder(false);
    } else {
      Swal.fire({
        title: response.data.message,
        timer: 1500,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };
  useEffect(() => {
    getOrderFunc();
  }, []);

  const getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const canceledFunc = async () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "common primary",
        cancelButton: "common btn-danger",
        title: "lineHeight",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure you want to cancel this task?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        iconColor: "#82d051",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const data = {
            order_id: orderData?.order_details?.id,
            status: 4,
            type: orderData?.order_details?.status == "pending" ? "order_draft" : orderData?.order_details?.status == "in progress" ? "order" : null,
          };

          const resp = await cancelOrder(data);
          if (resp.status === 200) {
            const student = orderData?.order_details?.student;
            const dataForEmail = {
              to: student.email,
              subject: `Task : ${orderData?.order_details?.title} has been canceled`,
              from: orderData?.order_details?.client?.email,
              first_name: student?.first_name,
              msg: orderData?.order_details?.description,
              action_url: `${emailUrl}/#/dashboardRoute/task-history/student-task-descp/${orderData?.order_details?.taskId}/${
                orderData?.order_details?.status == "pending"
                  ? orderData?.order_details?.order_draft?.id
                  : orderData?.order_details?.status == "in progress"
                  ? orderData?.order_details?.order?.id
                  : null
              }`,
              template: "student_notification_cancel_task",
            };

            await sendEmail(dataForEmail);

            const data = {
              type: 0,
              to_id: orderData?.order_details?.student?.id,
              from_id: orderData?.order_details?.client?.id,
              data: {
                title: "Task is cancelled",
                message: `Task canceled by ${orderData?.order_details?.client?.first_name}`,
                action_url: `/dashboardRoute/task-history/student-task-descp/${orderData?.order_details?.taskId}/${
                  orderData?.order_details?.status == "pending"
                    ? orderData?.order_details?.order_draft?.id
                    : orderData?.order_details?.status == "in progress"
                    ? orderData?.order_details?.order?.id
                    : null
                }`,
              },
              read_at: null,
            };
            const resp = await createNotification(data);
            if (resp.status === 200) {
              Swal.fire({
                title: "Task Canceled",
                timer: 1500,
                icon: "success",
                showConfirmButton: false,
              });
            }

            setTimeout(() => {
              navigate(`/dashboardRoute/customer-profile/`);
            }, 1800);
          }
        }
      });
  };
  const createChat = async (student_id, client_id, order_id, email, student_name, hiring) => {
    // creating chatRoom here
    setBtnLoderChat(true);
    let std_String = student_id.toString();
    let client_String = client_id.toString();
    const chat_room_id = await createChatRoom(std_String, client_String, orderId, order_id, null, null);

    setBtnLoderChat(false);
    setTimeout(() => {
      navigate(`/dashboardRoute/chatStudent?roomId=${chat_room_id}`);
    }, 500);
  };
  return (
    <>
      {loder ? (
        <div style={{ height: "100vh" }} className="height100vh">
          <Loader />
        </div>
      ) : (
        <div className="bgNotiLines">
          <Container>
            <h2 className="text-center alignIt">
              Task <span className="green">Details</span>
            </h2>
            <div className="orderContentMain orderContentMainmb">
              <Row className="mb-justify-center setResonive">
                <Col md={4}></Col>
                <Col md={5}>
                  <div className="orderHedg">
                    <>
                      <img src="/assets/images/offer/boxg.svg" alt="" />
                    </>
                    <p>Hourly Pay</p>
                    <>
                      <button style={{ cursor: "unset" }}>${orderData?.order_details?.task?.hourly_rate}</button>
                    </>
                  </div>
                </Col>
              </Row>
              <Row className="mb-justify-center">
                <Col md={4}>
                  <div className="studentDetails">
                    <h4 className="mb-3">Hired Student Details</h4>

                    <div className="studentDetailsImg">
                      <img src={orderData?.order_details?.student?.image} alt="" />
                    </div>
                    <h5 style={{ marginBottom: "10px" }} className="upH5">
                      {orderData?.order_details?.student?.first_name.charAt(0).toUpperCase() + orderData?.order_details?.student?.first_name.slice(1)}{" "}
                      &nbsp;
                      {orderData?.order_details?.student?.last_name.charAt(0).toUpperCase()}.
                      {orderData?.order_details?.student?.student_details?.dob &&
                        " " + getAge(orderData?.order_details?.student?.student_details?.dob) + "'"}
                    </h5>
                    <h5 className="belowH5">{orderData?.order_details?.student?.student_details?.grade} </h5>
                    <div className="studentContent ml-1">
                      <div className="mb-3 block ml-3232">
                        <img src="/assets/images/customerDashboard/capvector.svg" alt="" />
                        <span className="ml-7">
                          {orderData?.order_details?.student?.student_details?.college
                            ? orderData?.order_details?.student?.student_details?.college
                            : "Melo College"}
                        </span>
                      </div>
                      <div className="mb-3 block ml-3232">
                        <img src="/assets/images/customerDashboard/lcoationvector.svg" alt="" />
                        <span className="ml-7">
                          {orderData?.order_details?.student?.location
                            ? `${orderData?.order_details?.student?.location?.city?.trim()}, ${orderData?.order_details?.student?.location?.state.trim()}`
                            : "New York"}
                        </span>
                      </div>
                      <div className="mb-3 block ml-3232">
                        <img src="/assets/images/customerDashboard/verified-dashboar.svg" alt="" />
                        <span className="ml-7">{orderData?.order_details?.student?.email_verified_at !== null ? "Verified" : "Not Verified"}</span>
                      </div>
                    </div>
                    <div
                      className="hiredStudent"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                      }}
                    >
                      <Link
                        to={{
                          pathname: `/dashboardRoute/all-students/customer-student-profile/${orderData?.order_details?.student?.id}`,
                        }}
                        role="button"
                        key={orderData?.order_details?.student?.id}
                      >
                        <div className="profileBtn mt-4">
                          <button className="primary profileBtnSet hiredStudentProfileBtn">View Profile</button>
                        </div>
                      </Link>

                      {btnLodrChat ? (
                        <div
                          className="relative connEctLoder hiredStudentChatLink"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "139px",
                            padding: "12px 27px",
                            color: "#FFC700",
                            border: "1px solid #FFC700",
                            borderColor: "#FFC700",
                            borderRadius: "50px",
                          }}
                        >
                          <div
                            className="loader"
                            style={{
                              borderTop: "5px solid #FFC700",
                              position: "absolute",
                              marginLeft: "2px",
                            }}
                          ></div>
                          <Link
                            to=""
                            style={{
                              color: "#FFC700",
                            }}
                          >
                            Chat
                          </Link>
                        </div>
                      ) : (
                        <Link
                          to={""}
                          role="button"
                          onClick={() => {
                            createChat(
                              orderData?.order_details?.student.id,
                              orderData?.order_details?.client?.id,
                              orderData?.order_details?.order_draft?.id,
                              orderData?.order_details?.student?.email,
                              orderData?.order_details?.student?.first_name.charAt(0).toUpperCase() +
                                orderData?.order_details?.student?.first_name.slice(1),
                              0
                            );
                          }}
                        >
                          <div className="profileBtn">
                            <button
                              className="hiredStudentChatLink"
                              style={{
                                padding: "12px 27px",
                                color: "#FFC700",
                                border: "1px solid #FFC700",
                                borderColor: "#FFC700",
                                borderRadius: "50px",
                                width: "139px",
                                background: "none",
                              }}
                            >
                              Chat
                            </button>
                          </div>
                        </Link>
                      )}
                    </div>
                  </div>
                </Col>
                <Col md={5}>
                  <Row className="mb-justify-center setResonive1" style={{ paddingBottom: "0", marginBottom: "-3px" }}>
                    <Col md={4}></Col>
                    <Col md={5}>
                      <div className="orderHedg">
                        <>
                          <img src="/assets/images/offer/boxg.svg" alt="" />
                        </>
                        <p>Hourly Pay</p>
                        <>
                          <button style={{ cursor: "unset" }}>${orderData?.order_details?.task?.hourly_rate}</button>
                        </>
                      </div>
                    </Col>
                  </Row>
                  <div className="orderDetailRight settaskDEtailg specialPage">
                    <div className="orderfirstBox mb-orderfirstBox-right">
                      <div className="orderfirstBoxP">
                        <div>
                          <img src="/assets/images/customerDashboard/jobtype.svg" alt="" />
                        </div>
                        <div>
                          <p>Job Type</p>
                          <h5>{orderData?.order_details?.task?.type}</h5>
                        </div>
                      </div>
                      <div className="orderfirstBoxP">
                        <div>
                          <img src="/assets/images/customerDashboard/tasks.svg" alt="" />
                        </div>
                        <div>
                          <p>Task</p>
                          <h5>{orderData?.order_details?.task?.title}</h5>
                        </div>
                      </div>
                      <div className="orderfirstBoxP">
                        <div>
                          <img src="/assets/images/customerDashboard/locations.svg" alt="" />
                        </div>
                        <div>
                          <p>Location</p>
                          <h5>
                            {orderData?.order_details?.task?.location?.city}, {orderData?.order_details?.task?.location?.state}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className={`orderfirstBox mb-orderfirstBox-left`}>
                      <div className="orderfirstBoxP">
                        <div>
                          <img src="/assets/images/customerDashboard/startdate.svg" alt="" />
                        </div>
                        <div>
                          <p>Start Date</p>
                          <h5>{moment(orderData?.order_details?.task?.start_date).format("MM-DD-YYYY")}</h5>
                        </div>
                      </div>
                      {orderData?.order_details?.task?.end_date && (
                        <div className="orderfirstBoxP">
                          <div>
                            <img src="/assets/images/customerDashboard/Vectordate.svg" alt="" />
                          </div>
                          <div>
                            <p>End Date</p>
                            <h5>{moment(orderData?.order_details?.task?.end_date).format("MM-DD-YYYY")}</h5>
                          </div>
                        </div>
                      )}
                      <div className="orderfirstBoxP">
                        <div>
                          <img src="/assets/images/customerDashboard/timee.svg" alt="" />
                        </div>
                        <div>
                          <p>Time</p>
                          <h5>
                            {orderData?.availability?.map((item) => {
                              return (
                                <div>
                                  <div>{item?.day.charAt(0).toUpperCase() + item?.day.slice(1)}</div>
                                  <div>
                                    {item.avail[0].start} - {item.avail[0].end}
                                  </div>
                                </div>
                              );
                            })}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* 2nd section */}

                  <div className="orderDetailRight taskDescriptionClass">
                    <p className="ml-1" style={{ color: "#82D051" }}>
                      Task Description
                    </p>
                    <p style={{ fontFamily: "Poppins" }} className="pd0101">
                      {orderData?.order_details?.task?.description}
                    </p>
                  </div>

                  {/* <CardElement /> */}
                  {orderData?.order_details?.status === "in progress" || orderData?.order_details?.status === "review" ? (
                    <div className="payBtnDiv justify-content-end">
                      <Link
                        style={{ fontFamily: "Manrope" }}
                        to={`/dashboardRoute/feedback/${orderData?.order_details?.task_id}`}
                        className="primary common alignRiht"
                      >
                        Review Student
                      </Link>

                      <Link className="secondary common cancelBtn1" onClick={canceledFunc}>
                        Cancel Task
                      </Link>
                    </div>
                  ) : null}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      )}
    </>
  );
};

export default CustomerOrderDetails;
