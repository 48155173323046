import dummyImgg from "./dummy12.jpeg";
import {isMobile} from 'react-device-detect';

export const emailUrl = "findsparrow.com";
export const handleImageError = (e) => {
  console.error("test", e);
  // const defaultImage = new Image();
  // defaultImage.src = '/assets/images/dummy12.jpeg?v=' + Date.now();
  // defaultImage.onload = () => {
  //   e.target.src = defaultImage.src;
  // };
};

// converting base64Image to blob
export function base64ToBlob(base64Data, contentType) {
  contentType = contentType || "";
  var sliceSize = 1024;
  var byteCharacters = atob(base64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);
    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}

// convert image to base64
export function convertImageToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
}

export const colourStylesLocation = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  menu: (styles, state) => ({
    ...styles,
    marginLeft: "-40px",
    width: window.innerWidth < 768 ? "300px" : "281.13px",
    zIndex: 999,
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      fontSize: isMobile ? "14px" : 'inherit',
      backgroundColor: isDisabled
        ? "#cacaca"
        : isSelected
        ? "#82D051"
        : isFocused
        ? undefined
        : undefined,
      color: isDisabled
        ? "#cacaca"
        : isSelected
        ? "#fcfcfc"
        : isFocused
        ? "#898A8D"
        : "#898A8D",
      cursor: isDisabled ? "not-allowed" : "default",
      ":active": {
        ...styles[":active"],
        backgroundColor: "#82D051",
      },
    };
  },
  input: (styles) => ({
    ...styles,
    color: "#898A8D",
    fontFamily: "'Manrope', sans-serif",
    fontWeight: 400,
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "#898a8d",
    fontFamily: "'Manrope', sans-serif",
    fontWeight: 400,
  }),
  singleValue: (styles) => ({
    ...styles,
    color: "#898A8D",
    fontFamily: "'Manrope', sans-serif",
    fontWeight: 400,
  }),
  
};

export const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  menu: (styles, state) => ({
    ...styles,
    marginLeft: "1px",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? "#cacaca"
        : isSelected
        ? "#82D051"
        : isFocused
        ? undefined
        : undefined,
      color: isDisabled
        ? "#cacaca"
        : isSelected
        ? "#fcfcfc"
        : isFocused
        ? "#898A8D"
        : "#898A8D",
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: "#82D051",
      },
    };
  },
  input: (styles) => ({
    ...styles,
    color: "#898A8D",
    fontFamily: "'Manrope', sans-serif",
    fontWeight: 400,
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "#898a8d",
    fontFamily: "'Manrope', sans-serif",
    fontWeight: 400,
  }),
  singleValue: (styles) => ({
    ...styles,
    color: "#898A8D",
    fontFamily: "'Manrope', sans-serif",
    fontWeight: 400,
  }),
};
