export const sideBarList = [
  {
    id: "1",
    imagePath: "/assets/images/sidebar/Home.svg",
    imagePathHover: "/assets/images/sidebar/Home1.svg",
    slink: "/dashboardRoute/student-profile/",
    clink: "/dashboardRoute/customer-profile",
    linkName: "Dashboard",
    type: "global",
  },
  {
    id: "2",
    imagePath: "/assets/images/sidebar/srchSidebar.svg",
    imagePathHover: "/assets/images/sidebar/srch1.svg",
    clink: "/dashboardRoute/all-students",
    linkName: "Search Student",
    type: "client",
  },
  {
    id: "3",
    imagePath: "/assets/images/sidebar/peple.svg",
    imagePathHover: "/assets/images/sidebar/peple1.svg",
    clink: "/dashboardRoute/my-students",
    linkName: "My Students",
    type: "client",
  },
  {
    id: "4",
    imagePathHover: "/assets/images/sidebar/srch1.svg",
    imagePath: "/assets/images/sidebar/srchSidebar.svg",
    slink: "/dashboardRoute/search-tasks/",
    linkName: "Find Jobs",
    type: "student",
  },
  {
    id: "4800",
    imagePath: "/assets/images/sidebar/Commentsidebar.svg",
    imagePathHover: "/assets/images/sidebar/Comment1.svg",
    clink: "/dashboardRoute/chatStudent",
    slink: "/dashboardRoute/chatStudent",
    linkName: "Chat",
    type: "global",
  },
  {
    id: "611",
    imagePath: "/assets/images/sidebar/paperSidebar_old.svg",
    imagePathHover: "/assets/images/sidebar/paper1_old.svg",
    slink: "/dashboardRoute/task-history",
    linkName: "Previous Tasks",
    type: "student",
  },
  {
    id: "571",
    imagePath: "/assets/images/sidebar/bell.svg",
    imagePathHover: "/assets/images/sidebar/bell1.svg",
    clink: "/dashboardRoute/customer-notification",
    slink: "/dashboardRoute/student-notifications",
    linkName: "Notifications",
    type: "global",
    // session: sessionStorage.getItem("parentWithClass")
  },

  // {
  //     id: "7",
  //     imagePath: "/assets/images/sidebar/bnnk.svg",
  //     imagePathHover: "/assets/images/sidebar/bnkk1.svg",
  //     slink: "/dashboardRoute/student-earnings",
  //     linkName: "Bank Details",
  //     type: "student",
  // },
];
