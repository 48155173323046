import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";
import {
  days,
  timeSlots,
} from "../../../services/availibility&timeslots/Availibility";
import { createAvailability } from "../../../services/availibility&timeslots/CreateAvailability";
import { useMemo } from "react";
import Loader from "../../loader/Loader";

// import LowerCircle from "../LowerCircle";

const StepTwo = () => {
  let navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loader, setLoader] = useState(false);
  const [loder, setLoder] = useState(true);

  useEffect(() => {
    getDaysFunc();
    getTimeFunc();
  }, []);

  // get working days
  const [workdays, setworkdays] = useState();
  const getDaysFunc = async () => {
    const response = await days();

    if (response.status === 200) {
      let order = {
        monday: 1,
        tuesday: 2,
        wednesday: 3,
        thursday: 4,
        friday: 5,
        saturday: 6,
        sunday: 7,
      };
      let daysArray = response.data;
      daysArray.sort((a, b) => {
        return order[a.day] - order[b.day];
      });

      setworkdays(daysArray);
    } else {
      Swal.fire({
        title: response.data.message,
        timer: 1500,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };

  // get timing slots
  const [timeSlot, setTimeSlot] = useState();

  const getTimeFunc = async () => {
    const response1 = await timeSlots();

    if (response1.status === 200) {
      setTimeSlot(response1.data);
      setLoder(false);
    } else {
      Swal.fire({
        title: response1.data.message,
        timer: 1500,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };

  const optionTime = [];
  if (timeSlot?.length > 0) {
    timeSlot?.map((singleTime) => {
      return optionTime.push({
        label: singleTime?.slot,
        value: singleTime.id,
      });
    });
  }

  // post Api
  const [selectPlaceholder, setSelectPlaceholder] = useState("Select ...");
  const [slotsUpdated, setSlotsUpdated] = useState(false);
  const [endTimes, setEndTimes] = useState({});
  const [startTimes, setStartTimes] = useState({});
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [userSlots, setuserSlots] = useState([]);
  // 
  const handleEndSelectChange = (selectedOption, itemID) => {
    setEndTimes((prevEndTimes) => ({
      ...prevEndTimes,
      [itemID]: selectedOption.value,
    }));

    if (selectedOption.value !== null) {
      makeArray(itemID, selectedOption.value);
    }
  };


  const makeArray = (dayID, end) => {
    setEnd(end);
    let copyUseState = [...userSlots];
    const start = startTimes[dayID];

    if (start && end && start !== end && start < end) {
      let toBeAdd = false;
      let countSameDaySlot = 0;

      copyUseState?.forEach((singleIndex) => {
        if (
          (singleIndex.start_time_slot_id === start ||
            singleIndex.end_time_slot_id === end) &&
          singleIndex.day_id === dayID
        ) {
          toBeAdd = true;
        }
        if (singleIndex.day_id === dayID) {
          countSameDaySlot++;
        }
      });

      if (countSameDaySlot >= 3) {
        toBeAdd = true;
      }

      if (!toBeAdd) {
        copyUseState.push({
          start_time_slot_id: start,
          end_time_slot_id: end,
          day_id: dayID,
          user_id: JSON.parse(localStorage.getItem("sparrowSignIn")).user.id,
        });

        setuserSlots(copyUseState);
        setStartTimes((prevStartTimes) => ({
          ...prevStartTimes,
          [dayID]: null,
        }));
        setEndTimes((prevEndTimes) => ({
          ...prevEndTimes,
          [dayID]: null,
        }));
        setSelectPlaceholder("Select ...");
        setStart(null);
        setEnd(null);
        setSlotsUpdated(true);
      }
    }
  };

  useEffect(() => {
    if (slotsUpdated) {
      setSlotsUpdated(false);
    }
  }, [slotsUpdated]);
  const sortedUserSlots1 = useMemo(() => {
    const sortedSlots = userSlots
      .sort((a, b) => {
        if (a.day_id !== b.day_id) {
          return a.day_id - b.day_id;
        } else {
          return a.start_time_slot_id - b.start_time_slot_id;
        }
      });

    return sortedSlots;
  }, [userSlots, slotsUpdated]);

  const searchtimeID = (nameKey, myArray) => {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].id == nameKey) {
        return myArray[i].slot;
      }
    }
  };

  const removeValueFromArray = (index_single, day_id) => {
    let copyUseState = [...userSlots];
    copyUseState.splice(index_single, 1);

    setuserSlots(copyUseState);
  };
  // fill out later
  const handleChange = (e) => {
    let isChecked = e.target.checked;
    if (isChecked) {
      setTimeout(() => {
        // navigate('/signUpRoute/step-four');
        navigate("/signUpRoute/profile-done");
      }, 200);
    }
  };

  // submit fucntion
  const onSubmit = async (fData) => {
    setLoader(true);
    const resp = await createAvailability(userSlots);
    if (resp.status === 200) {
      Swal.fire({
        title: "Work details have been added!",
        timer: 1500,
        icon: "success",
        showConfirmButton: false,
      });
      setLoader(false);
      navigate("/signUpRoute/profile-done");
    } else {
      Swal.fire({
        title: resp.data.message,
        timer: 3500,
        icon: "error",
        showConfirmButton: false,
      });
    }

    setLoader(false);
  };

  return (
    // work avalilibity
    <div>
      {loder ? (
        <div className="height100vh">
          <Loader />
        </div>
      ) : (
        <div className="setLower2">
          <div className="mb-green-arrow">
            <Link to="/signUpRoute/step-two">
              <img src="./assets/images/Vector_backIcon.svg" alt="" />
            </Link>
          </div>
          <div className="block-img-mb mb-head-img">
            <img src="/assets/images/Ellipse511.png" alt="123" />
          </div>
          {/* desktopStep */}
          <div className="stOneDiv setResonive">
            <img src="/assets/images/offer/Processbar/Group22614.svg" alt="" />
          </div>
          {/* mobileStep */}
          <div className="stOneDivResp setResonive1">
            <img src="/assets/images/offer/stepThreeMobile.svg" alt="" />
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="SignupConfirmEmail stepForm"
          >
            <div className="stepOne">
              <h2 style={{ textAlign: "center" }}>Work Availability</h2>

              {/*  */}
              {workdays?.map((item) => {
                return (
                  <div className="mb35 timeSlotD" key={item?.id}>
                    <div className="workPoint">
                      <div className="state">
                        <label>
                          {item?.day.charAt(0).toUpperCase() + item?.day.slice(1)}
                        </label>
                      </div>
                    </div>
                    {errors.dayId && <span className="eror">Select Day</span>}
                    <div className="mbl-mb-time">
                      <label className="displaynone-mb-551" htmlFor="appt">
                        Time of day:
                      </label>
                    </div>
                    <div className="workingTime justifyNone time-mb-select">
                      <div className="mb-time-zone px-2">
                        <label className="displaynone-mb" htmlFor="appt">
                          Select a time:
                        </label>
                        <Select
                          className="timingInput_select mb-time-1 d-inline-block"
                          options={optionTime}
                          placeholder={selectPlaceholder}
                          onChange={(selectedOption) => {
                            // Update the start time for the specific workday
                            setStartTimes((prevStartTimes) => ({
                              ...prevStartTimes,
                              [item.id]: selectedOption.value,
                            }));
                          }}
                          value={startTimes[item.id] ? optionTime.find(option => option.value === startTimes[item.id]) : null}
                        />
                        <span className="d-inline-flex timeBetweenStartEnd px-2">-</span>
                        <Select
                          className="timingInput_select mb-time-1 d-inline-block"
                          options={optionTime}
                          placeholder={selectPlaceholder}
                          onBlur={() => {
                            const selectedEndTime = endTimes[item.id];
                            if (selectedEndTime !== null) {
                              makeArray(item?.id, selectedEndTime);
                            }
                          }}
                          onChange={(selectedOption) => {
                            handleEndSelectChange(selectedOption, item.id);
                          }}
                          value={endTimes[item.id] ? optionTime.find(option => option.value === endTimes[item.id]) : null}
                          isDisabled={!startTimes[item.id]} // Disable if start is null
                        />
                      </div>
                    </div>
                    <div className="timingBox w-100">
                      {sortedUserSlots1?.map((parentObjArray, index) =>
                        parentObjArray?.day_id === item?.id ? (
                          <div key={index} className="timeSlotPills">
                            {searchtimeID(parentObjArray.start_time_slot_id, timeSlot)} -{" "}
                            {searchtimeID(parentObjArray.end_time_slot_id, timeSlot)}{" "}
                            <span
                              style={{
                                color: "white",
                                cursor: "pointer",
                              }}
                              onClick={() => removeValueFromArray(index, item?.id)}
                            >
                              &#x2715;
                            </span>{" "}
                          </div>
                        ) : null
                      )}
                    </div>
                  </div>
                );
              })}

              {/*  */}
              <label
                style={{ display: "flex", alignItems: "center" }}
                className="container1"
              >
                <input type="checkbox" onChange={(e) => handleChange(e)} />
                <span class="checkmark"></span>
                <label className="lbel">Fill out later</label>
              </label>
              <div className="formBtnSet">
                <Link
                  to="/signUpRoute/step-two"
                  className="backBtn displaynone-mb"
                >
                  Back
                </Link>
                {loader ? (
                  <div className="relative stepLoderAlign">
                    <div className="loader alignLoader"></div>
                    <input
                      type="submit"
                      className="backBtn ctn pl40"
                      disabled
                      value="Continue"
                    />
                  </div>
                ) : (
                  <input
                    type="submit"
                    className="backBtn ctn"
                    value="Continue"
                  />
                )}
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default StepTwo;
