import React from "react";
import { Container } from "react-bootstrap";

const SparrowUserPledge = () => {
  return (
    <div className="mt140 termsPage">
      <Container>
        <h2 className="text-center">The Sparrow User Pledge Terms</h2>
        <br />
        <br />
        <p>
          The Sparrow User Pledge (“User Pledge” or “Pledge”) is a part of Findsparrow, Inc.’s Terms of Services. Capitalized terms not defined herein
          shall have the definitions set forth in Sparrow’s Terms of Service). Nothing in the Sparrow User Pledge shall be construed to contradict or
          otherwise invalidate any part of Sparrow’s Terms of Service or Privacy Policy, which remain in full force and effect.
        </p>
        <br />
        <p>
          THE SPARROW TERMS OF SERVICE CONTAIN A BINDING ARBITRATION PROVISION AND CLASS ACTION WAIVER WHICH ARE APPLICABLE TO THIS USER PLEDGE AND
          AFFECT YOUR LEGAL RIGHTS. PLEASE READ THE ARBITRATION AND CLASS ACTION WAIVER PROVISIONS CAREFULLY. YOU ACKNOWLEDGE AND AGREE THAT BY USING
          THE SPARROW PLATFORM, YOU HAVE READ, UNDERSTAND, AND AGREE TO BE BOUND BY THE TERMS OF SERVICE (INCLUDING THE ARBITRATION PROVISION AND
          CLASS ACTION WAIVER), THE PRIVACY POLICY, AND THE TERMS OF THIS USER PLEDGE (TOGETHER, THE “AGREEMENT”).
        </p>
        <br />
        <h4>What is the “Sparrow User Pledge”?</h4>
        <div className="ml-1 mb-2">
          <br />
          <p>
            Sparrow is a technology platform that allows Users to connect with each other so they can be their most productive selves. Students
            determine what categories they are qualified to task in, and scope the Task directly with their Client. Sparrow does not oversee, monitor,
            or direct how a Student performs a Task, does not monitor Tasks or chat threads between Users, and does not otherwise assume
            responsibility for the actions of Users. Clients are advised to confirm with their Student that s/he is qualified to perform the Task
            prior to the Task taking place. Sparrow is not liable for the acts or omissions of Users, nor does Sparrow provide insurance against any
            losses sustained by Users. That said, Sparrow wants Users to be happy about their experience using the Sparrow Platform, and the User
            Pledge is in place to encourage continued use of the Sparrow Platform.
          </p>
          <br />
          <p>
            If Users fail to resolve an issue between themselves, and subject to the terms, exclusions and limitations set forth in the Agreement
            (including this Sparrow User Pledge), Sparrow may offer the following in its discretion on a case-by-case basis:
          </p>
          <br />
          <ul>
            <li>
              Clients up to USD Two Thousand Dollars ($2,000) per occurrence for Property Damage arising as a direct result of Negligence of a Student
              during performance of a Task through the Sparrow Platform;
            </li>
            <li>
              Users up to USD Two Thousand Dollars ($2,000) per occurrence for Bodily Injury sustained by a User who did not cause the injury, as a
              direct result of Negligence by another User during the performance of a Task through the Sparrow Platform;
            </li>
            <li>
              Clients up to USD Two Thousand Dollars ($2,000) per occurrence for Theft of a User’s property by a Student during performance of a Task
              through the Sparrow Platform.
            </li>
          </ul>
          <p>
            Terms and Conditions: In order to be eligible for payment under the Sparrow User Pledge, Users must comply with the following terms and
            conditions:
          </p>
          <br />
          <ul>
            <li>
              If the Task giving rise to the User Pledge Claim (“the Claim”) required a licensed professional to perform (e.g., this could include
              plumbing, electrical, medical professionals, legal work, notaries public, licensed general contractors, exterminators, etc), the Client
              has obtained proof prior to the execution of the Task that the Student has such license;
            </li>
            <li>The Task giving rise to the Claim must not violate Sparrow’s Terms of Condition;</li>
            <li>
              The Task giving rise to the Claim must have been matched through the Sparrow Platform, performed by the hired Student, and paid for in
              full via the Sparrow Platform;
            </li>
            <li>You must submit a request within fourteen (14) days of the performance of the Task giving rise to the Claim;</li>
            <li>
              You must not have submitted a separate User Pledge Claim within ninety (90) days prior to the occurrence of the Task giving rise to the
              Claim;
            </li>
            <li>You must not have violated our Terms of Service; and</li>
            <li>Your Sparrow account is in good standing, with no outstanding or pending balances owed to Sparrow or other Users.</li>
          </ul>
        </div>

        <h4>What is excluded from the Sparrow User Pledge?</h4>
        <div className="ml-1 mb-2">
          <br />
          <p>
            Sparrow is a technology platform that allows Users to connect with each other so they can be their most productive selves. Students
            determine what categories they are qualified to task in, and scope the Task directly with their Client. Sparrow does not oversee, monitor,
            or direct how a Student performs a Task, does not monitor Tasks or chat threads between Users, and does not otherwise assume
            responsibility for the actions of Users. Clients are advised to confirm with their Student that s/he is qualified to perform the Task
            prior to the Task taking place. Sparrow is not liable for the acts or omissions of Users, nor does Sparrow provide insurance against any
            losses sustained by Users. That said, Sparrow wants Users to be happy about their experience using the Sparrow Platform, and the User
            Pledge is in place to encourage continued use of the Sparrow Platform.
          </p>
          <br />
          <p>
            If Users fail to resolve an issue between themselves, and subject to the terms, exclusions and limitations set forth in the Agreement
            (including this Sparrow User Pledge), Sparrow may offer the following in its discretion on a case-by-case basis:
          </p>
          <br />
          <ul>
            <li>
              Clients up to USD Two Thousand Dollars ($2,000) per occurrence for Property Damage arising as a direct result of Negligence of a Student
              during performance of a Task through the Sparrow Platform;
            </li>
            <li>
              Users up to USD Two Thousand Dollars ($2,000) per occurrence for Bodily Injury sustained by a User who did not cause the injury, as a
              direct result of Negligence by another User during the performance of a Task through the Sparrow Platform;
            </li>
            <li>
              Clients up to USD Two Thousand Dollars ($2,000) per occurrence for Theft of a User’s property by a Student during performance of a Task
              through the Sparrow Platform.
            </li>
          </ul>
          <br />
          <p>
            Terms and Conditions: In order to be eligible for payment under the Sparrow User Pledge, Users must comply with the following terms and
            conditions:
          </p>
          <br />
          <ul>
            <li>
              If the Task giving rise to the User Pledge Claim (“the Claim”) required a licensed professional to perform (e.g., this could include
              plumbing, electrical, medical professionals, legal work, notaries public, licensed general contractors, exterminators, etc), the Client
              has obtained proof prior to the execution of the Task that the Student has such license;
            </li>
            <li>The Task giving rise to the Claim must not violate Sparrow’s Terms of Condition;</li>
            <li>
              The Task giving rise to the Claim must have been matched through the Sparrow Platform, performed by the hired Student, and paid for in
              full via the Sparrow Platform;
            </li>
            <li>You must submit a request within fourteen (14) days of the performance of the Task giving rise to the Claim;</li>
            <li>
              You must not have submitted a separate User Pledge Claim within ninety (90) days prior to the occurrence of the Task giving rise to the
              Claim;
            </li>
            <li>You must not have violated our Terms of Service; and</li>
            <li>Your Sparrow account is in good standing, with no outstanding or pending balances owed to Sparrow or other Users.</li>
          </ul>
        </div>

        <h4>What is excluded from the Sparrow User Pledge?</h4>
        <div className="ml-1 mb-2">
          <br />
          <p>
            The Sparrow User Pledge does not cover Property Damage, Bodily Injury, Theft, or other losses or damages arising from or in any way
            related to any of the following (the “Excluded Losses”):
          </p>
          <br />
          <ul>
            <li>Losses arising from operation of any motor vehicle, bicycle, aircraft or watercraft by a User;</li>
            <li>Losses arising out of any intellectual property claim;</li>
            <li>
              Losses arising out of interruption of business, loss of market, loss of income and/or loss of use, unemployment compensation, losses
              associated with the unauthorized access to Electronic Data, or any other indirect, consequential or special damages;
            </li>
            <li>
              Losses for Property Damage exceeding the original value or replacement value (whichever is the lesser), less any standard depreciation;
            </li>
            <li>Losses to Ineligible Property;</li>
            <li>Client losses arising from Negligence of a Client or third party, or from Client’s lack of supervision of the task;</li>
            <li>Student losses arising from Negligence of a Student or third party;</li>
            <li>
              Losses arising from a manufacturer’s or a product’s defects, or from pre-existing damages or conditions of the item or property, normal
              wear and tear, or deterioration;
            </li>
            <li>
              Losses arising from items (including but not limited to tools) supplied by the Client, or resulting from Client’s recommendations or
              instructions (i.e. if a manufacturer recommends affixing furniture to a wall and a Client declines to have furniture affixed, or Client
              directs Student to perform the Task in a manner that results in damages);
            </li>
            <li>Losses that are a normal part of or natural result of the Task undertaken;</li>
            <li>
              Losses arising from intentional acts, including but not limited to: (i) assault and battery, (ii) sexual abuse or molestation, (iii)
              identity theft or fraud;
            </li>
            <li>
              Losses directly or indirectly arising out of flooding or water damage, or relating to mildew, mold, fungi, spores, or other bacteria or
              microorganisms of any type, nature or description, including but not limited to any substance whose presence poses an actual or
              potential threat to human health;
            </li>
            <li>
              Losses associated with a User sharing, providing access to, or otherwise providing the User’s Electronic Data and/or account information
              to another User or a third party, including but not limited to losses resulting from phishing scams and account takeover.
            </li>
            <li>
              Losses beyond the specific damaged area (i.e. if the Task caused a scratch in the floor, the User Pledge will address repairs to the
              scratched area only; likewise, for Property Damage that is cosmetic in nature and does not impact the usability of the item, the User
              Pledge will address repairs to the damage only);
            </li>
            <li>Losses arising from products containing or causing hazardous or harmful materials, communicable diseases, or pollution;</li>
            <li>Displacement costs, including hotel and other accommodations;</li>
            <li>
              Losses arising out of acts of nature, including but not limited to: pollution, earthquakes, and weather-related events such as rain,
              wind, etc.;
            </li>
            <li>Losses arising from acts of terrorism;</li>
            <li>Losses resulting from cancellation by a Student, including increased costs associated with re-booking a or hiring a third party;</li>
            <li>Losses resulting from Theft without a valid police report;</li>
            <li>Losses reported more than 14 days after the Task took place; and</li>
            <li>Losses with insufficient documentation.</li>
          </ul>
        </div>

        <h4>The User Pledge is not insurance</h4>
        <div className="ml-1 mb-2">
          <br />
          <p>
            You understand and agree that the Sparrow User Pledge is not insurance, and that no User or third party is an insured or third party
            beneficiary.
          </p>
          <br />
          <p>
            Sparrow has the right to subrogate against any person or entity who may be responsible for causing the Losses giving rise to a Claim,
            including Users or any third party. By making a Claim under this User Pledge you agree you will assist in, and cooperate fully with, any
            actions taken by Sparrow or its agents to subrogate a Claim.
          </p>
          <br />
          <p>
            If you carry insurance that would cover you in the event of a Claim, including but not limited to renter’s insurance, homeowner’s
            insurance, medical insurance, or an umbrella policy (“Personal Insurance”), you must seek compensation for Losses from your Personal
            Insurance, prior to seeking compensation by way of the User Pledge. The User Pledge will only compensate for Losses to the extent not
            otherwise covered by your Personal Insurance. Sparrow has the right to provide a User’s contact information to an insurance company or to
            another User in order to facilitate a resolution of a Claim.
          </p>
        </div>
        <h4>How do I submit a User Pledge Claim?</h4>
        <div className="ml-1 mb-2">
          <br />
          <p>
            To submit a Claim, please send a request to our contact email&nbsp;within fourteen (14) days of the performance of the Task giving rise to
            the Claim. Before contacting Sparrow, you must first make a good faith attempt to resolve the issue directly with the User(s) involved.
            Payment requests are subject to the terms and conditions noted herein. All Claims will be reviewed on a case-by-case basis.
          </p>
          <br />
          <p>
            During our claims assessment process, you agree to
            <br />
            <ol type="i">
              <li>protect and preserve any damaged property that is the basis of a Claim from further damage;</li>
              <li>assist or allow Sparrow or its agents access to make copies, photographs and recordings of anything relating to the Claim;</li>
              <li>allow Sparrow or its insurers access to inspect anything relating to the Claim</li>
              <li>accept repairs by a Student first; and</li>
              <li>submit requested materials by the dates outlined by the Sparrow Support or Resolutions Teams.</li>
            </ol>
          </p>
          <br />
          <p>If any part of your Claim is approved, then as a condition to any payment to you under the Sparrow User Pledge, you agree:</p>
          <br />
          <ul>
            <li>
              to release and hold harmless Sparrow and Affiliates from any further liability or obligations with respect to the facts and
              circumstances of the matters set forth in the Claim;
            </li>
            <li>to treat as confidential the resolution of your Claim, as well as any associated Resolutions Agreement;</li>
            <li>
              to refund to Sparrow any amounts that Sparrow determines to have been erroneously paid to you with respect to an approved Claim; and
            </li>
            <li>to execute and deliver to Sparrow the “Sparrow Resolutions Agreement” upon request.</li>
          </ul>
        </div>
        <h4>Miscellaneous</h4>
        <div className="ml-1 mb-2">
          <br />
          <p>
            No person, entity or agent, including any User or any Sparrow employee, may alter the terms of this user Pledge. Users may not assign or
            transfer the terms of the User Pledge, and no party shall be a third party beneficiary of these terms. The terms of this User Pledge are
            not intended to and shall not be construed to give any person or entity other than a User of the Sparrow platform any interest or rights
            (including, without limitation, any third party beneficiary rights) with respect to or in connection with any agreement or provision
            contained herein or contemplated hereby. The Sparrow user Pledge shall be governed by the laws of the State of California without regard
            to its conflict of law provisions. Sparrow reserves the right, in its sole and absolute discretion, to eliminate the User Pledge
            altogether or change, modify or delete any of the terms of this User Pledge at any time, effective with or without prior notice and
            without any liability to Sparrow. Sparrow will endeavor to notify you of material changes by email, but will not be liable for any failure
            to do so. If any future changes to this User Pledge are unacceptable to you, you must deactivate, and immediately stop using, the Sparrow
            Platform. Your continued use of the Sparrow Platform following any revision to this User Pledge constitutes your complete and irrevocable
            acceptance of any and all such changes.
          </p>
        </div>
        <h4>Defined Terms</h4>
        <div className="ml-1 mb-2">
          <br />
          <p>
            "Bodily Injury" means physical injury, sickness or disease sustained by any person, including but not limited to death resulting from any
            of these.
          </p>
          <br />
          <p>
            “Electronic Data” means data or information stored electronically or in digital format including data, information, audio, video, files,
            databases, software, systems, applications, tapes, drives, cloud storage or data processing devices.
          </p>
          <br />
          <p>
            "User Pledge Claim” or “Claim” means a dispute resulting in Property Damage, Bodily Injury or Theft which is submitted via the contact
            email&nbsp;pursuant to the terms and conditions set forth herein.
          </p>
          <br />
          <p>
            “Ineligible Property” means cash or other currency, animals, fine art (paintings, photos, pictures, textiles, sculptures or other mediums
            of art, antiques, precious stones or metals and similar property of rare or historical value); Electronic Data; damage to common areas;
            and/or items of sentimental value like heirlooms or photographs beyond the straight replacement value.
          </p>
          <br />
          <p>"Losses" means any Property Damage, Bodily Injury or Theft other than Excluded Losses.</p>
          <br />
          <p>
            “Negligence” means a finding by Sparrow, in its sole discretion, that a User’s action or omission
            <ol type="a">
              <li>breached a duty to other Users during the performance of a Task and</li>
              <li>was the proximate cause of that User’s actual damages.</li>
            </ol>
          </p>
          <br />
          <p>"Property Damage" means physical damage to, loss, or destruction of tangible property belonging to a User.</p>
          <br />
          <p>
            "Theft" means the intentional and fraudulent taking by one User of another User’s tangible personal property without permission or consent
            of the owner, with intent to convert it to another’s use.
          </p>
          <h6>{navigator.userAgent}</h6>
        </div>
      </Container>
    </div>
  );
};

export default SparrowUserPledge;
