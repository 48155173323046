import axios from "../axios";

export const isInvited = async (body) => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("sparrowSignIn"))?.token
      }`,
    },
  };
  try {
    const response = await axios.post("/task/student", body, config);
    return response;
  } catch (err) {
    return err.response;
  }
};
