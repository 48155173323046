import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getMessaging, onMessage } from 'firebase/messaging';

export const firebaseConfig = {
  apiKey: "AIzaSyBwh3l5ddP_hrfwL0dEX1VfSZSZw4dlepI",
  authDomain: "spaarrow-18396.firebaseapp.com",
  projectId: "spaarrow-18396",
  storageBucket: "spaarrow-18396.appspot.com",
  messagingSenderId: "633417783045",
  appId: "1:633417783045:web:631953acb9d0769808c96c",
  measurementId: "G-B6NTQYEY18"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app)

// notifiactions
let messaging;

// Check if the user agent does not match "FBIOS" or "FBAN" AND does not match "iPhone"
if (
  !navigator.userAgent.match(/(FBIOS|FBAN|FBAV)/) && // Matches Facebook iOS app
  !navigator.userAgent.match(/Instagram/) && // Matches Instagram app
  !navigator.userAgent.match(/(FB_IAB|Instagram)/) && // Matches Facebook and Instagram in-app browsers
  !navigator.userAgent.match(/(FBForIPhone|FBDV)/) &&
  !navigator.userAgent.match(/iPhone/) // Matches additional Facebook patterns
) {
  messaging = getMessaging();
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    // Only set up the message listener if messaging is defined
    if (messaging) {
      onMessage(messaging, (payload) => {
        console.log("payload", payload);
        resolve(payload);
      });
    } else {
      // If messaging is not defined, resolve immediately with a null payload
      resolve(null);
    }
  });