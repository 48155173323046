import React, { useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { days, timeSlots } from "../../services/availibility&timeslots/Availibility";
import { getLocations, getLocations100Random } from "../../services/locations/Locations";
import { createTaskAvalability } from "../../services/taskavailability/CreateTaskAvalability";
import { createTask } from "../../services/tasks/CreateTasks";
// import FooterNav from '../mobilefooternav/FooterNav';
import Select from "react-select";
import AsyncSelect from "react-select/async";
// import { v4 as uuidv4 } from 'uuid';
import { createOrder } from "../../services/order/CreateOrder";
import { createNotification } from "../../services/notifications/CreateNotification";
// import { sendNotification } from '../../services/notifications/SendNotification'
// import { addDoc, collection, getDocs, query, where, Timestamp, getFirestore } from 'firebase/firestore'
// import { initializeApp } from 'firebase/app'
// import { firebaseConfig } from '../../firebase/FireBase'
// import { createChatRoom } from './customerinvitecards/CreatingChatRoom'
import { inviteStudent } from "../../services/invitestudents/InviteStudent";
import AuthContext from "../../context/AuthContext";
import { sendEmail } from "../../services/contactus/ContactUs";
import { useSingleUserInfo } from "../hooks/getSingleUser";
import ReactPixel from "react-facebook-pixel";
import { emailUrl } from "../../utils/constants";
import Loader from "../loader/Loader";
import moment from "moment";
import { createOrderDraft } from "../../services/order/CreateOrderDraft";
import { sendLocalityEmails } from "../../services/order/SendLocalityEmail";

const CustomerCreateTask = () => {
  let { studentId, fcmToken } = useParams();

  // firebase
  // const appNew = initializeApp(firebaseConfig);
  // const dbNew = getFirestore(appNew);

  const userInfo = useSingleUserInfo(studentId);

  let navigate = useNavigate();
  const [loder, setLoder] = useState(true);
  // clientId
  let client_id = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.id;
  let client_Name = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.first_name;
  if (client_Name) {
    client_Name = client_Name.charAt(0).toUpperCase() + client_Name.slice(1);
  }
  let client_Email = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.email;

  //select  recurring or not 0 for not 1 for recuring
  const [taskType, setTaskType] = useState("0");
  const [showEndDate, setShowEndDate] = useState(false);

  const selectGender = (e) => {
    setTaskType(e.target.value);

    if (e.target.value == 1) {
      setShowEndDate(true);
    } else {
      setShowEndDate(false);
    }
  };
  // useEffect for updating button values of gender
  useEffect(() => {}, [taskType]);

  // get location to show first
  const [randomLocation, setRandomLocation] = useState([]);
  const GetlocationsRamdomFunc = async () => {
    // debugger

    const optionlocation = [];
    const resp = await getLocations100Random();
    resp?.data?.map((singleLocation) => {
      return optionlocation.push({
        label: singleLocation.city + " " + singleLocation.state + "," + singleLocation.zip,
        value: singleLocation.id,
      });
    });
    setRandomLocation(optionlocation);
  };

  const taskCategoriesOptions = [
    { label: "Child Care", value: "Child Care", isParent: true },
    { label: "Tutoring", value: "Tutoring", isParent: true },
    // { label: "Business", value: "Business", isParent: false },
    // { label: "Chemistry", value: "Chemistry", isParent: false },
    // { label: "Chinese", value: "Chinese", isParent: false },
    // { label: "English", value: "English", isParent: false },
    // { label: "French", value: "French", isParent: false },
    // { label: "Geography", value: "Geography", isParent: false },
    // { label: "German", value: "German", isParent: false },
    // { label: "History", value: "History", isParent: false },
    // { label: "Math", value: "Math", isParent: false },
    // { label: "Science", value: "Science", isParent: false },
    // { label: "Social Studies", value: "Social Studies", isParent: false },
    // {
    //   label: "Religious Education",
    //   value: "Religious Education",
    //   isParent: false,
    // },
    { label: "Pet Sitting", value: "Pet Sitting", isParent: true },
    { label: "Yard Work", value: "Yard Work", isParent: true },
    { label: "Grocery Delivery", value: "Grocery Delivery", isParent: true },
    { label: "Housekeeping", value: "Housekeeping", isParent: true },
    { label: "Elder Care", value: "Elder Care", isParent: true },
    { label: "Window Cleaning", value: "Window Cleaning", isParent: true },
    { label: "Moving", value: "Moving", isParent: true },
    { label: "Sports Coaching", value: "Sports Coaching", isParent: true },
    // { label: "Baseball", value: "Baseball", isParent: false },
    // { label: "Basketball", value: "Basketball", isParent: false },
    // { label: "Boxing Football", value: "Boxing Football", isParent: false },
    // { label: "Golf", value: "Golf", isParent: false },
    // { label: "Soccer", value: "Soccer", isParent: false },
    // { label: "Softball", value: "Softball", isParent: false },
    // { label: "Swimming", value: "Swimming", isParent: false },
    // { label: "Tennis", value: "Tennis", isParent: false },
    // { label: "Track & Field", value: "Track & Field", isParent: false },
    // { label: "Volleyball", value: "Volleyball", isParent: false },
    // { label: "Water Polo", value: "Water Polo", isParent: false },
    // { label: "Wrestling", value: "Wrestling", isParent: false },
    { label: "Other", value: "Other", isParent: true },
  ];

  useEffect(() => {
    GetlocationsRamdomFunc();
    getDaysFunc();
    getTimeFunc();
    ReactPixel.track("Create Task");
  }, []);

  const auth = useContext(AuthContext);
  // get locations
  // const [locations, setlocations] = useState([])
  // const locationsFunc = async () => {
  //     const resp = await getLocations();
  //     setlocations(resp.data)
  // }

  // get working days
  const [workdays, setworkdays] = useState();
  const getDaysFunc = async () => {
    const response = await days();

    if (response.status === 200) {
      if (response?.data?.length > 0) {
        let updatedArray = [];

        let order = {
          monday: 1,
          tuesday: 2,
          wednesday: 3,
          thursday: 4,
          friday: 5,
          saturday: 6,
          sunday: 7,
        };

        updatedArray = response?.data.sort(function (a, b) {
          return order[a.day] - order[b.day];
        });
        setworkdays(updatedArray);
      }
    } else {
      Swal.fire({
        title: response.data.message,
        timer: 1500,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };

  // get timing slots
  const [timeSlot, setTimeSlot] = useState();

  const getTimeFunc = async () => {
    const response1 = await timeSlots();

    if (response1.status === 200) {
      setTimeSlot(response1.data);
      setLoder(false);
    } else {
      setLoder(false);
      Swal.fire({
        title: response1.data.message,
        timer: 1500,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };
  // adding time in days
  // const [start, setStart] = useState();
  // const [end, setEnd] = useState();
  // adding time in belw array
  // const [timeArray, setTimeArray] = useState([]);
  // const defaultSelectValue = { label: "Select ...", value: "" };

  const [userSlots, setuserSlots] = useState([]);
  // const [userSlots, setUserSlots] = useState([]);

  const makeArray = (day_id, time_id, startEndTime) => {
    const copyUserState = [...userSlots];

    // Check if a slot with the same day_id already exists
    const existingSlot = copyUserState.find((slot) => slot.day_id === day_id);

    if (existingSlot) {
      // Check if the selected time_id is the same as the existing time_id
      if (
        (startEndTime === "start" && existingSlot.end_time_slot_id === time_id) ||
        (startEndTime === "end" && existingSlot.start_time_slot_id === time_id)
      ) {
        // Remove the selected time_id from the existing slot
        if (startEndTime === "start") {
          existingSlot.start_time_slot_id = null;
        } else {
          existingSlot.end_time_slot_id = null;
        }
      } else {
        // Update the existing slot with the start_time_slot_id or end_time_slot_id
        if (startEndTime === "start") {
          existingSlot.start_time_slot_id = time_id;
        } else {
          existingSlot.end_time_slot_id = time_id;
        }
      }

      // Check if both start and end times are selected for the same day
      if (existingSlot.start_time_slot_id !== null && existingSlot.end_time_slot_id !== null) {
        // Find the indices of start and end times in the timeSlot array
        console.log(timeSlot);
        console.log(existingSlot);
        const startTimeIndex = timeSlot.findIndex((slot) => slot.id === existingSlot.start_time_slot_id);
        const endTimeIndex = timeSlot.findIndex((slot) => slot.id === existingSlot.end_time_slot_id);
        // Ensure end time is greater than start time
        if (startTimeIndex >= endTimeIndex) {
          // Reset the slot if end time is not greater than start time
          // existingSlot.start_time_slot_id = null;
          existingSlot.end_time_slot_id = null;
          // You can also display an error message or handle the situation as required
          Swal.fire({
            title: "Please add correct time slot",
            text: "The end time must be later than the start time.",
            timer: 2000,
            icon: "error",
            showConfirmButton: false,
          });
        }
      }
    } else {
      const newSlot = {
        day_id: day_id,
        start_time_slot_id: startEndTime === "start" ? time_id : null,
        end_time_slot_id: startEndTime === "end" ? time_id : null,
      };
      copyUserState.push(newSlot);
    }
    setuserSlots(copyUserState);
  };

  // getting timing accordingly
  const searchtimeID = (nameKey, myArray) => {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].id == nameKey) {
        return myArray[i].slot;
      }
    }
  };
  // remove from array
  const removeValueFromArray = (slotToRemove) => {
    setuserSlots((prevUserSlots) => {
      // Use filter to remove the slot with the specified day_id and time_slot_id
      const filteredSlots = prevUserSlots.filter(
        (slot) => !(slot.day_id === slotToRemove.day_id && slot.start_time_slot_id === slotToRemove.start_time_slot_id)
      );
      return filteredSlots;
    });
  };

  const isStartSelected = (item) => {
    const existingSlot = userSlots.find((slot) => slot.day_id === item.id);
    return existingSlot && existingSlot.start_time_slot_id !== null;
  };

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    menu: (styles) => ({ ...styles, backgroundColor: "white", zIndex: 999 }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled ? "#cacaca" : isSelected ? "#82D051" : isFocused ? undefined : undefined,
        color: isDisabled ? "#cacaca" : isSelected ? "#fcfcfc" : isFocused ? "#898A8D" : "#898A8D",
        cursor: isDisabled ? "not-allowed" : "default",
        ":active": {
          ...styles[":active"],
          backgroundColor: "#82D051",
        },
      };
    },
    input: (styles) => ({
      ...styles,
      color: "#898A8D",
      fontFamily: "'Manrope', sans-serif",
      fontWeight: 400,
    }),
    placeholder: (styles) => ({
      ...styles,
      color: "#898a8d",
      fontFamily: "'Manrope', sans-serif",
      fontWeight: 400,
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#898A8D",
      fontFamily: "'Manrope', sans-serif",
      fontWeight: 400,
    }),
  };

  const colourStyles_location = {
    // control: (styles) => ({ ...styles, backgroundColor: "#F8F8F8", border: "none" }),
    menu: (styles) => ({ ...styles, backgroundColor: "white", zIndex: 999 }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled ? "#cacaca" : isSelected ? "#82D051" : isFocused ? undefined : undefined,
        color: isDisabled ? "#cacaca" : isSelected ? "#fcfcfc" : isFocused ? "#898A8D" : "#898A8D",
        cursor: isDisabled ? "not-allowed" : "default",
        ":active": {
          ...styles[":active"],
          backgroundColor: "#82D051",
        },
      };
    },
    singleValue: (styles) => ({
      ...styles,
      color: "#898a8de6",
      fontFamily: "Poppins",
      fontWeight: 400,
    }),
  };

  const colourStyles_task = {
    menu: (styles) => ({ ...styles, backgroundColor: "white", zIndex: 999 }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
      ...styles,
      paddingLeft: data.isParent ? "5%" : "15%", // Add padding for child options
      color: data.isParent ? "#898A8D" : "#82D051", // Change color based on whether it's a parent or child
      cursor: "default",
      backgroundColor: isDisabled ? "#cacaca" : isSelected ? "#82D051" : isFocused ? undefined : undefined,
      color: isDisabled ? "#cacaca" : isSelected ? "#fcfcfc" : isFocused ? "#898A8D" : "#898A8D",
      cursor: isDisabled ? "not-allowed" : "default",
      ":active": {
        ...styles[":active"],
        backgroundColor: "#82D051",
      },
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#898a8de6",
      fontFamily: "Poppins",
      fontWeight: 400,
    }),
  };

  const optionTime = [];
  if (timeSlot?.length > 0) {
    timeSlot?.map((singleTime) => {
      return optionTime.push({
        label: singleTime?.slot,
        value: singleTime.id,
      });
    });
  }
  // console.log(optionTime);

  const filterLocationOption = (inputValue) => {
    if (inputValue.length > 2) {
      return auth.locations?.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
    } else {
      let result = auth.locations.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
      return result.splice(0, 80);
    }
  };

  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterLocationOption(inputValue));
    }, 1000);
  };

  // api function
  // isMobile state can be remove
  const [isMobile, setIsMobile] = useState("taskField wd50 Clender w-100 setClender mobileFields");
  const [isMobileEndDate, setIsMobileEndDate] = useState("taskField wd50 Clender w-100 setClender mobileFields");

  function verifyDate(date, secondVal) {
    // debugger
    if (date !== "" && secondVal === 1) {
      setIsMobile("taskField wd50 Clender w-100 setClender");
      var today = new Date();
      today.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0 for today's date

      if (new Date(date).getTime() < today.getTime()) {
        return "";
      }
      return date;
    } else if (date !== "" && secondVal === 2) {
      setIsMobileEndDate("taskField wd50 Clender w-100 setClender");
      var today1 = new Date();
      today1.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0 for today's date

      if (new Date(date).getTime() < today1.getTime()) {
        return "";
      }
      return date;
    } else if (date === "" && secondVal === 1) {
      setIsMobile("taskField wd50 Clender w-100 setClender mobileFields");
    } else {
      setIsMobileEndDate("taskField wd50 Clender w-100 setClender mobileFields");
    }
  }

  const [btnLoder, setBtnLoder] = useState(false);
  // getting coming sunday
  function add7DaysToInputDate(inputDate) {
    const currentDate = moment(inputDate, "YYYY-MM-DD");
    const nextDate = currentDate.add(7, "days");
    return nextDate.format("YYYY-MM-DD");
  }

  // one time job
  const [startId, setStartId] = useState(null);
  const [endId, setEndId] = useState(null);
  const [selectedArray, setSelectedArray] = useState([]);
  // console.log(selectedArray);
  const handleStartChange = (e) => {
    setStartId(e.value);
  };

  const handleEndChange = (e) => {
    const newEndId = e.value;
    // Check if the new end ID is different from the current end ID
    if (newEndId !== endId) {
      if (startId !== null && newEndId !== startId) {
        const startTimeIndex = timeSlot.findIndex((slot) => slot.id === startId);
        const endTimeIndex = timeSlot.findIndex((slot) => slot.id === newEndId);
        if (startTimeIndex < endTimeIndex) {
          // Start time is before end time, update selectedArray
          const newArray = createArray(startId, newEndId);
          setSelectedArray(newArray);
        } else {
          // Start time is not before end time, show error or handle accordingly
          // For now, clear selectedArray
          setSelectedArray([]);
          // Show error
          Swal.fire({
            title: "Please add correct time slot",
            timer: 2000,
            icon: "error",
            showConfirmButton: false,
          });
        }
      }
      setEndId(newEndId);
    } else {
      // If the new end ID is the same as the current end ID, clear the array and reset endId
      setSelectedArray([]);
      setEndId(null);
    }
  };

  const createArray = (startId, endId) => {
    return [
      {
        start_time_slot_id: startId,
        end_time_slot_id: endId,
      },
    ];
  };

  const issStartSelected = startId !== null;
  const removeValueFromArrayy = (slotToRemove) => {
    const updatedSlots = selectedArray.filter((slot) => {
      // Compare the slotToRemove with each slot in the array
      // and return only those that do not match the slotToRemove
      return slot.start_time_slot_id !== slotToRemove.start_time_slot_id || slot.end_time_slot_id !== slotToRemove.end_time_slot_id;
    });
    setSelectedArray(updatedSlots);
  };
  //
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (fData) => {
    setBtnLoder(true);
    // single job
    let day_id;
    const dayName = moment(fData.date).format("dddd");
    // other data
    const data = {
      user_id: client_id,
      location: parseInt(fData.location.value),
      title: fData.task,
      type: parseInt(taskType),
      hourly_rate: parseInt(fData.payRate),
      description: fData.description,
      start_date: fData.date,
      task_category: fData.task_category.value,
    };
    if (showEndDate) {
      data["end_date"] = fData.EndDate;
    }
    if (!showEndDate) {
      data["end_date"] = fData.date;
    }
    // if (!showEndDate) {
    //   data["end_date"] = add7DaysToInputDate(fData.date);
    //   // for one time job day id select
    //   switch (dayName) {
    //     case 'Sunday':
    //       day_id = 1;
    //       break;
    //     case 'Monday':
    //       day_id = 2;
    //       break;
    //     case 'Tuesday':
    //       day_id = 3;
    //       break;
    //     case 'Wednesday':
    //       day_id = 4;
    //       break;
    //     case 'Thursday':
    //       day_id = 5;
    //       break;
    //     case 'Friday':
    //       day_id = 6;
    //       break;
    //     case 'Saturday':
    //       day_id = 7;
    //       break;
    //     default:
    //       day_id = null; // Handle the case if the day name doesn't match any of the expected values
    //   }
    //   // for one time job creating final timeslot
    //   if (selectedArray.length > 0) {
    //     selectedArray[0]["day_id"] = day_id;
    //   }
    // }

    // console.log(timeSlotsForSingleJob);

    const resp = await createTask(data);
    if (resp.status === 200) {
      // debugger

      const idOfTask = resp?.data?.task?.id;
      // adding task id in timeArray
      if (showEndDate) {
        userSlots.forEach((object) => {
          object.task_id = idOfTask;
        });
      } else if (selectedArray.length > 0) {
        switch (dayName) {
          case "Sunday":
            day_id = 1;
            break;
          case "Monday":
            day_id = 2;
            break;
          case "Tuesday":
            day_id = 3;
            break;
          case "Wednesday":
            day_id = 4;
            break;
          case "Thursday":
            day_id = 5;
            break;
          case "Friday":
            day_id = 6;
            break;
          case "Saturday":
            day_id = 7;
            break;
          default:
            day_id = null; // Handle the case if the day name doesn't match any of the expected values
        }
        selectedArray[0]["task_id"] = idOfTask;
        selectedArray[0]["day_id"] = day_id;
      } else {
        Swal.fire({
          title: "Please add timeslots",
          timer: 3500,
          icon: "error",
          showConfirmButton: false,
        });
        setBtnLoder(false);
      }
      // again api hit
      if (userSlots.length > 0 || selectedArray.length > 0) {
        let resp1;
        if (userSlots.length > 0) {
          resp1 = await createTaskAvalability(userSlots);
        } else {
          resp1 = await createTaskAvalability(selectedArray);
        }

        if (resp1.status === 200) {
          Swal.fire({
            title: "Task Created Successfully!",
            timer: 1500,
            icon: "success",
            showConfirmButton: false,
          });
          // defining variables
          let respOfOrder;
          // debugger
          // creating order
          if (studentId !== "null") {
            const data = {
              task_id: idOfTask,
              // student_id: parseInt(studentId),
              client_id: client_id,
              status: 0,
              tip: null,
              hours_billed: null,
            };
            respOfOrder = await createOrderDraft(data);
            // creating notifiaction for All notififcation tab
            if (respOfOrder.status === 200) {
              const data1 = {
                // as reqirement by backend type is 0
                type: 0,
                to_id: parseInt(studentId),
                from_id: client_id,
                data: {
                  title: "Task recieved",
                  message: `You have received a task request from ${client_Name}. `,
                  action_url: `/dashboardRoute/task-history/student-task-descp/${idOfTask}/${respOfOrder?.data?.order?.id}`,
                },
                read_at: null,
              };
              await createNotification(data1);
              // send notification
              // const data = {
              //     fcmToken: fcmToken,
              //     title: `Order Recieved from ${client_Name}`,
              //     body: `Order Recieved from ${client_Name}`
              // }
              // const respOfNotification = await sendNotification(data)

              // creating chatRoom and sending notiifcaiton in chat
              // let std_String = studentId.toString()
              // createChatRoom(std_String, Client_string, idOfTask, respOfOrder?.data?.order?.id)

              // status

              // sending email
              const dataForEmail = {
                to: userInfo?.email,
                subject: fData.task,
                from: client_Email,
                first_name: userInfo?.first_name,
                client_name: client_Name,
                // last_name: userInfo?.last_name,
                msg: fData.description,
                action_url: `${emailUrl}/#/dashboardRoute/task-history/student-task-descp/${idOfTask}/${respOfOrder?.data?.order?.id}`,
                template: "student_invited",
              };
              await sendEmail(dataForEmail);
              // creating invite students number for record
              const data = {
                task_id: idOfTask,
                client_id: client_id,
                student_id: parseInt(studentId),
              };
              const response = await inviteStudent(data);
              if (response.status === 200) {
                setTimeout(() => {
                  Swal.fire({
                    title: "Email Sent",
                    timer: 1500,
                    icon: "success",
                    showConfirmButton: false,
                  });
                }, 1500);
                let localityEmailData = {
                  task_id: idOfTask,
                  order_id: respOfOrder?.data?.order?.id,
                  action_url: `${emailUrl}/#/dashboardRoute/task-history/student-task-descp/${idOfTask}/${respOfOrder?.data?.order?.id}`,
                };

                // await sendLocalityEmails(localityEmailData);
                setTimeout(() => {
                  navigate(`/dashboardRoute/all-students/invite-students/${idOfTask}/${respOfOrder?.data?.order?.id}`);
                }, 2700);
                // }, 4200);
              }
              // }
            }
          } else {
            const data = {
              task_id: idOfTask,
              // student_id: parseInt(studentId),
              client_id: client_id,
              status: 0,
              tip: null,
              hours_billed: null,
            };
            respOfOrder = await createOrderDraft(data);

            let localityEmailData = {
              task_id: idOfTask,
              order_id: respOfOrder?.data?.order?.id,
              action_url: `${emailUrl}/#/dashboardRoute/task-history/student-task-descp/${idOfTask}/${respOfOrder?.data?.order?.id}`,
            };

            // await sendLocalityEmails(localityEmailData);
            setTimeout(() => {
              navigate(`/dashboardRoute/all-students/invite-students/${idOfTask}/${respOfOrder?.data?.order?.id}`);
            }, 1600);
            return;
          }
        } else {
          Swal.fire({
            title: resp1.data.message,
            timer: 3500,
            icon: "error",
            showConfirmButton: false,
          });
          setBtnLoder(false);
        }
      } else {
        Swal.fire({
          title: "Please add timeslots",
          timer: 3500,
          icon: "error",
          showConfirmButton: false,
        });
        setBtnLoder(false);
      }
    } else {
      Swal.fire({
        title: resp.data.message,
        timer: 3500,
        icon: "error",
        showConfirmButton: false,
      });
      setBtnLoder(false);
    }
  };

  return (
    <div>
      {loder ? (
        <div style={{ height: "100vh" }} className="height100vh">
          <Loader />
        </div>
      ) : (
        <div className="customerCreateTask customerContainer">
          <h2 className="mt78 textCenter">
            Create Your <span className="green">Task</span>
          </h2>
          <div className="taskForm">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb24">
                <p className="mb-2 tasksHed">Task</p>
                <div>
                  <input
                    placeholder="E.g. Babysitting"
                    className="taskField respFontOnly"
                    type="text"
                    maxLength={20}
                    {...register("task", { required: true })}
                  />
                </div>
                {errors.task && <span className="eror">This field is required</span>}
              </div>
              <div className="wd100 locationDivMobile">
                <p className="mb-2 tasksHed">Task Category</p>
                <div style={{ marginBottom: "4%" }}>
                  <Controller
                    name="task_category"
                    control={control}
                    {...register("task_category", { required: true })}
                    render={({ field }) => (
                      <AsyncSelect
                        {...field}
                        className="StepOneSelect createTaskLocation font15"
                        styles={colourStyles_task}
                        cacheOptions
                        defaultOptions={taskCategoriesOptions}
                        placeholder="Select Task Category"
                      />
                    )}
                  />
                </div>
                {errors.task_category && <span className="eror">This field is required</span>}
              </div>
              <div className="mb24">
                <p className="mb-2 tasksHed">Time Frame</p>
                <div className="qParts">
                  <div className="pOptions d-flex">
                    <div className="selectGEndrDiv jobSelection">
                      <input type="radio" value="0" onChange={selectGender} id="radioMale" checked={taskType === "0"} />
                      <label htmlFor="radioMale" className="gender-mb setColor oneTimeJob mb-recuringJobLabel">
                        One-time job
                        <span className="hoverTextForOneTime">
                          Choose the days in the week that you want our student to help. The task will end after the last day in the week.
                        </span>
                      </label>
                    </div>
                    <div className="selectGEndrDiv jobSelection">
                      <input type="radio" value="1" onChange={selectGender} id="radioFemale" checked={taskType === "1"} />
                      <label htmlFor="radioFemale" className="gender-mb setColor recuringJobLabel mb-recuringJobLabel">
                        Recurring job
                        <span className="hoverTextForRecurring">
                          Choose the days in the week that you want our student to help. The task will reoccur every week until the end date is
                          reached.
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb24 d-flex justify-content-between gap-3">
                <div className="mb-createtask-40 w-50">
                  <p className="mb-2 tasksHed">Start Date</p>
                  <div className="w-100">
                    <input
                      className={isMobile}
                      type="date"
                      {...register("date", {
                        required: true,
                        setValueAs: (v) => verifyDate(v, 1),
                      })}
                    />
                  </div>
                  {/* <h1>trs</h1> */}
                  {errors.date &&
                    (errors.date?.ref?.value ? (
                      <span className="eror">Date must be greater or equal to today.</span>
                    ) : (
                      <span className="eror">This field is required.</span>
                    ))}
                </div>
                {showEndDate && (
                  <div className="mb-createtask-40 w-50">
                    <p className="mb-2 tasksHed">End Date</p>
                    <div className="w-100">
                      <input
                        className={isMobileEndDate}
                        type="date"
                        {...register("EndDate", {
                          required: true,
                          setValueAs: (v) => verifyDate(v, 2),
                        })}
                      />
                    </div>
                    {errors.EndDate &&
                      (errors.EndDate?.ref?.value ? (
                        <span className="eror">Date must be greater or equal to today.</span>
                      ) : (
                        <span className="eror">This field is required.</span>
                      ))}
                  </div>
                )}
              </div>

              {/* showing mutliple days */}
              {!showEndDate ? (
                <>
                  <p className="mb-2 tasksHed">Time</p>
                  <div>
                    <Select
                      className="timingInput_select mb-time-1 d-inline-block"
                      options={optionTime}
                      styles={colourStyles}
                      placeholder="Start ..."
                      onChange={handleStartChange}
                    />

                    <span className="d-inline-block timeBetweenStartEnd px-2">-</span>

                    <Select
                      className="timingInput_select mb-time-1 d-inline-block"
                      options={optionTime}
                      styles={colourStyles}
                      placeholder="End ..."
                      onChange={handleEndChange}
                      isDisabled={!issStartSelected}
                    />
                  </div>
                  <div className="timingBox padding0">
                    <div className="daysSpace px-3 text-center">Est. Time</div>
                    <div className="dayTImes taskTimeSlot">
                      {selectedArray.map((parentObjArray, index) => {
                        const startValue = searchtimeID(parentObjArray.start_time_slot_id, timeSlot);
                        const endValue = searchtimeID(parentObjArray.end_time_slot_id, timeSlot);

                        // Only render if both start and end values are selected
                        if (startValue && endValue) {
                          return (
                            <div key={index} className="timeSlotPills taskTimePills">
                              {startValue} - {endValue}
                              <span
                                className="removeTimeIcon"
                                style={{
                                  color: "white",
                                  cursor: "pointer",
                                  marginLeft: "7px",
                                }}
                                onClick={() => removeValueFromArrayy(parentObjArray)}
                              >
                                &#x2715;
                              </span>
                            </div>
                          );
                        }

                        return null; // Don't render if either start or end value is missing
                      })}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {workdays?.map((item) => {
                    return (
                      <div className="mb24" key={item?.id}>
                        <p className="mb-2 tasksHed">{item?.day.charAt(0).toUpperCase() + item?.day.slice(1)}</p>
                        <div>
                          <Select
                            className="timingInput_select mb-time-1 d-inline-block"
                            options={optionTime}
                            styles={colourStyles}
                            placeholder="Start ..."
                            onChange={(e) => {
                              makeArray(item?.id, e.value, "start");
                            }}
                          />
                          <span className="d-inline-block timeBetweenStartEnd px-2">-</span>
                          <Select
                            className="timingInput_select mb-time-1 d-inline-block"
                            options={optionTime}
                            styles={colourStyles}
                            placeholder="End ..."
                            onChange={(e) => {
                              makeArray(item?.id, e.value, "end");
                            }}
                            isDisabled={!isStartSelected(item)} // Disable if start time is not selected
                          />
                        </div>
                        <div className="timingBox padding0">
                          <div className="daysSpace px-3 text-center">Est. Time</div>
                          <div className="dayTImes taskTimeSlot">
                            {userSlots
                              .filter((slot) => slot.day_id === item.id)
                              .map((parentObjArray, index) => {
                                const startValue = searchtimeID(parentObjArray.start_time_slot_id, timeSlot);
                                const endValue = searchtimeID(parentObjArray.end_time_slot_id, timeSlot);

                                // Only render if both start and end values are selected
                                if (startValue && endValue) {
                                  return (
                                    <div key={index} className="timeSlotPills taskTimePills">
                                      {startValue} - {endValue}
                                      <span
                                        className="removeTimeIcon"
                                        style={{
                                          color: "white",
                                          cursor: "pointer",
                                          marginLeft: "7px",
                                        }}
                                        onClick={() => removeValueFromArray(parentObjArray)} // Pass the entire slot object to the function
                                      >
                                        &#x2715;
                                      </span>
                                    </div>
                                  );
                                }

                                return null; // Don't render if either start or end value is missing
                              })}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}

              <div className="mb24 d-flex">
                <div className="wd30 hourlyPayMobile">
                  <p className="mb-2 tasksHed">Hourly Pay</p>
                  <div className="DollarSign">
                    <input
                      className="taskField taskHourlyRate respFontOnly"
                      placeholder="00.00"
                      type="number"
                      step="0.01"
                      {...register("payRate", { required: true })}
                    />
                  </div>
                  {errors.payRate && <span className="eror">This field is required</span>}
                </div>
                <div className="wd70 ml-24 locationDivMobile">
                  <p className="mb-2 tasksHed">Location</p>
                  <div>
                    <Controller
                      name="select"
                      control={control}
                      {...register("location", { required: true })}
                      render={({ field }) => (
                        <AsyncSelect
                          {...field}
                          className="StepOneSelect createTaskLocation font15"
                          styles={colourStyles_location}
                          cacheOptions
                          loadOptions={loadOptions}
                          defaultOptions={randomLocation}
                          placeholder="Select Location"
                        />
                      )}
                    />
                  </div>
                  {errors.location && <span className="eror">This field is required</span>}
                </div>
              </div>
              <div className="mb24 mb0">
                <p className="mb-2 tasksHed">Task Description</p>
                <textarea
                  name=""
                  id=""
                  maxLength="450"
                  className="taskField setHeghtMessage"
                  placeholder="Describe what you need help with"
                  {...register("description", { required: true })}
                />
              </div>
              {errors.description && <span className="eror">This field is required</span>}
              <div className="mb24 mt-5" style={{ textAlign: "center" }}>
                {btnLoder ? (
                  <div className="relative crtask" style={{ margin: "auto" }}>
                    <div className="loader alignLoader"></div>
                    <input value="Create Task" className="primary createTaskBtn" />
                  </div>
                ) : (
                  <input type="submit" value="Create Task" className="primary createTaskBtn" />
                )}
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerCreateTask;
