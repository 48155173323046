import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { updateNotification } from "../../services/notifications/UpdateNotifications";
// import FooterNav from '../mobilefooternav/FooterNav'
import ReactPixel from "react-facebook-pixel";
import { handleImageError } from "../../utils/constants";
import Loader from "../loader/Loader";

const CustomerNotifications = () => {
  const auth = useContext(AuthContext);
  const [loder, setLoder] = useState(true);
  const [notifications, setNotifications] = useState([]);

  console.log(notifications);
  const getFunc = () => {
    if (auth.notifications.length > 0) {
      setTimeout(() => {
        setLoder(false);
      }, 1000);
      setNotifications(auth.notifications);
    } else {
      setTimeout(() => {
        setLoder(false);
      }, 1000);
    }
  };

  useEffect(() => {
    ReactPixel.track("Customer Notification");
    getFunc();
  }, [auth]);

  // updating notifications on click of bell icon and removing dot
  useEffect(() => {
    // debugger
    const unreadNotifications = auth?.notifications?.filter((item) => {
      return item.read_at === null;
    });
    if (unreadNotifications?.length > 0) {
      updateNotificationFunc(unreadNotifications);
    }
  }, []);

  function timeDifference(current, previous) {
    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const msPerMonth = msPerDay * 30;
    const msPerYear = msPerDay * 365;

    const elapsed = current - previous;

    if (elapsed < msPerMinute) {
      return Math.round(elapsed / 1000) + " seconds ago";
    } else if (elapsed < msPerHour) {
      return Math.round(elapsed / msPerMinute) + " minutes ago";
    } else if (elapsed < msPerDay) {
      return Math.round(elapsed / msPerHour) + " hours ago";
    } else if (elapsed < msPerMonth) {
      return Math.round(elapsed / msPerDay) + " days ago";
    } else if (elapsed < msPerYear) {
      return Math.round(elapsed / msPerMonth) + " months ago";
    } else {
      return Math.round(elapsed / msPerYear) + " years ago";
    }
  }

  const updateNotificationFunc = async (unreadNotifications) => {
    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let currentDate = `${year}-${month}-${day}`;

    const data = {
      read_at: currentDate,
    };
    // debugger
    // hitting updateApi multiple times until it updates all
    for (const element of unreadNotifications) {
      const resp = await updateNotification(element.id, data);
      if (resp.status === 200) {
        if (document.getElementById("571")) {
          let id = document.getElementById("571");
          id.classList.remove("dot");
          // clearing notifications so dot icon does not show up agian and again
          auth.clearNotifications();
        }
      }
    }
  };

  return (
    <div className="bgNotiLines mb-notification-customer">
      <Container>
        <h2 className="text-center">
          My <span className="green">Notification</span>
        </h2>
        <div style={{ position: "relative" }}>
          <span>
            <img
              className="info6"
              src="/assets/images/newlogos/Info.svg"
              alt=""
            />
            <span className="hoverTextForR6">
              The notifications section is where you'll receive updates on
              students who have accepted the invitation for your task,
              notifications about any cancellations, and reminders for reviewing
              students.
            </span>
          </span>
        </div>
        {loder ? (
          <div className="height100vh">
            <Loader />
          </div>
        ) : (
          <div className="notificationsMainDiv px-5">
            {notifications?.length > 0 ? (
              notifications?.map((item) => {
                return (
                  <>
                    <Link
                      key={item?.id}
                      className="notification"
                      to={item?.data?.action_url}
                    >
                      <div>
                        <img src={item?.notification_from?.image} alt="" />
                      </div>
                      <div className="ml25">
                        <h5 className="notificationH5">
                          {item?.notification_from?.first_name
                            .charAt(0)
                            ?.toUpperCase() +
                            item?.notification_from?.first_name?.slice(1)}
                        </h5>
                        <p className="notificationP aboutContent">
                          {item?.data?.message} &nbsp;
                          <span className="green">View More</span>
                        </p>
                        <p>
                          {timeDifference(
                            new Date(),
                            new Date(item?.created_at)
                          )}
                        </p>
                      </div>
                    </Link>
                  </>
                );
              })
            ) : (
              <div className="notification noNotification">
                <p className="notificationP">No current notifications</p>
              </div>
            )}
          </div>
        )}
      </Container>
      {/* <FooterNav /> */}
    </div>
  );
};

export default CustomerNotifications;
