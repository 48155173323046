import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";
import axios from "../../services/axios";
import { isIOS } from 'react-device-detect';
import { requestForToken } from '../../firebase/Token';


const Verification = () => {
    let navigate = useNavigate();
    const { id, token } = useParams();

    const isEmailCLickedFunc = () => {
        axios.get(`/verify-email/${id}/${token}`, {
            headers: { Authorization: `Bearer ${(JSON.parse(localStorage.getItem('sparrowSignIn'))?.token)}` }
        }).then((resp) => {
            Swal.fire({
                title: resp.data.message,
                timer: 2000,
                icon: 'success',
                showConfirmButton: false,
            })
           
            if (JSON.parse(localStorage.getItem('sparrowSignIn'))?.user?.role === 'client') {
                if (!isIOS) {
                    requestForToken();
                  }
                setTimeout(() => {
                    navigate('/dashboardRoute/customer-profile/');
                }, 3500);
            } else if (JSON.parse(localStorage.getItem('sparrowSignIn'))?.user?.role === 'student') {
                if (!isIOS) {
                    requestForToken();
                  }
                setTimeout(() => {
                    navigate('/signUpRoute/step-one');
                }, 3500);
            } else {
                setTimeout(() => {
                navigate('/signin');
                }, 3500);
            }
            
        })
            .catch((error) => {
                Swal.fire({
                    title: error,
                    timer: 1500,
                    icon: 'error',
                    showConfirmButton: false,
                })
                setTimeout(() => {
                    navigate('/signUpRoute/confirm-email');
                }, 2500);
            })
    }
    useEffect(() => {
        isEmailCLickedFunc()
    }, [])

    return (
        <>
            <div className='height100vh'>
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                <h3 style={{ color: 'black' }}>&nbsp;&nbsp;&nbsp;Verifying....</h3>
            </div>

        </>
    )
}

export default Verification