import React, { useEffect } from 'react'
import ContactUsIcon from '../contactus/ContactUsIcon'
import AboutBanner from './AboutBanner'
import AboutConnecting from './AboutConnecting'
import AboutExpanding from './AboutExpanding'
import AboutFaq from './AboutFaq'
import AboutInspiration from './AboutInspiration'
import ReactPixel from 'react-facebook-pixel';

function AboutMain() {
    useEffect(() => {
        ReactPixel.track('FAQ page');
      }, []);
    return (
        <div>
            <ContactUsIcon />
            <AboutBanner />
            <AboutConnecting />
            <AboutInspiration />
            <AboutFaq />
            <AboutExpanding />
        </div>
    )
}

export default AboutMain