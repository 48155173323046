import axios from "../axios";

export const getSingleOrderByTaskId = async (task_id) => {
  try {
    // debugger
    const config = {
      headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem("sparrowSignIn"))?.token}` },
    };
    const response = await axios.get(`/getOrderByTaskId/${task_id}`, config);
    return response;
  } catch (err) {
    return err.response;
  }
};
