import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import AdvacnceSearchBar from "../advancesearchbar/AdvacnceSearchBar";
import StudentProfileCard from "../Home/StudentProfileCard";
import { ShowAllStudents } from "../../services/users/ShowAllStudents";
import Swal from "sweetalert2";
import { useLocation } from "react-router";
import Loader from "../loader/Loader";
import InfiniteScroll from "react-infinite-scroll-component";

const ViewAllStudents = () => {
  const student_role = 2;
  const bookNow = "Connect";
  const { state } = useLocation();
  let ClientId = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.id;
  const [loder, setLoder] = useState(true);
  const [allStudent, setAllStudent] = useState(
    state?.query ? state?.query : []
  );
  const [searchedValue, setSearchedValue] = useState();
  const [displayedStudents, setDisplayedStudents] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [isSearching, setIsSearching] = useState(false);

  if (state?.hasOwnProperty("query")) {
    window.history.replaceState({}, document.title);
  }

  const getAllStudentFunc = async () => {
    if (!state?.hasOwnProperty("query")) {
      const response1 = await ShowAllStudents(student_role, ClientId);
      if (response1.status === 200) {
        const shuffledArray = response1.data.users?.sort(
          (a, b) => 0.5 - Math.random()
        );
        setAllStudent(shuffledArray);
        setDisplayedStudents(shuffledArray.slice(0, 12));
        setLoder(false);
      } else {
        Swal.fire({
          title: response1.data.message,
          timer: 1500,
          icon: "error",
          showConfirmButton: false,
        });
        setLoder(false);
      }
    } else {
      setLoder(false);
    }
  };
  useEffect(() => {
    getAllStudentFunc();
  }, []);

  // const handleViewStudent = (studentArray, searchValue) => {
  //   if (studentArray.length > 0) {
  //     setAllStudent(studentArray);
  //     setDisplayedStudents(studentArray.slice(0, 12));
  //   } else {
  //     getAllStudentFunc();
  //     setDisplayedStudents(allStudent);
  //   }
  //   const element = document.getElementById("allStudentSection");
  //   if (element) {
  //     element.scrollIntoView({ behavior: "smooth" });
  //   }
  // };
  const handleViewStudent = (studentArray, searchValue) => {
    // Reset the displayed students to only the first 12 of the new search result
    setDisplayedStudents(studentArray.slice(0, 12));

    // Ensure all students are updated in state
    setAllStudent(studentArray);

    // Reset hasMore to true if the new search result has more than 12 students
    setHasMore(studentArray.length > 12);

    // Scroll to the student section
    const element = document.getElementById("allStudentSection");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const fetch12MoreStudents = () => {
    let nextStudents;
    if (isSearching) {
      nextStudents = allStudent.slice(
        displayedStudents.length,
        displayedStudents.length + 12
      );
    } else {
      nextStudents = allStudent.slice(
        displayedStudents.length,
        displayedStudents.length + 12
      );
    }

    setDisplayedStudents([...displayedStudents, ...nextStudents]);
    if (displayedStudents.length + nextStudents.length >= allStudent.length) {
      setHasMore(false);
    }
  };

  return (
    <>
      {loder ? (
        <div className="height100vh">
          <Loader />
        </div>
      ) : (
        <div className="viewAllStudents">
          <Container className="text-center">
            <h2 className="mt70">
              Search <span className="green">Students</span>
            </h2>
            <div style={{ position: "relative" }}>
              <span>
                <img
                  className="info3"
                  src="/assets/images/newlogos/Info.svg"
                  alt=""
                />
                <span className="hoverTextForR3">
                  Search for students you're looking to hire by entering your
                  location and task. The advanced search filter enables you to
                  narrow your search for more precise results. Click on the
                  profile card of each student to see more information.
                </span>
              </span>
            </div>
            <AdvacnceSearchBar
              searchedValue={setSearchedValue}
              updateStudentResult={(studentArray, searchValue) =>
                handleViewStudent(studentArray, searchValue)
              }
              // valueFromHome={state?.defaultValue?.location ? state?.defaultValue?.location.value : state?.defaultValue?.taskInputValue}
            />
            {/* ShowAllStudents */}
            <InfiniteScroll
              dataLength={displayedStudents?.length}
              next={fetch12MoreStudents}
              hasMore={hasMore}
              // loader={<h4>Loading...</h4>}
              // endMessage={
              //   <p style={{ textAlign: "center" }}>
              //     <b>Yay! You have seen it all</b>
              //   </p>
              // }
              style={{ overflow: "none" }}
            >
              <Row className="viewAllStCards" id="allStudentSection">
                {displayedStudents?.map((singleStd, index) => {
                  if (singleStd?.email_verified_at !== null) {
                    return (
                      <>
                        <Col key={index} md={4} className="mb-5">
                          <StudentProfileCard
                            searchedValue={searchedValue}
                            bookNow={bookNow}
                            studentData={singleStd}
                            setAllStudent={setAllStudent}
                          />
                        </Col>
                      </>
                    );
                  }
                  return null;
                })}
              </Row>
            </InfiniteScroll>
          </Container>
        </div>
      )}
    </>
  );
};

export default ViewAllStudents;
