import React, { Fragment, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import { useNavigate, useParams } from "react-router";
import Swal from "sweetalert2";
// import FooterNav from '../mobilefooternav/FooterNav'
import { getSingleTask } from "../../services/tasks/GetSingleTask";
import AddConnectsModal from "../modals/AddConnectsModal";
import { updateOrder } from "../../services/order/UpdateOrder";
import { createNotification } from "../../services/notifications/CreateNotification";
import { sendEmail } from "../../services/contactus/ContactUs";
import moment from "moment";
import { emailUrl, handleImageError } from "../../utils/constants";
import { createChatRoom } from "./customerinvitecards/CreatingChatRoom";
import Loader from "../loader/Loader";
import { cancelOrder } from "../../services/order/CancelOrder";
import { db, firebaseConfig } from "../../firebase/FireBase";
import { initializeApp } from "firebase/app";
import { collection, deleteDoc, doc, getDocs, getFirestore, query, updateDoc, where } from "firebase/firestore";
import axios from "../../services/axios";
// import { sendEmail } from '../../services/contactus/ContactUs'

const CustomerTaskDetails = () => {
  let { taskId } = useParams();
  let navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  // const user_role = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.role;

  // console.log(user_role);
  // get order details data
  const [orderData, setOrderData] = useState([]);
  const [loder, setLoder] = useState(true);
  // console.log(orderData?.task_details);
  // getting order
  const getOrderFunc = async () => {
    const response = await getSingleTask(taskId);
    if (response.status === 200) {
      await setOrderData(response?.data?.task);
      // redirection check here
      // if (
      //   response.data.task?.task_details?.order_details[0]?.status !== 0 &&
      //   response.data.task?.task_details?.order_details[0]?.status !== 1
      // ) {
      //   navigate(
      //     `/dashboardRoute/customer-notification/order-details/${response.data.task?.task_details?.order_details[0]?.id}`
      //   );
      // }
      //
      setLoder(false);
    } else {
      Swal.fire({
        title: response.data.message,
        timer: 1500,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };
  useEffect(() => {
    getOrderFunc();
    if (!loder) {
      calculateConnects();
    }
  }, [loder]);

  // const getData = async () => {
  //   initializeApp(firebaseConfig);
  //   const db = getFirestore();
  //   let array = [];

  //   try {
  //     const response = await axios.get("/users");
  //     const db_users = response.data;

  //     for (const user of db_users) {
  //       const { id, email } = user;

  //       const q = query(collection(db, "Users"), where("id", "==", id.toString()));
  //       const querySnapshot = await getDocs(q);

  //       if (querySnapshot.empty) {
  //         console.log(`No Firestore user found with id: ${id}`);
  //         continue;
  //       }

  //       for (const docSnapshot of querySnapshot.docs) {
  //         const firestoreUser = docSnapshot.data();
  //         if (firestoreUser.email !== email) {
  //           await deleteDoc(doc(db, "Users", docSnapshot.id));
  //           console.log(
  //             `Email mismatch for id ${id}: Firestore email is ${firestoreUser.email}, DB email is ${email}`
  //           );
  //         } else {
  //           // console.log(`Email match for id ${id}`);
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error in getData function:", error);
  //   }
  // };

  // connect calculating func
  const [connectVal, setConnectVal] = useState(orderData?.task_details?.available_invites?.invites);
  const calculateConnects = () => {
    let finalConnects = orderData?.task_details?.available_invites?.invites - orderData?.task_details?.invitedStudents?.length;
    setConnectVal(finalConnects);
  };

  // connects modal
  const [show, setShow] = useState(false);
  const handleShow = () => {
    // debugger
    if (connectVal === 0) {
      // by setShow to true AddConnectsModal modal will popup
      setShow(true);
    } else {
      // https://github.com/Jinnbyte/sparrow-website.git
      setTimeout(() => {
        navigate(`/dashboardRoute/all-students/invite-students/${taskId}/${orderData?.task_details?.order_draft?.id}`);
      }, 100);
    }
  };
  const [btnLodrChat, setBtnLoderChat] = useState(false);
  const [btnLodrHire, setBtnLoderHire] = useState(false);

  const HireAndChat = async (client_id, status, student_id, task_id, order_id, email, student_name) => {
    setBtnLoderHire(true);

    const data = {
      task_id: task_id,
      student_id: student_id,
      client_id: client_id,
      status: status,
    };

    const resp = await updateOrder(data, order_id);
    // debugger;
    // if status has been updated so notifications
    if (resp.status === 200) {
      await createChat(student_id, client_id, order_id, email, student_name, 1);
    }
    setBtnLoderHire(false);
  };

  const createChat = async (student_id, client_id, order_id, email, student_name, hiring) => {
    // creating chatRoom here
    setBtnLoderChat(true);
    let std_String = student_id.toString();
    let client_String = client_id.toString();
    const chat_room_id = await createChatRoom(std_String, client_String, taskId, order_id, null, null);

    if (hiring === 1) {
      // creating notifiaction for All notififcation tab
      const dataN = {
        // as reqirement by backend type is 0
        type: 0,
        to_id: student_id,
        from_id: client_id,
        data: {
          title: "Task Started",
          message: `Congratulations! Your ${orderData?.task_details?.title.toLowerCase()} task has started. You can now chat with the client.`,
          action_url: `/dashboardRoute/chatStudent`,
        },
        read_at: null,
      };
      await createNotification(dataN);

      const dataForEmail = {
        to: email,
        subject: `${client_Name} has hired you!`,
        from: user_Email,
        first_name: student_name,
        client_name: client_Name,
        msg: ".",
        action_url: `${emailUrl}/#/dashboardRoute/task-history/student-task-descp/${taskId}/${order_id}`,
        template: "student_connect_with_client",
      };

      const email_sent = await sendEmail(dataForEmail);

      if (email_sent.status === 200) {
        Swal.fire({
          title: "Congratulations! You have hired a student for your task.",
          timer: 3000,
          icon: "success",
          showConfirmButton: false,
        });

        setTimeout(() => {
          navigate(`/dashboardRoute/chatStudent?roomId=${chat_room_id}`);
        }, 500);
      } else {
        setBtnLoderChat(false);
      }
    } else {
      setBtnLoderChat(false);
      setTimeout(() => {
        navigate(`/dashboardRoute/chatStudent?roomId=${chat_room_id}`);
      }, 500);
    }
  };

  // canceling order
  const reArrangeArray = (array) => {
    let order = {
      monday: 1,
      tuesday: 2,
      wednesday: 3,
      thursday: 4,
      friday: 5,
      saturday: 6,
      sunday: 7,
    };
    array.sort(function (a, b) {
      return order[a.day] - order[b.day];
    });
    return array;
  };

  // email work
  let emailOfAcceptedStd;
  if (orderData?.task_details?.order_accepted_by.length > 0) {
    emailOfAcceptedStd = orderData?.task_details?.order_accepted_by[0].email;
  }
  let user_Email = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.email;
  let client_Name = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.first_name;
  let client_user_id = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.id;

  // cancel task
  const canceledFunc = async () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "common primary",
        cancelButton: "common btn-danger",
        title: "lineHeight",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        // title: "Confirmation",
        title: "Are you sure you want to cancel this task?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        iconColor: "#82d051",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const data = {
            order_id:
              orderData?.task_details?.status == "pending"
                ? orderData?.task_details?.order_draft?.id
                : orderData?.task_details?.status == "in progress"
                ? orderData?.task_details?.order?.id
                : null,
            status: 4,
            type: orderData?.task_details?.status == "pending" ? "order_draft" : orderData?.task_details?.status == "in progress" ? "order" : null,
          };

          const resp = await cancelOrder(
            data,
            orderData?.task_details?.status == "pending"
              ? orderData?.task_details?.order_draft?.id
              : orderData?.task_details?.status == "in progress"
              ? orderData?.task_details?.order?.id
              : null
          );

          if (resp.status === 200) {
            const emails = orderData?.task_details?.invitedStudents.map((student) => student);
            // debugger;
            console.log(emails);
            for (const email of emails) {
              const dataForEmail = {
                to: email.email,
                subject: `Task : ${orderData?.task_details?.title} has been canceled`,
                from: user_Email,
                // first_name: orderData?.task_details?.order_accepted_by[0]?.first_name,
                first_name: email?.first_name,
                // first_name: client_Name,
                // last_name: orderData?.task_details?.order_accepted_by[0]?.last_name,
                msg: orderData?.task_details?.description,
                action_url: `${emailUrl}/#/dashboardRoute/task-history/student-task-descp/${taskId}/${
                  orderData?.task_details?.status == "pending"
                    ? orderData?.task_details?.order_draft?.id
                    : orderData?.task_details?.status == "in progress"
                    ? orderData?.task_details?.order?.id
                    : null
                }`,
                template: "student_notification_cancel_task",
              };

              await sendEmail(dataForEmail);
            }

            const ids = orderData?.task_details?.invitedStudents.map((student) => student.id);

            for (const id of ids) {
              const data = {
                // as reqirement by backend type is 0
                type: 0,
                to_id: id,
                from_id: orderData?.task_details?.client?.id,
                data: {
                  title: "Task is cancelled",
                  message: `Task canceled by ${client_Name}`,
                  action_url: `/dashboardRoute/task-history/student-task-descp/${taskId}/${
                    orderData?.task_details?.status == "pending"
                      ? orderData?.task_details?.order_draft?.id
                      : orderData?.task_details?.status == "in progress"
                      ? orderData?.task_details?.order?.id
                      : null
                  }`,
                },
                read_at: null,
              };
              // sending email
              // if (orderData?.task_details?.order_accepted_by.length > 0) {
              // }
              const resp = await createNotification(data);
              if (resp.status === 200) {
                Swal.fire({
                  title: "Task Canceled",
                  timer: 1500,
                  icon: "success",
                  showConfirmButton: false,
                });
              }
            }
            setTimeout(() => {
              navigate(`/dashboardRoute/customer-profile/`);
            }, 1800);
          }
        }
      });
  };

  return (
    <>
      {loder ? (
        <div style={{ height: "100vh" }} className="height100vh">
          <Loader />
        </div>
      ) : (
        <div className="bgNotiLines customerDetai">
          <Container>
            <h2 className="text-center alignIt">
              My <span className="green">Task</span>
            </h2>
            <div className="orderContentMain mtT10px">
              <Row className="wd59 elementCenter justify-content-center display-none-mb-mobile">
                {orderData?.task_details?.applied_students.length > 0 &&
                  orderData?.task_details?.status != "canceled" &&
                  orderData?.task_details?.applied_students.map((item) => (
                    <div className="notificationBaar">
                      <img
                        style={{
                          width: "48px",
                          height: "45px",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                        src={item?.student?.image}
                        alt=""
                      />
                      <p style={{ marginLeft: "14px" }}>
                        <span
                          style={{
                            color: "#82D051",
                            paddingRight: "5px",
                            cursor: "pointer",
                          }}
                          onClick={() => navigate(`/dashboardRoute/all-students/customer-student-profile/${item?.student?.id}`)}
                        >
                          {item?.student?.first_name.charAt(0).toUpperCase() +
                            item?.student?.first_name.slice(1) +
                            " " +
                            item?.student?.last_name.charAt(0).toUpperCase() +
                            "."}
                        </span>
                        has applied for your task.
                      </p>
                      {btnLodrChat ? (
                        <div className="relative connEctLoder">
                          <div className="loader alignLoader" style={{ borderTop: "5px solid #FFC700" }}></div>
                          <Link to="" style={{ color: "#FFC700", borderColor: "#FFC700" }}>
                            Chat
                          </Link>
                        </div>
                      ) : (
                        <Link
                          to=""
                          style={{ color: "#FFC700", borderColor: "#FFC700" }}
                          onClick={() => {
                            createChat(
                              item?.student?.id,
                              orderData?.task_details?.client?.id,
                              orderData?.task_details?.order_draft?.id,
                              item?.student?.email,
                              item?.student?.first_name.charAt(0).toUpperCase() + item?.student?.first_name.slice(1),
                              0
                            );
                          }}
                        >
                          Chat
                        </Link>
                      )}
                      {btnLodrHire ? (
                        <div className="relative connEctLoder">
                          <div className="loader alignLoader"></div>
                          <Link to="">Hire</Link>
                        </div>
                      ) : (
                        <Link
                          to=""
                          onClick={() => {
                            HireAndChat(
                              orderData?.task_details?.client?.id,
                              2,
                              item?.student?.id,
                              orderData?.task_details?.id,
                              orderData?.task_details?.status == "pending"
                                ? orderData?.task_details?.order_draft?.id
                                : orderData?.task_details?.status == "in progress"
                                ? orderData?.task_details?.order?.id
                                : null,
                              item?.student?.email,
                              item?.student?.first_name
                            );
                          }}
                          style={{ marginLeft: "20px" }}
                        >
                          Hire
                        </Link>
                      )}
                    </div>
                  ))}
                {orderData?.task_details?.invitedStudents.length > 0 &&
                  orderData?.task_details?.status != "canceled" &&
                  orderData?.task_details?.invitedStudents.map((item) =>
                    item?.status === 5 ? (
                      <div className="notificationBaar">
                        <img
                          style={{
                            width: "48px",
                            height: "45px",
                            borderRadius: "50%",
                            objectFit: "cover",
                          }}
                          src={item?.image}
                          alt=""
                        />
                        <p style={{ marginLeft: "14px" }}>
                          <span
                            style={{
                              color: "#82D051",
                              paddingRight: "5px",
                              cursor: "pointer",
                            }}
                            onClick={() => navigate(`/dashboardRoute/all-students/customer-student-profile/${item?.id}`)}
                          >
                            {item?.first_name.charAt(0).toUpperCase() +
                              item?.first_name.slice(1) +
                              " " +
                              item?.last_name.charAt(0).toUpperCase() +
                              "."}
                          </span>
                          accepted your invite.
                        </p>
                        {btnLodrChat ? (
                          <div className="relative connEctLoder">
                            <div className="loader alignLoader" style={{ borderTop: "5px solid #FFC700" }}></div>
                            <Link
                              to=""
                              style={{
                                color: "#FFC700",
                                borderColor: "#FFC700",
                              }}
                            >
                              Chat
                            </Link>
                          </div>
                        ) : (
                          <Link
                            to=""
                            style={{ color: "#FFC700", borderColor: "#FFC700" }}
                            onClick={() => {
                              createChat(
                                item?.id,
                                orderData?.task_details?.client?.id,
                                orderData?.task_details?.order_draft?.id,
                                item?.email,
                                item?.first_name.charAt(0).toUpperCase() + item?.first_name.slice(1),
                                0
                              );
                            }}
                          >
                            Chat
                          </Link>
                        )}
                        {btnLodrHire ? (
                          <div className="relative connEctLoder">
                            <div className="loader alignLoader"></div>
                            <Link to="">Hire</Link>
                          </div>
                        ) : (
                          <Link
                            to=""
                            onClick={() => {
                              HireAndChat(
                                orderData?.task_details?.client?.id,
                                2,
                                item?.id,
                                orderData?.task_details?.id,
                                orderData?.task_details?.status == "pending"
                                  ? orderData?.task_details?.order_draft?.id
                                  : orderData?.task_details?.status == "in progress"
                                  ? orderData?.task_details?.order?.id
                                  : null,
                                item?.email,
                                item?.first_name
                              );
                            }}
                            style={{ marginLeft: "20px" }}
                          >
                            Hire
                          </Link>
                        )}
                      </div>
                    ) : null
                  )}
                <h4 className="customerDetai4">Task Details</h4>
                <Col md={7}>
                  {orderData?.task_details?.client?.id == client_user_id ? (
                    <div className="mergeImg">
                      {orderData?.task_details?.invitedStudents.slice(0, 5).map((item) => {
                        return <img className="profileImgM" src={item?.image} alt="" referrerpolicy="no-referrer" />;
                      })}

                      <div>
                        <p className="setResonive responsiveNone">
                          {orderData?.task_details?.invitedStudents.length === 1 ? (
                            <Fragment>
                              {orderData?.task_details?.invitedStudents[0].first_name?.charAt(0).toUpperCase() +
                                orderData?.task_details?.invitedStudents[0].first_name?.slice(1)}{" "}
                              invited
                            </Fragment>
                          ) : orderData?.task_details?.invitedStudents.length > 1 && orderData?.task_details?.invitedStudents.length <= 5 ? (
                            <Fragment>{orderData?.task_details?.invitedStudents.length} students invited</Fragment>
                          ) : orderData?.task_details?.invitedStudents.length > 5 ? (
                            "5+ students invited"
                          ) : (
                            ""
                          )}
                        </p>
                      </div>
                    </div>
                  ) : null}
                </Col>
                <Col md={5}>
                  <div className="orderHedg orderHedgalin">
                    <>
                      <img src="/assets/images/offer/boxg.svg" alt="" />
                    </>
                    <p>Hourly Pay</p>
                    <>
                      <button style={{ cursor: "unset" }}>${orderData?.task_details?.hourly_rate}</button>
                    </>
                  </div>
                </Col>
              </Row>
              <div className="wd59 elementCenter justify-content-center display-none-mb-desktop mb-elementCenter">
                {orderData?.task_details?.applied_students.length > 0 &&
                  orderData?.task_details?.applied_students.map((item) => (
                    <div className="notificationBaar">
                      <img
                        style={{
                          width: "48px",
                          height: "45px",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                        src={item?.student?.image}
                        alt=""
                      />
                      <p style={{ marginLeft: "14px" }}>
                        <span
                          style={{
                            color: "#82D051",
                            paddingRight: "5px",
                            cursor: "pointer",
                          }}
                          onClick={() => navigate(`/dashboardRoute/all-students/customer-student-profile/${item?.student?.id}`)}
                        >
                          {item?.student?.first_name.charAt(0).toUpperCase() +
                            item?.student?.first_name.slice(1) +
                            " " +
                            item?.student?.last_name.charAt(0).toUpperCase() +
                            "."}
                        </span>
                        has applied for your task.
                      </p>
                      {btnLodrChat ? (
                        <div className="relative connEctLoder">
                          <div className="loader alignLoader" style={{ borderTop: "5px solid #FFC700" }}></div>
                          <Link to="" style={{ color: "#FFC700", borderColor: "#FFC700" }}>
                            Chat
                          </Link>
                        </div>
                      ) : (
                        <Link
                          to=""
                          style={{
                            color: "#FFC700",
                            borderColor: "#FFC700",
                            margin: 5,
                          }}
                          onClick={() => {
                            createChat(
                              item?.student?.id,
                              orderData?.task_details?.client?.id,
                              orderData?.task_details?.order_draft?.id,
                              item?.student?.email,
                              item?.student?.first_name.charAt(0).toUpperCase() + item?.student?.first_name.slice(1),
                              0
                            );
                          }}
                        >
                          Chat
                        </Link>
                      )}
                      {btnLodrHire ? (
                        <div className="relative connEctLoder">
                          <div className="loader alignLoader"></div>
                          <Link to="">Hire</Link>
                        </div>
                      ) : (
                        <Link
                          to=""
                          onClick={() => {
                            HireAndChat(
                              orderData?.task_details?.client?.id,
                              2,
                              item?.student?.id,
                              orderData?.task_details?.id,
                              orderData?.task_details?.status == "pending"
                                ? orderData?.task_details?.order_draft?.id
                                : orderData?.task_details?.status == "in progress"
                                ? orderData?.task_details?.order?.id
                                : null,
                              item?.student?.email,
                              item?.student?.first_name
                            );
                          }}
                        >
                          Hire
                        </Link>
                      )}
                    </div>
                  ))}
                {orderData?.task_details?.invitedStudents.length > 0 &&
                  orderData?.task_details?.invitedStudents.map((item) =>
                    item?.status === 5 ? (
                      <div className="notificationBaar">
                        <img
                          style={{
                            width: "48px",
                            height: "45px",
                            borderRadius: "50%",
                            objectFit: "cover",
                          }}
                          src={item?.image}
                          alt=""
                        />
                        <p style={{ marginLeft: "14px" }}>
                          <span
                            style={{
                              color: "#82D051",
                              paddingRight: "5px",
                              cursor: "pointer",
                            }}
                            onClick={() => navigate(`/dashboardRoute/all-students/customer-student-profile/${item?.id}`)}
                          >
                            {item?.first_name.charAt(0).toUpperCase() +
                              item?.first_name.slice(1) +
                              " " +
                              item?.last_name.charAt(0).toUpperCase() +
                              "."}
                          </span>
                          accepted your invite.
                        </p>
                        {btnLodrChat ? (
                          <div className="relative connEctLoder">
                            <div className="loader alignLoader" style={{ borderTop: "5px solid #FFC700" }}></div>
                            <Link
                              to=""
                              style={{
                                color: "#FFC700",
                                borderColor: "#FFC700",
                              }}
                            >
                              Chat
                            </Link>
                          </div>
                        ) : (
                          <Link
                            to=""
                            style={{
                              color: "#FFC700",
                              borderColor: "#FFC700",
                              margin: 5,
                            }}
                            onClick={() => {
                              createChat(
                                item?.id,
                                orderData?.task_details?.client?.id,
                                orderData?.task_details?.order_draft?.id,
                                item?.email,
                                item?.first_name.charAt(0).toUpperCase() + item?.first_name.slice(1),
                                0
                              );
                            }}
                          >
                            Chat
                          </Link>
                        )}
                        {btnLodrHire ? (
                          <div className="relative connEctLoder">
                            <div className="loader alignLoader"></div>
                            <Link to="">Hire</Link>
                          </div>
                        ) : (
                          <Link
                            to=""
                            onClick={() => {
                              HireAndChat(
                                orderData?.task_details?.client?.id,
                                2,
                                item?.id,
                                orderData?.task_details?.id,
                                orderData?.task_details?.status == "pending"
                                  ? orderData?.task_details?.order_draft?.id
                                  : orderData?.task_details?.status == "in progress"
                                  ? orderData?.task_details?.order?.id
                                  : null,
                                item?.email,
                                item?.first_name
                              );
                            }}
                          >
                            Hire
                          </Link>
                        )}
                      </div>
                    ) : null
                  )}
                <div className="fkex">
                  <Col md={7}>
                    {orderData?.task_details?.client?.id == client_user_id ? (
                      <div className="mergeImg">
                        <div>
                          <p className="flex">
                            {orderData?.task_details?.invitedStudents.length === 1 ? (
                              <Fragment>
                                {orderData?.task_details?.invitedStudents.slice(0, 1).map((item) => {
                                  return (
                                    <>
                                      <img className="profileImgM" src={item?.image} alt="" referrerpolicy="no-referrer" />
                                      <p>{item?.first_name?.charAt(0).toUpperCase() + item?.first_name?.slice(1)} invite</p>
                                    </>
                                  );
                                })}
                              </Fragment>
                            ) : orderData?.task_details?.invitedStudents.length > 1 && orderData?.task_details?.invitedStudents.length <= 5 ? (
                              <Fragment>
                                {orderData?.task_details?.invitedStudents.slice(0, orderData?.task_details?.invitedStudents.length).map((item) => {
                                  return (
                                    <>
                                      <img className="profileImgM" src={item?.image} alt="" referrerpolicy="no-referrer" />
                                    </>
                                  );
                                })}
                              </Fragment>
                            ) : orderData?.task_details?.invitedStudents.length > 5 ? (
                              "5+ students invited"
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                      </div>
                    ) : null}
                  </Col>
                  <Col md={5}>
                    <div className="orderHedg orderHedgalin">
                      <>
                        <img src="/assets/images/offer/boxg.svg" alt="" />
                      </>
                      <p>Hourly Pay</p>
                      <>
                        <button style={{ cursor: "unset" }}>${orderData?.task_details?.hourly_rate}</button>
                      </>
                    </div>
                  </Col>
                </div>
              </div>
              <Row className="justify-content-center">
                <Col md={7}>
                  <div style={{ position: "relative" }}>
                    <span>
                      <img className="info4" src="/assets/images/newlogos/Info.svg" alt="" />
                      <span className="hoverTextForR4">
                        Feel free to invite more students or cancel the task whenever you wish. As soon as a student accepts your task, you will be
                        able to chat about your task. Only click the “Hire” button when you have found the right student for the job.
                      </span>
                    </span>
                  </div>
                  <div className="orderDetailRight editBtn">
                    <div className="orderfirstBox w-100">
                      <div className="orderfirstBoxP w-100">
                        <div>
                          <img src="/assets/images/customerDashboard/jobtype.svg" alt="" />
                        </div>
                        <div>
                          <p>Job Type</p>
                          <h5>{orderData?.task_details?.type}</h5>
                        </div>
                      </div>
                      <div className="orderfirstBoxP w-100">
                        <div>
                          <img src="/assets/images/customerDashboard/Task.svg" alt="" />
                        </div>
                        <div>
                          <p>Task</p>
                          <h5>{orderData?.task_details?.title}</h5>
                        </div>
                      </div>
                      <div className="orderfirstBoxP w-100">
                        <div>
                          <img src="/assets/images/customerDashboard/location.svg" alt="" />
                        </div>
                        <div>
                          <p>Location</p>
                          <h5>
                            {orderData?.task_details?.location?.city}, {orderData?.task_details?.location?.state}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="orderfirstBox w-100">
                      <div className="orderfirstBoxP w-100">
                        <div>
                          <img src="/assets/images/customerDashboard/Date.svg" alt="" />
                        </div>
                        <div>
                          <p>Start Date</p>
                          <h5> {moment(orderData?.task_details?.start_date).format("MM-DD-YYYY")}</h5>
                        </div>
                      </div>
                      {orderData?.task_details?.end_date && (
                        <div className="orderfirstBoxP w-100">
                          <div>
                            <img src="/assets/images/customerDashboard/Date.svg" alt="" />
                          </div>
                          <div>
                            <p>End Date</p>
                            <h5>{moment(orderData?.task_details?.end_date).format("MM-DD-YYYY")}</h5>
                          </div>
                        </div>
                      )}
                      <div className="orderfirstBoxP w-100">
                        <div>
                          <img src="/assets/images/customerDashboard/Time.svg" alt="" />
                        </div>
                        <div className="dsp-mr-0">
                          <p>Time</p>
                          <h5 className="timetaskDesp">
                            {reArrangeArray(orderData?.availability)?.map((item) => {
                              return (
                                <div>
                                  <div>{item?.day.charAt(0).toUpperCase() + item?.day.slice(1)}:</div>
                                  <div>
                                    {item.avail[0].start} - {item.avail[0].end}
                                  </div>
                                </div>
                              );
                            })}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* 2nd section */}

                  <div className="orderDetailRight setTsdk">
                    <div className="d-flex1 mb-2">
                      <p className="ml-1 despHead" style={{ color: "#82D051" }}>
                        Task Description
                      </p>
                      {/* connects */}
                      <p
                        style={{
                          color: "#82D051",
                          marginRight: "10px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {orderData?.task_details?.client?.id == client_user_id ? (
                          <>
                            <img
                              src="/assets/images/customerDashboard/circleDott.svg"
                              alt=""
                              style={{ marginRight: "5px" }}
                              className="setResonive"
                            />
                            Remaining Invites
                            <span className="connectsSpan">{connectVal}</span>
                          </>
                        ) : null}
                      </p>
                    </div>
                    <span className="pd0101 tasskDescpTxt">{orderData?.task_details?.description}</span>
                  </div>
                  {
                    // 3 means closed and 4 means canceled
                    orderData?.task_details?.order_draft?.status == "pending" && orderData?.task_details?.client?.id == client_user_id ? (
                      <div className="payBtnDiv justify-content-end">
                        {/* if task is accepted by student then cencel button will be shown */}
                        {orderData?.task_details?.order_draft?.status == "in progress" ||
                        orderData?.task_details?.order_draft?.status == "pending" ? (
                          <Link className="secondary common cancelBtn1" onClick={canceledFunc}>
                            Cancel Task
                          </Link>
                        ) : (
                          ""
                        )}
                        &nbsp;&nbsp;&nbsp;
                        <Link onClick={handleShow} className="primary common">
                          Invite Students
                        </Link>
                      </div>
                    ) : null
                  }
                </Col>
              </Row>
            </div>
          </Container>
          {/* connects purchasing Modal */}
          <AddConnectsModal show={show} setShow={setShow} taskId={taskId} />
        </div>
      )}
    </>
  );
};

export default CustomerTaskDetails;
