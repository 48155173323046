import React, { useEffect, useRef, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { logout } from "../../services/authentication/Logout";
import { navList } from "./NavbarList";
import { Online } from "react-detect-offline";
import AuthContext from "../../context/AuthContext";
import { useContext } from "react";
import { getAllNotifications } from "../../services/notifications/GetNotifications";
import {
  collection as fireStoreCollectione,
  query as fireStoreQuery,
  where as fireStoreWhere,
  getDocs as fireStoreDocs,
  onSnapshot,
  getFirestore,
} from "firebase/firestore";
import { firebaseConfig, onMessageListener } from "../../firebase/FireBase";
import { initializeApp } from "firebase/app";
import Swal from "sweetalert2";
// import incomingSound from "../../utils/IncomingMessage.mp3"; // Import your sound file

const DashboardNav = () => {
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = useContext(AuthContext);

  let user_id = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.id;
  let user_id_string = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.id.toString();
  const [expanded, setExpanded] = useState(false);
  //
  // const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (
    !navigator.userAgent.match(/(FBIOS|FBAN|FBAV)/) && // Matches Facebook iOS app
    !navigator.userAgent.match(/Instagram/) && // Matches Instagram app
    !navigator.userAgent.match(/(FB_IAB|Instagram)/) && // Matches Facebook and Instagram in-app browsers
    !navigator.userAgent.match(/(FBForIPhone|FBDV)/) &&
    !navigator.userAgent.match(/iPhone/) // Matches additional Facebook patterns
  ) {
    onMessageListener()
      .then((payload) => {
        // setShow(true);
        // setNotification({title: payload.notification.title, body: payload.notification.body})
        // debugger
        Swal.fire({
          title: payload.notification.title,
          html: `<p>${payload.notification.body}</p>`,
          timer: 3000,
          // icon: "success",
          showConfirmButton: false,
        });
        console.log(payload);
      })
      .catch((err) => console.log("failed: ", err));
  } else {
    console.log("Website is open on iPhone or social media of iPhone. Skipping onMessageListener.");
  }

  useEffect(() => {
    const fetchDocument = async () => {
      try {
        const q12 = fireStoreQuery(fireStoreCollectione(db, "ChatRooms"));
        onSnapshot(q12, { includeMetadataChanges: true }, (snapshot) => {
          const chatRoomList = snapshot.docs.map((doc) => doc.data());
          if (chatRoomList) {
            const userChatRooms = chatRoomList?.filter((item) => {
              return item?.userIds.includes(user_id_string);
            });
            const UnreadChatRoom = userChatRooms?.filter((item) => {
              return item?.seen === false && item?.senderId !== user_id_string;
            });
            // debugger;
            if (UnreadChatRoom?.length > 0) {
              // 4800 id is for message bell icon
              if (document.getElementById("4800")) {
                let id = document.getElementById("4800");
                id.classList.add("dot");
              }
            }
          }
          // Do something with the chatRoomList
        });
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };
    fetchDocument();
  }, [db]);
  // for showing dot icon in sideBar
  useEffect(() => {
    const unreadNotifications = auth?.notifications?.filter((item) => {
      return item.read_at === null;
    });
    if (unreadNotifications?.length > 0) {
      // 571 id is for bell icon
      if (document.getElementById("571")) {
        let id = document.getElementById("571");
        id.classList.add("dot");
      }
    }
  }, [auth?.notifications]);

  // loder
  const [loader, setLoader] = useState(false);
  const showMenuu = (e) => {
    e.target.closest(".actionContent").classList.toggle("block");
  };

  // logout
  const navigate = useNavigate();
  const { handleSubmit } = useForm();

  const logOut = async () => {
    setLoader(true);
    const data = {
      email: JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.email,
    };
    // debugger
    const resp = await logout(data);

    if (resp.status !== 200) {
    } else {
      // removing notification states from useContext
      auth.clearNotifications();
      localStorage.removeItem("sparrowSignIn");
      setTimeout(() => {
        navigate("/signin");
      }, 100);
    }
  };

  useEffect(() => {
    // get notifiations
    getFunc();
  }, []);
  // getting notifiations again as recieve firebase Notification
  const getFunc = async () => {
    if (JSON.parse(localStorage.getItem("sparrowSignIn"))) {
      const optionNotifications = [];
      const resp = await getAllNotifications(user_id);
      resp.data?.notification?.map((single) => {
        return optionNotifications.push(single);
      });
      auth.addNotifications(optionNotifications);
    }
  };
  //
  // loggin icon work
  const [iconAfterLogedIn, setIconAfterLogedIn] = useState(false);

  const loginIcon = (item) => {
    if (!iconAfterLogedIn) {
      return setIconAfterLogedIn(
        <div className="actionContent">
          <Online>
            <div className="colorGreen"></div>
          </Online>
          {/* navabr user img */}
          <img className="userHedrImg" onClick={showMenuu} src={JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.image} alt="" />

          {loader ? (
            <div className="dropdownn setLogoutWidth">
              <form className="dropdown-contentt">
                <div className="relative">
                  <div className="loader alignLoader"></div>
                  <span>Log Out</span>
                </div>
              </form>
            </div>
          ) : (
            <div className="dropdownn">
              <form className="dropdown-contentt">
                <Link
                  to={
                    JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.role === "client"
                      ? "/dashboardRoute/customer-profile/"
                      : JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.role === "student"
                      ? "/dashboardRoute/student-profile/"
                      : ""
                  }
                >
                  <span>Profile</span>
                </Link>
                <span onClick={handleSubmit(logOut)}>Log Out</span>
              </form>
            </div>
          )}
        </div>
      );
    }
  };

  // handling clicked outside element
  const refOne = useRef(null);
  useEffect(() => {
    document.addEventListener("click", handleClickOutsideBox, true);
    document.addEventListener("click", handleClickMainDropdown, true);
    // Unbind the event listener on clean up
    return () => {
      document.removeEventListener("click", handleClickOutsideBox, true);
      document.removeEventListener("click", handleClickMainDropdown, true);
    };
  }, []);

  const handleClickOutsideBox = (e) => {
    document.querySelectorAll(".actionContent").forEach((item) => {
      if (!refOne?.current?.contains(e.target)) {
        item.classList.remove("block");
      }
    });
  };

  const handleClickMainDropdown = (e) => {
    document.querySelectorAll(".navbar-collapse").forEach((item) => {
      if (!refOne?.current?.contains(e.target)) {
        // item.classList.remove("show")
        setExpanded(false);
      }
    });
  };
  const isiPhone = window.navigator.platform === "iPhone";

  return (
    <div className="dashboardheader">
      <Navbar expanded={expanded} expand="lg" ref={refOne}>
        {isiPhone ? (
          <>
            {!expanded ? (
              <div class="hamburger">
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
              </div>
            ) : (
              <img className="blockipad MENUImg heigAuto" src={"/assets/images/Cross.svg"} alt="" />
            )}
          </>
        ) : (
          <img className="blockipad MENUImg" src={!expanded ? "/assets/images/Menu.svg" : "/assets/images/Cross.svg"} alt="" />
        )}
        <Container>
          <div className="headerLeft">
            <Link to="/" className="setResonive">
              <img src="/assets/images/newlogos/sparrowlogo.png" alt="" />
              {/* <img src="/assets/images/signUpNav/Group 666.svg" alt="" /> */}
            </Link>
            <Link to="/" className="d-none makeMobileOnly">
              {isiPhone ? <img src="/assets/images/newlogos/sparrowlogo.png" alt="" /> : <img src="/assets/images/newlogos/sparrowlogo.png" alt="" />}
            </Link>
          </div>

          {/* show if system is logged in mobile*/}
          <div className="d-none makeMobileOnly also_in_tablet">{iconAfterLogedIn && iconAfterLogedIn}</div>

          <div className="headerRight">
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              data-toggle="collapse"
              data-target="#basic-navbar-nav"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => setExpanded(expanded ? false : "expanded")}
            />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                {navList.map((item) => {
                  return (
                    <>
                      {item.imageName === "Image" && JSON.parse(localStorage.getItem("sparrowSignIn")) ? (
                        <>
                          {loginIcon(item)}
                          <div className="none">{iconAfterLogedIn && iconAfterLogedIn}</div>
                        </>
                      ) : item.taskName === "Post Task" && JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.role === "client" ? (
                        <Link to={item.link} onClick={() => setExpanded(false)} className="primary dashboardHederBtn">
                          {item.taskName}
                        </Link>
                      ) : item.auth === "Sign In" && !JSON.parse(localStorage.getItem("sparrowSignIn")) ? (
                        <Link to={item.link} className="primary dashboardHederBtn">
                          {item.auth}
                        </Link>
                      ) : (
                        ""
                      )}
                      {item.link === "/" ? (
                        <NavLink end to={item.link}>
                          {item.name}
                        </NavLink>
                      ) : item.type === "global" ? (
                        <NavLink data-toggle="collapse" to={item.link} onClick={() => setExpanded(false)}>
                          {item.name}
                        </NavLink>
                      ) : item.type === "mobileOnly" ? (
                        <NavLink className="setResonive1" to={item.link} onClick={() => setExpanded(false)}>
                          {item.name}
                        </NavLink>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
              </Nav>
            </Navbar.Collapse>
          </div>
        </Container>
      </Navbar>
    </div>
  );
};

export default DashboardNav;
