import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import AdvacnceSearchBar from "../advancesearchbar/AdvacnceSearchBar";
import StudentProfileCard from "../Home/StudentProfileCard";
import { ShowAllStudents } from "../../services/users/ShowAllStudents";
import Swal from "sweetalert2";
import ReactPixel from "react-facebook-pixel";
import Loader from "../loader/Loader";
import InfiniteScroll from "react-infinite-scroll-component";

const CustomerViewAllStudents = () => {
  const [loder, setLoder] = useState(true);
  const student_role = 2;
  let ClientId = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.id;
  const [allStudent, setAllStudent] = useState([]);
  const [displayedStudents, setDisplayedStudents] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [searchedValue, setSearchedValue] = useState();

  const getAllStudentFunc = async () => {
    const response1 = await ShowAllStudents(student_role, ClientId);
    if (response1.status === 200) {
      const shuffledArray = response1.data.users?.sort(
        (a, b) => 0.5 - Math.random()
      );
      setAllStudent(shuffledArray);
      setDisplayedStudents(shuffledArray.slice(0, 12));
      setLoder(false);
    } else {
      Swal.fire({
        title: response1.data.message,
        timer: 1500,
        icon: "error",
        showConfirmButton: false,
      });
      setLoder(false);
    }
  };
  useEffect(() => {
    getAllStudentFunc();
    ReactPixel.track("All Students");
  }, []);

  const handleViewStudent = (studentArray, is_searched) => {
    setAllStudent(studentArray);
    setDisplayedStudents(studentArray.slice(0, 12));
    setIsSearching(is_searched); // Set isSearching to true if searchValue exists
  };

  const bookNow = "Connect";
  const fetch12MoreStudents = () => {
    let nextStudents;
    if (isSearching) {
      nextStudents = allStudent.slice(
        displayedStudents.length,
        displayedStudents.length + 12
      );
    } else {
      nextStudents = allStudent.slice(
        displayedStudents.length,
        displayedStudents.length + 12
      );
    }

    setDisplayedStudents([...displayedStudents, ...nextStudents]);
    if (displayedStudents.length + nextStudents.length >= allStudent.length) {
      setHasMore(false);
    }
  };

  return (
    <>
      {loder ? (
        <div className="height100vh height10vh">
          <Loader />
        </div>
      ) : (
        <div className="viewAllStudents">
          <Container className="text-center pl90">
            <h2 className="mt70">
              Search <span className="green">Students</span>
              <div style={{ position: "relative" }}>
                <span>
                  <img
                    className="info3 info3C"
                    src="/assets/images/newlogos/Info.svg"
                    alt=""
                  />
                  <span className="hoverTextForR3 hoverTextForR3C">
                    Search for students you're looking to hire by entering your
                    location and task. The advanced search filter enables you to
                    narrow your search for more precise results. Click on the
                    profile card of each student to see more information.
                  </span>
                </span>
              </div>
            </h2>
            <AdvacnceSearchBar
              searchedValue={setSearchedValue}
              // updateStudentResult={handleViewStudent}
              updateStudentResult={(studentArray, searchValue) =>
                handleViewStudent(studentArray, searchValue)
              }
            />
            <InfiniteScroll
              dataLength={displayedStudents?.length}
              next={fetch12MoreStudents}
              hasMore={hasMore}
              style={{ overflow: "none" }}
            >
              <Row className="viewAllStCards">
                {displayedStudents?.map((singleStd) => {
                  if (singleStd?.email_verified_at !== null) {
                    return (
                      <Col md={4} className="mb-5" key={singleStd?.id}>
                        <StudentProfileCard
                          searchedValue={searchedValue}
                          bookNow={bookNow}
                          studentData={singleStd}
                          setAllStudent={setAllStudent}
                        />
                      </Col>
                    );
                  }
                })}
              </Row>
            </InfiniteScroll>
          </Container>
        </div>
      )}
    </>
  );
};

export default CustomerViewAllStudents;
